import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';
import { Creators as AccountActions } from '../ducks/accounts';

export function* getAccount() {
  try {
    const { data, status } = yield call(
      api.get,
      '/accounts',
    );

    if (status === 200) {
      yield put(AccountActions.getAccountSuccess(data));
    }
  } catch (error) {
    message.error('Houve um erro ao retornar o saldo da conta!');
    yield put(AccountActions.getAccountFailed());
  }
}
