const replaceNameField = (field) => {
  const fields = {
    username: 'Usuário',
    email: 'E-mail',
    code: 'Código',
  };

  return fields[field] || field;
};

const messages = (field) => ({
  'validation.unique': `O ${replaceNameField(
    field,
  )} informado não está disponível para uso. Por favor, informe outro.`,
});

export default (errors) => {
  const response = [];

  // eslint-disable-next-line array-callback-return
  Object.keys(errors).map((key) => {
    response.push(messages(key)[errors[key]]);
  });

  return response[0] || 'Por favor, verifique os valores preenchidos e tente novamente!';
};
