import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';
import { Creators as UsersActions } from '../ducks/users';

import validation from '../../services/validation';

const Constants = {
  CREATE_USER: 'users/CREATE',
  TOGGLING_VISIBILITY: 'users/VISIBILITY',
};

export function* getAllUsers() {
  try {
    const { data, status } = yield call(api.get, '/users');
    if (status === 200) {
      yield put(UsersActions.getAllUsersSuccess(data.data));
    }
  } catch (error) {
    yield put(UsersActions.getAllUsersFailed(error.message));
  }
}

export function* createUser({ payload }) {
  message.loading({
    content: 'Registrando novo usuário',
    key: Constants.CREATE_USER,
  });

  try {
    const { data, status } = yield call(api.post, '/users', payload);
    if (status === 201) {
      message.success({
        content: `Usuário '${data.name}' foi criado com sucesso!`,
        key: Constants.CREATE_USER,
      });

      yield put(UsersActions.createUserSuccess(data));
      yield put(UsersActions.handleModalVisibility(false));
    }
  } catch (error) {
    let content = 'Houve um erro ao criar o usuário, tente novamente mais tarde!';

    if (error.response) {
      const { response } = error;

      if (response?.data?.message) {
        content = response.data.message;
      }

      if (response?.status === 422) {
        content = validation(response.data);
      }
    }

    message.error({
      content,
      key: Constants.CREATE_USER,
    });

    yield put(UsersActions.createUserFailed());
  }
}

export function* editUser({ payload }) {
  try {
    const { status } = yield call(api.put, `/users/${payload.id}`, payload);
    if (status === 204) {
      message.success({
        content: 'O Usuário foi alterado com sucesso!',
      });

      yield put(UsersActions.getAllUsers());
      yield put(UsersActions.editUserSuccess());
      yield put(UsersActions.handleModalVisibility(false));
    }
  } catch (error) {
    let content = 'Houve um erro ao editar o usuário, tente novamente mais tarde!';

    if (error.response) {
      const { response } = error;

      if (response?.data?.message) {
        content = response.data.message;
      }

      if (response?.status === 422) {
        content = validation(response.data);
      }
    }

    message.error(content);

    yield put(UsersActions.editUserFailed());
  }
}

export function* toggleUserVisibility({ payload }) {
  message.loading({
    content: `Alterando status atual do usuário ${payload.name}`,
    key: Constants.TOGGLING_VISIBILITY,
  });

  try {
    const { status } = yield call(api.put, `/users/${payload.key}`, {
      active: !payload.active,
    });

    if (status === 204) {
      message.success({
        content: `O status do usuário '${payload.name}' agora está: ${
          !payload.active ? 'Ativo' : 'Inativo'
        }!`,
        key: Constants.TOGGLING_VISIBILITY,
      });

      yield put(
        UsersActions.toggleVisibilitySuccess({
          ...payload,
          id: payload.key,
          active: !payload.active,
        }),
      );
    }
  } catch (error) {
    let content = 'Houve um erro ao alterar o status do usuário, tente novamente mais tarde!';
    if (error && error?.response?.data?.message) {
      content = error.response.data.message;
    }

    message.error({
      content,
      key: Constants.TOGGLING_VISIBILITY,
    });
  }
}
