import { call, put, select } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';
import { Creators as ProductActions } from '../ducks/products';
import { Creators as CategoryActions } from '../ducks/categories';

const Constants = {
  DELETE_PRODUCT: 'product/delete',
  TOGGLE_PRODUCT: 'product/toggle',
  REORDER_PRODUCT: 'product/reorder',
};

export function* getProducts({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      `/menus/categories/${payload.category_id}/item-offers`,
    );

    if (status === 200) {
      yield put(
        ProductActions.getProductsSuccess({
          data: data.data,
          menu_category_id: payload.category_id,
        }),
      );
    }
  } catch (error) {
    yield put(ProductActions.getProductsFailed(error.message));
  }
}

export function* getProduct({ payload }) {
  try {
    const { data, status } = yield call(api.get, `/menus/item-offers/${payload.id}`);

    if (status === 200) {
      yield put(
        ProductActions.getProductSuccess({
          data,
        }),
      );
    }
  } catch (error) {
    yield put(ProductActions.getProductFailed(error.message));
  }
}

export function* createProduct({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/menus/item-offers', payload);

    if (status === 201) {
      message.success(`O Produto '${payload.name}' foi criado com sucesso!`);

      yield put(
        ProductActions.createProductSuccess({
          ...data,
          menu_category_id: payload.menu_category_id,
        }),
      );

      yield put(ProductActions.handleModalVisibility(false));
      yield put(
        CategoryActions.addProductToCategory({
          ...data,
          menu_category_id: payload.menu_category_id,
        }),
      );
    }
  } catch (error) {
    message.error('Houve um erro ao criar o produto, tente novamente mais tarde!');
    yield put(ProductActions.createProductFailed());
  }
}

export function* editProduct({ payload }) {
  try {
    const { status } = yield call(api.put, `/menus/item-offers/${payload.id}`, payload);

    if (status === 204) {
      message.success('O produto foi alterado com sucesso!');

      yield put(ProductActions.editProductSuccess());
      yield put(
        ProductActions.getProducts({
          category_id: payload.menu_category_id,
        }),
      );

      yield put(ProductActions.handleModalVisibility(false));
    }
  } catch (error) {
    message.error('Houve um erro ao editar o produto, tente novamente mais tarde!');
    yield put(ProductActions.editProductFailed());
  }
}

export function* reorderProducts({ payload }) {
  message.loading({
    content: 'Estamos processando a sua solicitação para reordenar os seus produtos!',
    key: Constants.REORDER_PRODUCT,
  });
  try {
    const { status } = yield call(api.post, `/menus/category-items/${payload.id}/reorder`, payload);

    if (status === 200) {
      message.success({
        content: 'Os produtos foram reordenados com sucesso!',
        key: Constants.REORDER_PRODUCT,
      });
      yield put(ProductActions.reorderProductsSuccess());
    }
  } catch (error) {
    message.error('Houve um erro ao reordenar os produtos, tente novamente mais tarde!');
    yield put(ProductActions.reorderProductsFailed());
  }
}

export function* toggleVisiblityProduct({ payload }) {
  message.loading({
    content: 'Estamos processando a sua solicitação para alterar a visibilidade do produto!',
    key: Constants.TOGGLE_PRODUCT,
  });
  try {
    const { status } = yield call(
      api.post,
      `/menus/category-items/${payload.menu_category_item_id}/status`,
      {
        active: !payload.active,
      },
    );

    if (status === 204) {
      message.success({
        content: 'A visibilidade do produto foi alterada com sucesso!',
        key: Constants.TOGGLE_PRODUCT,
      });
      yield put(ProductActions.toggleProductVisibilitySuccess());
    }
  } catch (error) {
    message.error(
      'Houve um erro ao alterar a visibilidade do produto, tente novamente mais tarde!',
    );
    yield put(ProductActions.toggleProductVisibilityFailed());
  }
}

export function* deleteProduct({ payload }) {
  message.loading({
    content: 'Estamos processando a sua solicitação para deletar!',
    key: Constants.DELETE_PRODUCT,
  });
  try {
    const { status } = yield call(api.delete, `/menus/item-offers/${payload.id}`);

    if (status === 204) {
      message.success({
        content: `O produto "${payload.name}" foi deletado com sucesso!`,
        key: Constants.DELETE_PRODUCT,
      });

      yield put(ProductActions.deleteProductSuccess(payload.id));
      yield put(CategoryActions.removeProductFromCategory(payload.id));
    }
  } catch (error) {
    message.error('Houve um erro ao deletar o produto, tente novamente mais tarde!');
    yield put(ProductActions.deleteProductFailed());
  }
}

export function* uploadImage({ payload }) {
  try {
    const { itemImages } = yield select((state) => state.products);

    if (itemImages && itemImages.length - 1 > 20) {
      throw new Error('Limite de 20 imagens atingido!');
    }

    const { data, status } = yield call(api.post, `/menus/items/${payload.id}/images`, payload);

    if (status === 201) {
      message.success('Upload da imagem feito com sucesso!');
      yield put(
        ProductActions.uploadImageSuccess({
          item_id: payload.id,
          image_id: data.id,
          type_id: data.type_id,
          url: data.filename,
        }),
      );
    }
  } catch (error) {
    message.error(error.message);

    yield put(
      ProductActions.uploadImageFailed({
        reference: payload.reference,
      }),
    );
  }
}

export function* getItemImages({ payload: id }) {
  try {
    const { data, status } = yield call(api.get, `/menus/items/images/${id}`);

    if (status === 200) {
      yield put(ProductActions.getItemImagesSuccess(data));
    }
  } catch (error) {
    message.error('Houve um erro ao retornar as imagens do produto, tente novamente mais tarde!');

    yield put(ProductActions.getItemImagesFailed());
  }
}

export function* removeImage({ payload }) {
  try {
    const { status } = yield call(api.delete, `/menus/items/images/${payload.id}`);

    if (status === 204) {
      yield put(ProductActions.removeImageSuccess(payload));
    }
  } catch (error) {
    message.error('Houve um erro ao remover a imagem, tente novamente mais tarde!');
  }
}
