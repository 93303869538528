import styled from 'styled-components';
import { Col } from 'antd';
import media from 'styled-media-query';

export const FormBox = styled(Col)`
  background-color: white;
  border-radius: 0.286rem;
  font-family: 'Jost', sans-serif;
  font-weight: 400;
  line-height: 1.8;
  color: #212120;

  ${media.lessThan('small')`
    padding-right: 20px;
    padding-left: 20px;
  `}

  ${media.greaterThan('large')`
    padding-right: 40px;
    padding-left: 40px;
  `}
`;

export const PasswordRuleAlertUl = {
  border: '1px solid',
  borderRadius: '6px',
  padding: '20px 5px 20px 25px',
  display: 'flex',
  width: 'auto',
  flex: 1,
  borderColor: '#ffeeba',
  backgroundColor: '#fff3cd',
  color: '#856404',
  fontSize: '11px',
  fontWeight: 'bold',
  listStyle: 'none',
};

export const PasswordRuleAlertSubUl = {
  paddingLeft: '16px',
  paddingTop: '10px',
};
