import React from 'react';
import { Switch, BrowserRouter } from 'react-router-dom';

/**
 * Roles
 */
import {
  ROLE_ADMIN,
  ROLE_EXPERT,
  ROLE_SUPPORT,
  ROLE_MASTER,
} from '../constants/roles';

/**
 * Pages
 */
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import Login from '../pages/Login';
import Register from '../pages/Register';
import RegisterSuccess from '../pages/Register/register-success';
import ResetPassword from '../pages/ResetPassword';
import Header from '../components/Header';
import Menu from '../pages/Menu';
import Config from '../pages/Config';
import User from '../pages/User';
import Coupon from '../pages/Coupons';

const privateRoutes = [
  {
    path: '/',
    component: Config,
    authorize: [
      ROLE_ADMIN,
      ROLE_MASTER,
      ROLE_SUPPORT,
    ],
  },
  {
    path: '/menu',
    component: Menu,
    authorize: [
      ROLE_ADMIN,
      ROLE_EXPERT,
      ROLE_MASTER,
      ROLE_SUPPORT,
    ],
  },
  {
    path: '/coupons',
    component: Coupon,
    authorize: [
      ROLE_ADMIN,
      ROLE_MASTER,
      ROLE_SUPPORT,
    ],
  },
  {
    path: '/settings',
    component: Config,
    authorize: [
      ROLE_ADMIN,
      ROLE_MASTER,
      ROLE_SUPPORT,
    ],
  },
  {
    path: '/users',
    component: User,
    authorize: [
      ROLE_ADMIN,
      ROLE_MASTER,
      ROLE_SUPPORT,
    ],
  },
];

const Routes = () => (
  <BrowserRouter>
    <Switch>
      <PublicRoute exact path="/login" component={Login} />
      <PublicRoute path="/register/:token?" component={Register} />
      <PublicRoute path="/reset-password" component={ResetPassword} />
      <PublicRoute path="/register-success" component={RegisterSuccess} />

      <Header>
        {
          privateRoutes.map((route) => (
            // eslint-disable-next-line react/jsx-first-prop-new-line
            <PrivateRoute exact
              key={route.path}
              {...route}
            />
          ))
        }
      </Header>
    </Switch>
  </BrowserRouter>
);

export default Routes;
