import React from 'react';
import { Row } from 'antd';
import { Wrapper } from './styles';
import CouponsList from './components/CouponsList';

const Coupon = () => (
  <Wrapper>
    <Row justify="space-between">
      <CouponsList />
    </Row>
  </Wrapper>
);

export default Coupon;
