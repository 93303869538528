import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Button,
  Input,
  Row,
  InputNumber,
  Col,
  TreeSelect,
  Switch,
  message,
} from 'antd';
import PropTypes from 'prop-types';
import {
  PlusCircleOutlined,
  MinusCircleOutlined,
  CloseOutlined,
  CheckOutlined,
} from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { Title } from '../../styles';
import { Creators as OptionalCreators } from '../../../../../../store/ducks/optionals';
import OptionalItem from './Optional';
import { styles } from './styles';

const OptionalForm = ({ visible, productId, setVisible }) => {
  const dispatch = useDispatch();
  const { saving, edit, optional } = useSelector((state) => state.optionals);
  const [form] = Form.useForm();
  const [type, setType] = useState(1);
  const [arrOptionals, setArrayOptionals] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [active, setActive] = useState(true);
  const [arrProductsSelected, setArrayProductsSelected] = useState(productId ? [productId] : []);
  const { data } = useSelector((state) => state.categories);
  const treeData = data
    .filter((cat) => cat.item_offers.length > 0)
    .map((category) => ({
      title: category.name,
      key: `category-${category.id}`,
      value: `category-${category.id}`,
      children: category.item_offers.map((product) => ({
        title: product.name,
        value: product.id,
        key: product.id,
      })),
    }));

  const onChangeProducts = (value) => {
    setDisabled(false);
    setArrayProductsSelected(value);
  };

  useEffect(() => {
    if (visible) {
      form.resetFields();
      if (edit) {
        if (optional && optional.items) {
          setArrayProductsSelected(optional.items.map((item) => item.id));
        }

        setArrayOptionals(
          // eslint-disable-next-line no-shadow
          edit.options.map((optional, index) => (
            <OptionalItem
              key={optional.id}
              index={index}
              type={edit.type === 'selector' ? 1 : 0}
              onChange={(() => setDisabled(false))}
            />
          )),
        );
        setType(1);
        form.setFieldsValue(edit);
      } else {
        setArrayProductsSelected(productId ? [productId] : []);
      }
    }
  }, [visible, edit, form, productId, optional]);

  useEffect(() => {
    if (visible) {
      if (edit) {
        dispatch(
          OptionalCreators.getOptional({
            id: edit.id,
          }),
        );
      }
    }
  }, [visible, edit]);

  const onFinish = (values) => {
    if (edit) {
      dispatch(
        OptionalCreators.editOptional({
          id: edit.id,
          type: type === 0 ? 'simple' : 'selector',
          ...values,
          items: arrProductsSelected,
          options: values.options.map((option) => ({
            ...option,
            price: option.final_price,
            active: option.active ? option.active : true,
          })),
          active: active || false,
        }),
      );
    } else if (type !== 0 && arrOptionals.length === 0) {
      message.warning('Você deve adicionar ao menos uma opcão.');
    } else {
      dispatch(
        OptionalCreators.createOptional({
          ...values,
          items: arrProductsSelected,
          options: values.options.map((option) => ({
            ...option,
            price: option.final_price,
            active: option.active ? option.active : true,
          })),
          active: active || false,
        }),
      );
    }

    setTimeout(() => {
      if (!saving) {
        setVisible(false);
        setArrayOptionals([]);
        setDisabled(true);
      }
    }, 500);
  };

  const tProps = {
    treeData,
    value: arrProductsSelected,
    onChange: onChangeProducts,
    treeCheckable: true,
    placeholder: 'Selecione os produtos',
    style: {
      width: '100%',
      borderBottomWidth: 1,
    },
  };

  const incrementArrayOptions = () => {
    const quantity = arrOptionals.length;
    setArrayOptionals((oldArray) => [
      ...oldArray,
      <OptionalItem
        key={quantity}
        index={quantity}
        newOption
        type={1}
        onChange={(() => setDisabled(false))}
      />,
    ]);
  };

  const decrementArrayOptions = () => {
    if (arrOptionals.length > 1) {
      const temp = [...arrOptionals];
      temp.pop();
      setArrayOptionals(temp);
    }
  };

  function verifyIfAble() {
    return type === 1;
  }

  const renderArrayOptionals = () => arrOptionals.map((item, index) => (
    <OptionalItem
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      item={item}
      index={index}
      newOption
      type={1}
      onChange={(() => setDisabled(false))}
    />
  ));

  const renderOptionals = () => (
    <Row>
      <Col span={24}>
        <Form.Item
          style={{ marginRight: 40 }}
          name="name"
          rules={[{ required: true, message: 'O título do opcional é obrigatório!' }]}
        >
          <Input placeholder="Título" style={styles.text} />
        </Form.Item>
      </Col>
      <Col span={24}>
        <Form.Item style={{ marginRight: 40 }} name="description">
          <Input placeholder="Descrição" style={styles.text} />
        </Form.Item>
      </Col>
      {type === 1 && (
        <Col span={24}>
          <Row>
            <Col span={6} style={{ marginRight: 10 }}>
              <Form.Item label="Min:" name="selectable_min">
                <InputNumber min={0} max={10} />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item label="Max:" name="selectable_max">
                <InputNumber min={1} max={10} />
              </Form.Item>
            </Col>
          </Row>
        </Col>
      )}
      {verifyIfAble() && (
        <Col span={24}>
          {type === 1 && <Title>Opção </Title>}
          <Button
            type="primary"
            shape="circle"
            icon={<PlusCircleOutlined />}
            size={30}
            onClick={() => incrementArrayOptions()}
            style={{ marginRight: 10 }}
          />
          <Button
            type="primary"
            shape="circle"
            danger
            icon={<MinusCircleOutlined />}
            size={30}
            onClick={() => decrementArrayOptions()}
          />
        </Col>
      )}
      {type === 1 ? (
        <div
          id="optional_div"
          style={{ maxHeight: 400, overflow: 'auto', width: '100%' }}
        >
          {renderArrayOptionals()}
        </div>
      ) : (
        <OptionalItem
          onChange={(() => setDisabled(false))}
          key={0}
          index={0}
          newOption={!edit}
        />
      )}
    </Row>
  );

  function handleCloseModal() {
    setVisible(false);
    setArrayProductsSelected([]);
    setArrayOptionals([]);
    setDisabled(true);
  }

  return (
    <Modal
      width="50%"
      title={edit ? 'Editar Opcional' : 'Cadastrar Opcional'}
      centered
      visible={visible}
      destroyOnClose
      onCancel={() => handleCloseModal()}
      footer={[
        <Button key="back" onClick={() => handleCloseModal()}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={disabled}
          loading={saving}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form
        form={form}
        onChange={() => setDisabled(false)}
        onFinish={onFinish}
        initialValues={{
          min_selectable: 0,
          max_selectable: 1,
        }}
      >
        <Col style={{ marginBottom: 10 }}>
          <Title style={{ marginRight: 10 }}>Ativar Opcional</Title>
          <Form.Item
            name="active"
            valuePropName="checked"
            checked={active}
            onChange={() => {
              setActive(!active);
              setDisabled(false);
            }}
          >
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              onChange={() => setDisabled(false)}
              defaultChecked
            />
          </Form.Item>
        </Col>

        <div style={{ marginTop: 15 }}>
          {renderOptionals()}
        </div>

        <Row style={{ marginTop: 15 }}>
          <Title>Associar opcional aos produtos</Title>
          <TreeSelect bordered {...tProps} />
        </Row>
      </Form>
    </Modal>
  );
};

OptionalForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  productId: PropTypes.number,
  setVisible: PropTypes.func.isRequired,
};

OptionalForm.defaultProps = {
  productId: null,
};
export default OptionalForm;
