import Immutable from 'seamless-immutable';

export const Types = {
  GET_ACCOUNT_REQUEST: 'accounts/GET_ACCOUNT_REQUEST',
  GET_ACCOUNT_SUCCESS: 'accounts/GET_ACCOUNT_SUCCESS',
  GET_ACCOUNT_FAILED: 'accounts/GET_ACCOUNT_FAILED',
};

const INITIAL_STATE = Immutable({
  loading: false,
  account: {},
});

export default function accounts(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_ACCOUNT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_ACCOUNT_SUCCESS: {
      return {
        ...state,
        loading: false,
        account: action.payload,
      };
    }

    case Types.GET_ACCOUNT_FAILED: {
      return {
        ...state,
        loading: false,
        account: {},
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getAccount: (payload) => ({
    type: Types.GET_ACCOUNT_REQUEST,
    payload,
  }),

  getAccountSuccess: (payload) => ({
    type: Types.GET_ACCOUNT_SUCCESS,
    payload,
  }),

  getAccountFailed: (payload) => ({
    type: Types.GET_ACCOUNT_FAILED,
    payload,
  }),
};
