import styled from 'styled-components';
import { Table, Layout } from 'antd';

export const StyledTable = styled(Table)`
  overflow: auto;
  .ant-table-row {
    background-color: white;
  }
`;

export const Wrapper = styled(Layout)`
  padding: 30px;
  flex: 1;
  height: 100%;
  overflow: auto;
  display: block;
`;

export const AlertContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;
