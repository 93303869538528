export const styles = {
  min: {
    marginLeft: 5,
    marginRight: 10,
    marginBottom: 5,
    borderWidth: 0,
    boxShadow: 'none',
    borderBottomWidth: 1,
  },
  text: {
    marginBottom: 5,
    borderWidth: 0,
    boxShadow: 'none',
    borderBottomWidth: 1,
    width: '100%',
  },
  max: {
    marginBottom: 5,
    borderWidth: 0,
    boxShadow: 'none',
    borderBottomWidth: 1,
    width: '100%',
  },
  colOption: { marginRight: 20, marginTop: 15 },
};
