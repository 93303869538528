import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';
import { Creators as CategoryActions } from '../ducks/categories';
import { Creators as ProductActions } from '../ducks/products';

const Constants = {
  DELETE_CATEGORY: 'category/delete',
  TOGGLE_CATEGORY: 'category/toggle',
  REORDER_CATEGORY: 'category/reorder',
};

export function* getCategories() {
  try {
    const { data, status } = yield call(api.get, '/menus/categories');

    if (status === 200) {
      yield put(CategoryActions.getCategoriesSuccess(data.data));

      if (data.total > 0) {
        yield put(
          ProductActions.getProducts({
            category_id: data.data[0].id,
          }),
        );
      }
      yield put(ProductActions.setCategorySuccess(data.total > 0 ? data.data[0] : null));
    }
  } catch (error) {
    yield put(CategoryActions.getCategoriesFailed(error.message));
  }
}

export function* createCategory({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/menus/categories', payload);

    if (status === 201) {
      message.success(`A Categoria '${payload.name}' foi criada com sucesso!`);
      yield put(CategoryActions.createCategorySuccess(data));
      yield put(CategoryActions.handleModalVisibility(false));
    }
  } catch (error) {
    message.error('Houve um erro ao criar a categoria, tente novamente mais tarde!');
    yield put(CategoryActions.createCategoryFailed());
  }
}

export function* editCategory({ payload }) {
  try {
    const { status } = yield call(api.put, `/menus/categories/${payload.id}`, payload);

    if (status === 204) {
      message.success('A Categoria foi alterada com sucesso!');
      yield put(CategoryActions.editCategorySuccess(payload));
      yield put(ProductActions.editActiveCategory(payload));
      yield put(CategoryActions.handleModalVisibility(false));
    }
  } catch (error) {
    message.error('Houve um erro ao editar a categoria, tente novamente mais tarde!');
    yield put(CategoryActions.editCategoryFailed());
  }
}

export function* reorderCategories({ payload }) {
  message.loading({
    content: 'Estamos processando a sua solicitação para reordenar as categorias!',
    key: Constants.REORDER_CATEGORY,
  });
  try {
    const { status } = yield call(api.post, `/menus/categories/${payload.id}/reorder`, payload);

    if (status === 200) {
      message.success({
        content: 'As categorias foram reordenadas com sucesso!',
        key: Constants.REORDER_CATEGORY,
      });
      yield put(CategoryActions.reorderCategoriesSuccess());
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error('Houve um erro ao reordenar as categorias, tente novamente mais tarde!');
    }
    yield put(CategoryActions.reorderCategoriesFailed());
  }
}

export function* toggleVisiblityCategory({ payload }) {
  message.loading({
    content: 'Estamos processando a sua solicitação para alterar a visibilidade da categoria!',
    key: Constants.TOGGLE_CATEGORY,
  });
  try {
    const { status } = yield call(api.post, `/menus/categories/${payload.id}/status`, {
      active: !payload.active,
    });

    if (status === 200) {
      message.success({
        content: 'A visibilidade da categoria foi alterada com sucesso!',
        key: Constants.TOGGLE_CATEGORY,
      });
      yield put(CategoryActions.toggleCategoryVisibilitySuccess(payload));
      yield put(ProductActions.editActiveCategory(payload));
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error(
        'Houve um erro ao alterar a visibilidade da categoria, tente novamente mais tarde!',
      );
    }
    yield put(CategoryActions.toggleCategoryVisibilityFailed());
  }
}

export function* deleteCategory({ payload }) {
  message.loading({
    content: 'Estamos processando a sua solicitação para deletar!',
    key: Constants.DELETE_CATEGORY,
  });
  try {
    const { status } = yield call(api.delete, `/menus/categories/${payload.id}`);

    if (status === 204) {
      message.success({
        content: `A categoria "${payload.name}" foi deletada com sucesso!`,
        key: Constants.DELETE_CATEGORY,
      });

      yield put(CategoryActions.deleteCategorySuccess(payload.id));
      yield put(ProductActions.removeCategorySuccess(payload.id));
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error('Houve um erro ao deletar a categoria, tente novamente mais tarde!');
    }
    yield put(CategoryActions.deleteCategoryFailed());
  }
}
