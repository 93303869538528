/* eslint-disable react/jsx-one-expression-per-line */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuid } from 'uuid';

import { Row, Col, Modal, Spin, Upload, Button, message, Typography } from 'antd';

import { PlusCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

import ImageCrop from '../../../../../../components/ImageCrop';

import {
  getBase64,
  beforeUpload,
  validateImageSize,
  base64ValidateSize,
} from '../../../../../../configs/utils';

import {
  Creators as ProductActions,
  Types as ProductTypes,
} from '../../../../../../store/ducks/products';

import './index.css';

const dimensions = {
  cover: {
    w: 1242,
    h: 690,
  },
  thumb: {
    w: 270,
    h: 270,
  },
};

// eslint-disable-next-line arrow-body-style
const formatFiles = (files) => {
  return files.map((file) => ({
    uid: file.image_id,
    itemId: file.item_id,
    imageType: file.type,
    name: 'default',
    status: file?.status ? file?.status : 'done',
    url: file.filename,
  }));
};

const { Text } = Typography;
const { confirm } = Modal;

// eslint-disable-next-line react/prop-types
const ProductImage = ({ visible }) => {
  const dispatch = useDispatch();
  const { itemImagesLoading, item } = useSelector((state) => state.products);

  const [defaultImage, setDefaultImage] = useState([]);

  const [imageCropVisible, setImageCropVisible] = useState(false);
  const [imageCropped, setImageCropped] = useState(null);
  const [src, setSrc] = useState(null);

  const handleSetImages = () => {
    if (item.image) {
      setDefaultImage(
        formatFiles([
          {
            image_id: item.image_id,
            item_id: item.id,
            type: 'png',
            filename: item.image,
          },
        ]),
      );
    } else {
      setDefaultImage([]);
    }
  };

  useEffect(() => {
    if (item.image_id) {
      handleSetImages();
    }
  }, [item]);

  // eslint-disable-next-line consistent-return
  const handleUpload = ({ file }, thumb = false) => {
    if (thumb && file.status === 'uploading') {
      // eslint-disable-next-line no-use-before-define
      return handleCrop(file);
    }

    switch (file.status) {
      case 'uploading':
        getBase64(file.originFileObj).then((base64) => {
          const { w, h } = dimensions.cover;

          validateImageSize(base64, w, h)
            .then(() => {
              const reference = uuid();

              dispatch(
                ProductActions.addImage({
                  id: reference,
                  reference,
                  item_id: null,
                  type_id: ProductTypes.IMAGE_COVER_TYPE,
                  status: 'uploading',
                  filename: '',
                }),
              );

              dispatch(
                ProductActions.uploadImage({
                  id: item.id,
                  filename: file.name,
                  image: base64,
                  type_id: ProductTypes.IMAGE_COVER_TYPE,
                  reference,
                }),
              );
            })
            .catch(() => {
              message.error(`A imagem precisa ter no mínimo ${w} x ${h} de tamanho.`);
            });
        });
        break;
      case 'removed':
        confirm({
          content: 'Você deseja realmente excluir a imagem?',
          icon: <ExclamationCircleOutlined />,
          title: 'Solicitação para excluir imagem',
          okText: 'Sim',
          okType: 'danger',
          cancelText: 'Não',
          onOk() {
            dispatch(
              ProductActions.removeImage({
                id: file.uid,
                itemId: file.itemId,
                type_id: file.imageType,
              }),
            );
          },
        });

      // eslint-disable-next-line no-fallthrough
      default:
        break;
    }
  };

  const handleImageCropped = (image) => {
    if (!base64ValidateSize(image.toDataURL(), 2048)) {
      return;
    }

    const reference = uuid();

    dispatch(
      ProductActions.addImage({
        id: reference,
        reference,
        item_id: null,
        type_id: ProductTypes.IMAGE_THUMB_TYPE,
        status: 'uploading',
        filename: '',
      }),
    );

    dispatch(
      ProductActions.uploadImage({
        id: item.id,
        filename: 'thumb_default',
        image: image.toDataURL(),
        type_id: ProductTypes.IMAGE_THUMB_TYPE,
        reference,
      }),
    );

    setImageCropVisible(false);
    setSrc(null);
    setImageCropped(null);
  };

  const handleCrop = (file) => {
    if (file) {
      if (file.originFileObj) {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
          const { w, h } = dimensions.thumb;

          validateImageSize(reader.result, w, h)
            .then(() => {
              setImageCropVisible(true);
              return setSrc(reader.result);
            })
            .catch(() => {
              message.error(`A imagem precisa ter no mínimo ${w} x ${h} de tamanho.`);
            });
        });

        reader.readAsDataURL(file.originFileObj);
      }
    }
  };

  return (
    <Modal
      width={1000}
      visible={visible}
      title={
        <div>
          Imagem para <strong> {item.name}</strong>
        </div>
      }
      onCancel={() => dispatch(ProductActions.handleModalImageVisibility(false))}
      footer={[
        <Button
          key="back"
          onClick={() => dispatch(ProductActions.handleModalImageVisibility(false))}
        >
          Fechar
        </Button>,
      ]}
    >
      <Spin spinning={itemImagesLoading} tip="Carregando imagens...">
        <Row>
          <Col xs={6} sm={4} lg={4} xl={4} md={4}>
            <h4>Principal</h4>
            <h5 style={{ color: '#8c8c8c' }}>
              Tamanho mínimo: <Text keyboard>270x270</Text>
            </h5>
          </Col>
          <Col xs={18} sm={4} lg={4} xl={4} md={4}>
            <div style={{ fontSize: '12px' }}>
              <Upload
                accept="image/*"
                maxCount={1}
                fileList={defaultImage}
                listType="picture-card"
                beforeUpload={beforeUpload}
                onChange={(file) => handleUpload(file, true)}
                customRequest={() => {}}
              >
                {defaultImage.length === 0 && (
                  <div>
                    <PlusCircleOutlined className="icon-upload" />
                    <div className="text-upload">Carregar imagem</div>
                  </div>
                )}
              </Upload>
            </div>
          </Col>
        </Row>
      </Spin>

      <Modal
        className="modal-crop"
        title="Definir imagem principal"
        closable={false}
        width={1000}
        visible={imageCropVisible}
        footer={[
          <Button
            key="back"
            onClick={() => {
              setImageCropVisible(false);
              setSrc(null);
              setImageCropped(null);
            }}
          >
            Cancelar
          </Button>,
          <Button
            key="submit"
            type="primary"
            disabled={!imageCropped}
            onClick={() => handleImageCropped(imageCropped)}
          >
            Salvar
          </Button>,
        ]}
      >
        <ImageCrop
          initialAspectRatio={1 / 1}
          aspectRatio={1 / 1}
          onCropped={setImageCropped}
          src={src}
        />
      </Modal>
    </Modal>
  );
};

export default ProductImage;
