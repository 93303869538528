/* eslint-disable react/prop-types */
import React, { useState, memo } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Tag, List, Switch, Divider } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Creators as OptionalActions } from '../../../../../../../store/ducks/optionals';

const styles = {
  tagRadius: {
    borderRadius: 10,
  },
};

const OptionalItem = ({ item, index, productId }) => {
  const dispatch = useDispatch();
  const [active, setActive] = useState(item.active);

  function toggleVisibility() {
    dispatch(
      OptionalActions.toggleOptionalItemVisibility({
        id: item.id,
      }),
    );
    setActive(!active);
  }

  function getPrice(itemPrice) {
    let price = 0.0;
    if (itemPrice) price = itemPrice;
    return parseFloat(price).toFixed(2).toString().replace('.', ',');
  }

  return (
    <Draggable
      key={item.id}
      isDragDisabled={!!productId}
      draggableId={item.id.toString()}
      index={index}
    >
      {(draggagleProvided) => (
        <div
          ref={draggagleProvided.innerRef}
          {...draggagleProvided.draggableProps}
          {...draggagleProvided.dragHandleProps}
          style={{
            ...draggagleProvided.draggableProps.style,
            flexDirection: 'column',
            padding: 5,
            backgroundColor: 'white',
          }}
        >
          <List.Item>
            <List.Item.Meta
              title={
                <>
                  <b>{item.name}</b>
                  &nbsp;&nbsp;
                  {item.pos_identifier && <small>{`(cód ${item.pos_identifier})`}</small>}
                </>
              }
              description={item.description}
            />
            {item.price && (
              <Tag color="#108ee9" style={styles.tagRadius}>
                {`R$ ${getPrice(item.price)}`}
              </Tag>
            )}
            <Tag color={active ? '#87d068' : '#f50'} style={styles.tagRadius}>
              {active ? 'Ativo' : 'Desativado'}
            </Tag>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={active}
              onChange={() => toggleVisibility()}
            />
          </List.Item>
          <Divider style={{ margin: 0 }} />
        </div>
      )}
    </Draggable>
  );
};

OptionalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    pos_identifier: PropTypes.string,
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  }).isRequired,
  index: PropTypes.number.isRequired,
  productId: PropTypes.number,
};

OptionalItem.defaultProps = {
  productId: null,
};

export default memo(OptionalItem);
