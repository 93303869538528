import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Button, Result, Spin, Modal, Select, Row, Col } from 'antd';

import { Wrapper, Content } from './styles';
import OptionalForm from './components/OptionalForm';
import Optional from './components/Optional';
import { Creators as OptionalCreators } from '../../../../store/ducks/optionals';

const { Option } = Select;

const OptionalList = ({ id }) => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [visible, setVisible] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [optionalId, setOptionalId] = useState(null);
  const [visibleVinculate, setVinculateVisible] = useState(false);
  const { data, loading, saving } = useSelector((state) => state.optionals);

  useEffect(() => {
    if (id) {
      setItems(
        data.filter((optional) => {
          const item = optional.items.find((product) => product.id === id);
          return !!item;
        }),
      );
    } else {
      setItems(data);
    }
  }, [id, data]);

  function renderOptionals() {
    if (items.length > 0) {
      return items.map((optional) => (
        <Optional
          key={optional.id}
          data={optional}
          productId={id}
          setVisible={setVisible}
          visible={visible}
        />
      ));
    }
    if (!id) {
      return (
        <Result
          status="404"
          title="Nenhum opcional cadastrado!"
          subTitle="Desculpe! A lista está vazia, tente cadastrar um item opcional."
        />
      );
    }

    return <p>Nenhum opcional vinculado a este produto</p>;
  }

  function filterOptionals(input, option) {
    return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  function onSelectOptional(value) {
    if (value.length !== 0) {
      setOptionalId(value);
      return setDisabled(false);
    }

    return setDisabled(true);
  }

  function handleVinculate() {
    dispatch(
      OptionalCreators.vinculateOptionalToProduct({
        product_id: id,
        optional_id: optionalId,
      }),
    );

    setTimeout(() => {
      if (!saving) {
        setVinculateVisible(false);
        setDisabled(true);
      }
    }, 500);
  }

  return (
    <Wrapper xs={24} sm={24} md={14} lg={24} xl={24}>
      <Card
        style={{ borderRadius: 5 }}
        title="Lista de Opcionais"
        extra={
          <>
            {id && (
              <Button
                onClick={() => {
                  setVinculateVisible(true);
                }}
                style={{ borderRadius: 20, marginRight: 10 }}
                type="primary"
              >
                Vincular Opcional
              </Button>
            )}
            <Button
              onClick={() => {
                dispatch(OptionalCreators.setOptionalEdit(null));
                setVisible(true);
              }}
              style={{ borderRadius: 20 }}
              type="primary"
            >
              Adicionar Opcional
            </Button>
          </>
        }
      >
        <OptionalForm visible={visible} setVisible={setVisible} productId={id} />
        {id && (
          <Modal
            title="Vincular Opcional"
            visible={visibleVinculate}
            maskClosable={false}
            onCancel={() => setVinculateVisible(false)}
            footer={[
              <Button key="back" onClick={() => setVinculateVisible(false)}>
                Cancelar
              </Button>,
              <Button
                key="submit"
                type="primary"
                disabled={disabled}
                loading={saving}
                onClick={() => handleVinculate()}
              >
                Salvar
              </Button>,
            ]}
          >
            <Row>
              <Col span={24}>
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Selecione um opcional"
                  // eslint-disable-next-line react/jsx-no-bind
                  onChange={onSelectOptional}
                  optionFilterProp="children"
                  // eslint-disable-next-line react/jsx-no-bind
                  filterOption={filterOptionals}
                >
                  {data.map((optional) => (
                    <Option key={optional.id} value={optional.id}>
                      {optional.name}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </Modal>
        )}
        <Content activeLoading={loading}>
          {loading ? (
            <Spin tip="Carregando opcionais..." size="large" style={{ marginTop: 5 }} />
          ) : (
            renderOptionals()
          )}
        </Content>
      </Card>
    </Wrapper>
  );
};

OptionalList.propTypes = {
  id: PropTypes.number,
};

OptionalList.defaultProps = {
  id: null,
};
export default OptionalList;
