import styled from 'styled-components';
import { Card, Tag } from 'antd';
import media from 'styled-media-query';

export const Wrapper = styled(Card)`
  .ant-card-body {
    display: ${({ visible }) => (visible ? 'block' : 'none')};
    transition: opacity 1s ease-out;
    opacity: ${({ visible }) => (visible ? 1 : 0)};
  }
`;

export const MobileTag = styled(Tag)`
  ${media.lessThan('small')`
    display: none
  `}
`;

export const styles = {
  tag: {
    borderRadius: 10,
    fontSize: 10,
    justifyContent: 'center',
    alignItems: 'center',
  },
  tagRadius: {
    borderRadius: 10,
  },
  dropdownIcon: {
    fontSize: 30,
    cursor: 'pointer',
    paddingLeft: 5,
    paddingRight: 10,
  },
  menu: { borderRadius: 10 },
  menuItem: {
    padding: 15,
  },

  menuItemDanger: {
    padding: 15,
    color: 'red',
  },
};
