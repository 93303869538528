/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/prop-types */
import React, { memo } from 'react';
import { Dropdown, Menu, Modal, Tag } from 'antd';
import PropTypes from 'prop-types';
import {
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';

import { Container, Informations, CouponInformation, Title, styles } from './styles';

import { Creators as CouponActions } from '../../store/ducks/coupons';

const { confirm } = Modal;

const Coupon = ({ item, setEditableCoupon }) => {
  const dispatch = useDispatch();

  function deleteCoupon() {
    confirm({
      content: `Você deseja realmente excluir o cupom "${item.title}"?`,
      icon: <ExclamationCircleOutlined />,
      title: 'Solicitação para excluir cupom',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        dispatch(
          CouponActions.deleteCoupon({
            id: item.id,
            title: item.title,
          }),
        );
      },
    });
  }

  return (
    <Container>
      <CouponInformation>
        <Informations>
          <Title>{item.title}</Title>
          {item.code}
        </Informations>
        <Informations>
          Máximo de usos: &nbsp;
          {item.max_uses}
          &nbsp;/ Usos: &nbsp;
          {item.used}
          &nbsp;/ Preço mínimo: &nbsp;
          {item.min_price}
        </Informations>
        {item.accumulative && (
          <Informations>
            <Tag color="#87d068" style={styles.tag}>
              Acumulativo
            </Tag>
          </Informations>
        )}
        {item.only_first_order && (
          <Informations>
            <Tag color="#b78282" style={styles.tag}>
              Somente para primeiro pedido
            </Tag>
          </Informations>
        )}
        {!item.active && (
          <Informations>
            <Tag color="#f50" style={styles.tag}>
              Inativo
            </Tag>
          </Informations>
        )}
      </CouponInformation>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Dropdown
          trigger={['click']}
          overlay={() => (
            <Menu style={{ borderRadius: 10 }}>
              <Menu.Item
                style={{
                  padding: 15,
                }}
                onClick={() => {
                  setEditableCoupon(item.id);
                  dispatch(CouponActions.handleModalVisibility(true));
                }}
              >
                <EditOutlined />
                Editar
              </Menu.Item>

              <Menu.Item
                style={{
                  color: 'red',
                  padding: 15,
                }}
                onClick={deleteCoupon}
              >
                <DeleteOutlined />
                Excluir
              </Menu.Item>
            </Menu>
          )}
          placement="topCenter"
        >
          <EllipsisOutlined
            size={90}
            style={{ fontSize: 40, paddingBottom: 5, cursor: 'pointer' }}
          />
        </Dropdown>
      </div>
    </Container>
  );
};

Coupon.propTypes = {
  setEditableCoupon: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    title: PropTypes.string.isRequired,
    usages: PropTypes.number,
    accumulative: PropTypes.bool,
    min_price: PropTypes.number,
    max_usages: PropTypes.number,
    name: PropTypes.string,
    image: PropTypes.shape({
      filename: PropTypes.string,
    }),
    active: PropTypes.bool,
  }).isRequired,
};

export default memo(Coupon);
