import Immutable from 'seamless-immutable';

export const Types = {
  CHANGE_VISIBLE_APP_REQUEST: 'restaurant/CHANGE_VISIBLE_APP_REQUEST',
  CHANGE_VISIBLE_APP_SUCCESS: 'restaurant/CHANGE_VISIBLE_APP_SUCCESS',
  CHANGE_VISIBLE_APP_FAILED: 'restaurant/CHANGE_VISIBLE_APP_FAILED',

  GET_RESTAURANT_IMAGE_REQUEST: 'restaurant/GET_RESTAURANT_IMAGE_REQUEST',
  GET_RESTAURANT_IMAGE_SUCCESS: 'restaurant/GET_RESTAURANT_IMAGE_SUCCESS',
  GET_RESTAURANT_IMAGE_FAILED: 'restaurant/GET_RESTAURANT_IMAGE_FAILED',

  GET_RESTAURANT_BASIC_INFO_REQUEST: 'restaurant/GET_RESTAURANT_BASIC_INFO_REQUEST',
  GET_RESTAURANT_BASIC_INFO_SUCCESS: 'restaurant/GET_RESTAURANT_BASIC_INFO_SUCCESS',
  GET_RESTAURANT_BASIC_INFO_FAILED: 'restaurant/GET_RESTAURANT_BASIC_INFO_FAILED',

  GET_RESTAURANT_DELIVERIES_REQUEST: 'restaurant/GET_RESTAURANT_DELIVERIES_REQUEST',
  GET_RESTAURANT_DELIVERIES_SUCCESS: 'restaurant/GET_RESTAURANT_DELIVERIES_SUCCESS',
  GET_RESTAURANT_DELIVERIES_FAILED: 'restaurant/GET_RESTAURANT_DELIVERIES_FAILED',

  GET_RESTAURANT_PENDENCIES_REQUEST: 'restaurant/GET_RESTAURANT_PENDENCIES_REQUEST',
  GET_RESTAURANT_PENDENCIES_SUCCESS: 'restaurant/GET_RESTAURANT_PENDENCIES_SUCCESS',
  GET_RESTAURANT_PENDENCIES_FAILED: 'restaurant/GET_RESTAURANT_PENDENCIES_FAILED',

  GET_RESTAURANT_SPECIFICATIONS_REQUEST: 'restaurant/GET_RESTAURANT_SPECIFICATIONS_REQUEST',
  GET_RESTAURANT_SPECIFICATIONS_SUCCESS: 'restaurant/GET_RESTAURANT_SPECIFICATIONS_SUCCESS',
  GET_RESTAURANT_SPECIFICATIONS_FAILED: 'restaurant/GET_RESTAURANT_SPECIFICATIONS_FAILED',

  GET_RESTAURANT_PLANS_REQUEST: 'restaurant/GET_RESTAURANT_PLANS_REQUEST',
  GET_RESTAURANT_PLANS_SUCCESS: 'restaurant/GET_RESTAURANT_PLANS_SUCCESS',
  GET_RESTAURANT_PLANS_FAILED: 'restaurant/GET_RESTAURANT_PLANS_FAILED',

  GET_RESTAURANT_BANK_ACCOUNTS_REQUEST: 'restaurant/GET_RESTAURANT_BANK_ACCOUNTS_REQUEST',
  GET_RESTAURANT_BANK_ACCOUNTS_SUCCESS: 'restaurant/GET_RESTAURANT_BANK_ACCOUNTS_SUCCESS',
  GET_RESTAURANT_BANK_ACCOUNTS_FAILED: 'restaurant/GET_RESTAURANT_BANK_ACCOUNTS_FAILED',

  UPDATE_RESTAURANT_BASIC_INFO_REQUEST: 'restaurant/UPDATE_RESTAURANT_BASIC_INFO_REQUEST',
  UPDATE_RESTAURANT_BASIC_INFO_SUCCESS: 'restaurant/UPDATE_RESTAURANT_BASIC_INFO_SUCCESS',
  UPDATE_RESTAURANT_BASIC_INFO_FAILED: 'restaurant/UPDATE_RESTAURANT_BASIC_INFO_FAILED',

  UPDATE_RESTAURANT_DELIVERIES_REQUEST: 'restaurant/UPDATE_RESTAURANT_DELIVERIES_REQUEST',
  UPDATE_RESTAURANT_DELIVERIES_SUCCESS: 'restaurant/UPDATE_RESTAURANT_DELIVERIES_SUCCESS',
  UPDATE_RESTAURANT_DELIVERIES_FAILED: 'restaurant/UPDATE_RESTAURANT_DELIVERIES_FAILED',

  UPDATE_RESTAURANT_SPECIFICATIONS_REQUEST: 'restaurant/UPDATE_RESTAURANT_SPECIFICATIONS_REQUEST',
  UPDATE_RESTAURANT_SPECIFICATIONS_SUCCESS: 'restaurant/UPDATE_RESTAURANT_SPECIFICATIONS_SUCCESS',
  UPDATE_RESTAURANT_SPECIFICATIONS_FAILED: 'restaurant/UPDATE_RESTAURANT_SPECIFICATIONS_FAILED',

  UPDATE_RESTAURANT_BANK_ACCOUNTS_REQUEST: 'restaurant/UPDATE_RESTAURANT_BANK_ACCOUNTS_REQUEST',
  UPDATE_RESTAURANT_BANK_ACCOUNTS_SUCCESS: 'restaurant/UPDATE_RESTAURANT_BANK_ACCOUNTS_SUCCESS',
  UPDATE_RESTAURANT_BANK_ACCOUNTS_FAILED: 'restaurant/UPDATE_RESTAURANT_BANK_ACCOUNTS_FAILED',

  STORE_RESTAURANT_BANK_ACCOUNTS_REQUEST: 'restaurant/STORE_RESTAURANT_BANK_ACCOUNTS_REQUEST',
  STORE_RESTAURANT_BANK_ACCOUNTS_SUCCESS: 'restaurant/STORE_RESTAURANT_BANK_ACCOUNTS_SUCCESS',
  STORE_RESTAURANT_BANK_ACCOUNTS_FAILED: 'restaurant/STORE_RESTAURANT_BANK_ACCOUNTS_FAILED',

  UPDATE_RESTAURANT_PLANS_REQUEST: 'restaurant/UPDATE_RESTAURANT_PLANS_REQUEST',
  UPDATE_RESTAURANT_PLANS_SUCCESS: 'restaurant/UPDATE_RESTAURANT_PLANS_SUCCESS',
  UPDATE_RESTAURANT_PLANS_FAILED: 'restaurant/UPDATE_RESTAURANT_PLANS_FAILED',

  UPLOAD_IMAGE_REQUEST: 'restaurant/UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'restaurant/UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILED: 'restaurant/UPLOAD_IMAGE_FAILED',

  UPLOAD_DOCUMENT_REQUEST: 'restaurant/UPLOAD_DOCUMENT_REQUEST',
  UPLOAD_DOCUMENT_SUCCESS: 'restaurant/UPLOAD_DOCUMENT_SUCCESS',
  UPLOAD_DOCUMENT_FAILED: 'restaurant/UPLOAD_DOCUMENT_FAILED',

  HANDLE_MODAL_VISIBILITY: 'restaurant/HANDLE_MODAL_VISIBILITY',

  GET_RESTAURANT_PLAN_REQUEST: 'restaurant/GET_RESTAURANT_PLAN_REQUEST',
  GET_RESTAURANT_PLAN_SUCCESS: 'restaurant/GET_RESTAURANT_PLAN_SUCCESS',
  GET_RESTAURANT_PLAN_FAILED: 'restaurant/GET_RESTAURANT_PLAN_FAILED',

  ASSOCIATE_PLAN_REQUEST: 'restaurant/ASSOCIATE_PLAN_REQUEST',
  ASSOCIATE_PLAN_SUCCESS: 'restaurant/ASSOCIATE_PLAN_SUCCESS',
  ASSOCIATE_PLAN_FAILED: 'restaurant/ASSOCIATE_PLAN_FAILED',

  GET_RESTAURANT_DOCUMENTS: 'restaurant/GET_RESTAURANT_DOCUMENTS',
  GET_RESTAURANT_DOCUMENTS_SUCCESS: 'restaurant/GET_RESTAURANT_DOCUMENTS_SUCCESS',
  GET_RESTAURANT_DOCUMENTS_FAILED: 'restaurant/GET_RESTAURANT_DOCUMENTS_FAILED',

  IMAGE_LOGO_TYPE: 1,
  IMAGE_COVER_TYPE: 2,
};

const INITIAL_STATE = Immutable({
  loading: false,
  saving: false,
  uploading: false,
  images: {
    logo: null,
    cover: null,
  },
  basicInfo: {},
  deliveries: {},
  specifications: {},
  plans: {},
  bankAccounts: {},
  data: null,
  pendencies: {},
  payments: [],
  visibleModal: false,
  plan: [],
  documents: [],
  loadingDocuments: false,

});

export default function restaurants(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_RESTAURANT_IMAGE_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_RESTAURANT_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        images: {
          ...state.images,
          logo: action.payload.logo?.filename,
          cover: action.payload.cover?.filename,
        },
      };
    }

    case Types.GET_RESTAURANT_IMAGE_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.GET_RESTAURANT_BASIC_INFO_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_RESTAURANT_BASIC_INFO_SUCCESS: {
      return {
        ...state,
        loading: false,
        basicInfo: action.payload.basicInfo,
      };
    }

    case Types.GET_RESTAURANT_BASIC_INFO_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.GET_RESTAURANT_DELIVERIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_RESTAURANT_DELIVERIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        deliveries: action.payload.deliveries,
      };
    }

    case Types.GET_RESTAURANT_DELIVERIES_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.CHANGE_VISIBLE_APP_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.CHANGE_VISIBLE_APP_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.CHANGE_VISIBLE_APP_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.GET_RESTAURANT_PENDENCIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_RESTAURANT_PENDENCIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        pendencies: action.payload,
      };
    }

    case Types.GET_RESTAURANT_PENDENCIES_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.GET_RESTAURANT_SPECIFICATIONS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_RESTAURANT_SPECIFICATIONS_SUCCESS: {
      return {
        ...state,
        loading: false,
        specifications: action.payload.specifications,
      };
    }

    case Types.GET_RESTAURANT_SPECIFICATIONS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.GET_RESTAURANT_PLANS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_RESTAURANT_PLANS_SUCCESS: {
      return {
        ...state,
        loading: false,
        plans: action.payload.plans,
      };
    }

    case Types.GET_RESTAURANT_PLANS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.GET_RESTAURANT_BANK_ACCOUNTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_RESTAURANT_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        bankAccounts: action.payload.bankAccounts,
      };
    }

    case Types.GET_RESTAURANT_BANK_ACCOUNTS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.UPDATE_RESTAURANT_BASIC_INFO_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.UPDATE_RESTAURANT_BASIC_INFO_SUCCESS: {
      return {
        ...state,
        saving: false,
        basicInfo: {
          ...action.payload,
        },
      };
    }

    case Types.UPDATE_RESTAURANT_BASIC_INFO_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }
    case Types.UPDATE_RESTAURANT_DELIVERIES_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.UPDATE_RESTAURANT_DELIVERIES_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.UPDATE_RESTAURANT_DELIVERIES_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }
    case Types.UPDATE_RESTAURANT_SPECIFICATIONS_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.UPDATE_RESTAURANT_SPECIFICATIONS_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.UPDATE_RESTAURANT_SPECIFICATIONS_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }
    case Types.UPDATE_RESTAURANT_BANK_ACCOUNTS_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.UPDATE_RESTAURANT_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.UPDATE_RESTAURANT_BANK_ACCOUNTS_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }
    case Types.STORE_RESTAURANT_BANK_ACCOUNTS_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.STORE_RESTAURANT_BANK_ACCOUNTS_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.STORE_RESTAURANT_BANK_ACCOUNTS_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }
    case Types.UPDATE_RESTAURANT_PLANS_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.UPDATE_RESTAURANT_PLANS_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.UPDATE_RESTAURANT_PLANS_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.UPLOAD_IMAGE_REQUEST: {
      return {
        ...state,
        uploading: true,
      };
    }

    case Types.UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        uploading: false,
        images: {
          cover: action.payload.type_id === Types.IMAGE_COVER_TYPE
            ? action.payload.url : state.images.cover,
          logo:
            action.payload.type_id === Types.IMAGE_LOGO_TYPE
              ? action.payload.url : state.images.logo,
        },
      };
    }

    case Types.UPLOAD_IMAGE_FAILED: {
      return {
        ...state,
        uploading: false,
      };
    }

    case Types.UPLOAD_DOCUMENT_REQUEST: {
      return {
        ...state,
        loadingDocuments: true,
      };
    }

    case Types.UPLOAD_DOCUMENT_SUCCESS: {
      return {
        ...state,
        loadingDocuments: false,
        data: {
          ...state.data,
        },
      };
    }

    case Types.UPLOAD_DOCUMENT_FAILED: {
      return {
        ...state,
        loadingDocuments: false,
      };
    }

    case Types.HANDLE_MODAL_VISIBILITY: {
      return {
        ...state,
        visibleModal: action.payload,
      };
    }

    case Types.GET_RESTAURANT_PLAN_REQUEST: {
      return {
        ...state,
      };
    }

    case Types.GET_RESTAURANT_PLAN_SUCCESS: {
      return {
        ...state,
        plan: action.payload,
        visibleModal: true,
      };
    }

    case Types.GET_RESTAURANT_PLAN_FAILED: {
      return {
        ...state,
        plan: [],
      };
    }

    case Types.ASSOCIATE_PLAN_REQUEST: {
      return {
        ...state,
      };
    }

    case Types.ASSOCIATE_PLAN_SUCCESS: {
      return {
        ...state,
        visibleModal: false,
      };
    }

    case Types.ASSOCIATE_PLAN_FAILED: {
      return {
        ...state,
        visibleModal: false,
      };
    }

    case Types.GET_RESTAURANT_DOCUMENTS: {
      return {
        ...state,
        loadingDocuments: true,
      };
    }

    case Types.GET_RESTAURANT_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        documents: action.payload,
        loadingDocuments: false,
      };
    }

    case Types.GET_RESTAURANT_DOCUMENTS_FAILED: {
      return {
        ...state,
        loadingDocuments: false,
        documents: [],
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  changeVisibleApp: () => ({
    type: Types.CHANGE_VISIBLE_APP_REQUEST,
  }),
  changeVisibleAppSuccess: () => ({
    type: Types.CHANGE_VISIBLE_APP_SUCCESS,
  }),
  changeVisibleAppFailed: () => ({
    type: Types.CHANGE_VISIBLE_APP_FAILED,
  }),
  getRestaurantBasicInfo: () => ({
    type: Types.GET_RESTAURANT_BASIC_INFO_REQUEST,
  }),

  getRestaurantBasicInfoSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_BASIC_INFO_SUCCESS,
    payload,
  }),

  getRestaurantBasicInfoFailed: () => ({
    type: Types.GET_RESTAURANT_BASIC_INFO_FAILED,
  }),

  getRestaurantDeliveries: () => ({
    type: Types.GET_RESTAURANT_DELIVERIES_REQUEST,
  }),

  getRestaurantDeliveriesSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_DELIVERIES_SUCCESS,
    payload,
  }),

  getRestaurantDeliveriesFailed: () => ({
    type: Types.GET_RESTAURANT_DELIVERIES_FAILED,
  }),

  getRestaurantPendencies: () => ({
    type: Types.GET_RESTAURANT_PENDENCIES_REQUEST,
  }),

  getRestaurantPendenciesSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_PENDENCIES_SUCCESS,
    payload,
  }),

  getRestaurantPendenciesFailed: () => ({
    type: Types.GET_RESTAURANT_PENDENCIES_FAILED,
  }),

  getRestaurantSpecifications: () => ({
    type: Types.GET_RESTAURANT_SPECIFICATIONS_REQUEST,
  }),

  getRestaurantSpecificationsSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_SPECIFICATIONS_SUCCESS,
    payload,
  }),

  getRestaurantSpecificationsFailed: () => ({
    type: Types.GET_RESTAURANT_SPECIFICATIONS_FAILED,
  }),

  getRestaurantPlans: () => ({
    type: Types.GET_RESTAURANT_PLANS_REQUEST,
  }),

  getRestaurantPlansSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_PLANS_SUCCESS,
    payload,
  }),

  getRestaurantPlansFailed: () => ({
    type: Types.GET_RESTAURANT_PLANS_FAILED,
  }),

  getRestaurantBankAccounts: () => ({
    type: Types.GET_RESTAURANT_BANK_ACCOUNTS_REQUEST,
  }),

  getRestaurantBankAccountsSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_BANK_ACCOUNTS_SUCCESS,
    payload,
  }),

  getRestaurantBankAccountsFailed: () => ({
    type: Types.GET_RESTAURANT_BANK_ACCOUNTS_FAILED,
  }),

  updateRestaurantBasicInfo: (payload) => ({
    type: Types.UPDATE_RESTAURANT_BASIC_INFO_REQUEST,
    payload,
  }),

  updateRestaurantBasicInfoSuccess: (payload) => ({
    type: Types.UPDATE_RESTAURANT_BASIC_INFO_SUCCESS,
    payload,
  }),

  updateRestaurantBasicInfoFailed: () => ({
    type: Types.UPDATE_RESTAURANT_BASIC_INFO_FAILED,
  }),
  updateRestaurantDeliveries: (payload) => ({
    type: Types.UPDATE_RESTAURANT_DELIVERIES_REQUEST,
    payload,
  }),

  updateRestaurantDeliveriesSuccess: (payload) => ({
    type: Types.UPDATE_RESTAURANT_DELIVERIES_SUCCESS,
    payload,
  }),

  updateRestaurantDeliveriesFailed: () => ({
    type: Types.UPDATE_RESTAURANT_DELIVERIES_FAILED,
  }),
  updateRestaurantSpecifications: (payload) => ({
    type: Types.UPDATE_RESTAURANT_SPECIFICATIONS_REQUEST,
    payload,
  }),

  updateRestaurantSpecificationsSuccess: (payload) => ({
    type: Types.UPDATE_RESTAURANT_SPECIFICATIONS_SUCCESS,
    payload,
  }),

  updateRestaurantSpecificationsFailed: () => ({
    type: Types.UPDATE_RESTAURANT_SPECIFICATIONS_FAILED,
  }),
  updateRestaurantPlans: (payload) => ({
    type: Types.UPDATE_RESTAURANT_PLANS_REQUEST,
    payload,
  }),

  updateRestaurantPlansSuccess: (payload) => ({
    type: Types.UPDATE_RESTAURANT_PLANS_SUCCESS,
    payload,
  }),

  updateRestaurantPlansFailed: () => ({
    type: Types.UPDATE_RESTAURANT_PLANS_FAILED,
  }),
  updateRestaurantBankAccounts: (payload) => ({
    type: Types.UPDATE_RESTAURANT_BANK_ACCOUNTS_REQUEST,
    payload,
  }),

  updateRestaurantBankAccountsSuccess: (payload) => ({
    type: Types.UPDATE_RESTAURANT_BANK_ACCOUNTS_SUCCESS,
    payload,
  }),

  updateRestaurantBankAccountsFailed: () => ({
    type: Types.UPDATE_RESTAURANT_BANK_ACCOUNTS_FAILED,
  }),

  storeRestaurantBankAccounts: (payload) => ({
    type: Types.STORE_RESTAURANT_BANK_ACCOUNTS_REQUEST,
    payload,
  }),

  storeRestaurantBankAccountsSuccess: (payload) => ({
    type: Types.STORE_RESTAURANT_BANK_ACCOUNTS_SUCCESS,
    payload,
  }),

  storeRestaurantBankAccountsFailed: () => ({
    type: Types.STORE_RESTAURANT_BANK_ACCOUNTS_FAILED,
  }),

  uploadImage: (payload) => ({
    type: Types.UPLOAD_IMAGE_REQUEST,
    payload,
  }),

  uploadImageSuccess: (payload) => ({
    type: Types.UPLOAD_IMAGE_SUCCESS,
    payload,
  }),

  uploadImageFailed: () => ({
    type: Types.UPLOAD_IMAGE_FAILED,
  }),

  uploadDocument: (payload) => ({
    type: Types.UPLOAD_DOCUMENT_REQUEST,
    payload,
  }),

  uploadDocumentSuccess: (payload) => ({
    type: Types.UPLOAD_DOCUMENT_SUCCESS,
    payload,
  }),

  uploadDocumentFailed: () => ({
    type: Types.UPLOAD_DOCUMENT_FAILED,
  }),

  getRestaurantImage: (payload) => ({
    type: Types.GET_RESTAURANT_IMAGE_REQUEST,
    payload,
  }),

  getRestaurantImageSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_IMAGE_SUCCESS,
    payload,
  }),

  getRestaurantImageFailed: (payload) => ({
    type: Types.GET_RESTAURANT_IMAGE_FAILED,
    payload,
  }),

  handleModalVisibility: (payload) => ({
    type: Types.HANDLE_MODAL_VISIBILITY,
    payload,
  }),

  getRestaurantPlan: (payload) => ({
    type: Types.GET_RESTAURANT_PLAN_REQUEST,
    payload,
  }),

  getRestaurantPlanSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_PLAN_SUCCESS,
    payload,
  }),

  getRestaurantPlanFailed: (payload) => ({
    type: Types.GET_RESTAURANT_PLAN_FAILED,
    payload,
  }),

  associatePlan: (payload) => ({
    type: Types.ASSOCIATE_PLAN_REQUEST,
    payload,
  }),

  associatePlanSuccess: (payload) => ({
    type: Types.ASSOCIATE_PLAN_SUCCESS,
    payload,
  }),

  associatePlanFailed: (payload) => ({
    type: Types.ASSOCIATE_PLAN_FAILED,
    payload,
  }),

  getRestaurantDocuments: (payload) => ({
    type: Types.GET_RESTAURANT_DOCUMENTS,
    payload,
  }),

  getRestaurantDocumentsSuccess: (payload) => ({
    type: Types.GET_RESTAURANT_DOCUMENTS_SUCCESS,
    payload,
  }),

  getRestaurantDocumentsFailed: (payload) => ({
    type: Types.GET_RESTAURANT_DOCUMENTS_FAILED,
    payload,
  }),
};
