/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Row, Col, Checkbox, Modal, Form, Button, Input, Select, Switch } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

import { Creators as ProductActions } from '../../../../../../store/ducks/products';
import InputGroup from '../../../../../../components/InputGroup';
import Icon from '../../../../../../components/Icon';

import './index.css';
import { getPrice } from '../../../../../../configs/utils';

const { Option } = Select;

const ProductForm = ({ visible, product }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const [disabled, setDisabled] = useState(true);
  const [active, setActive] = useState(true);
  const [finalPrice, setFinalPrice] = useState();

  const { data } = useSelector((state) => state.categories);
  const { category, saving } = useSelector((state) => state.products);
  const { types } = useSelector((state) => state.productTypes);

  useEffect(() => {
    setDisabled(true);

    if (visible) {
      if (product) {
        const typesMap = product.nutritional_infos.map((type) => type.id);
        // eslint-disable-next-line react/prop-types
        setActive(Boolean(product.active));

        setFinalPrice(product.original_price - product.discount_price);

        form.setFieldsValue({
          ...product,
          nutritional_infos: typesMap,
          menu_category_id: product.menu_category_id,
        });
      } else {
        setActive(true);
        form.resetFields();
        form.setFieldsValue({
          menu_category_id: category.id,
        });
      }
    }
  }, [visible, category, product, form]);

  const onFinish = (values) => {
    const newValues = values;
    newValues.price = values.original_price;
    newValues.active = active;
    newValues.nutritional_infos = values.nutritional_infos || [];
    newValues.adults = values.adults || false;

    if (product) {
      dispatch(
        ProductActions.editProduct({
          id: product.id,
          ...newValues,
        }),
      );
    } else {
      dispatch(ProductActions.createProduct(newValues));
    }
  };

  const handleVisibility = () => {
    dispatch(ProductActions.handleModalVisibility(false));
  };

  const handleOnChange = () => {
    setFinalPrice(form.getFieldValue('original_price') - form.getFieldValue('discount_price'));
  };

  return (
    <Modal
      title={product ? 'Editar Produto' : 'Cadastrar Produto'}
      maskClosable={false}
      centered
      visible={visible}
      onCancel={() => handleVisibility()}
      footer={[
        <Button key="back" onClick={() => handleVisibility()}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={disabled}
          loading={saving}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form
        form={form}
        initialValues={{
          pos_price: 0,
          percent_discount: 0,
        }}
        onFinish={onFinish}
        onChange={() => setDisabled(false)}
        layout="vertical"
      >
        <Row gutter={24}>
          <Col span={24}>
            <Form.Item
              name="name"
              label="Nome"
              rules={[{ required: true, message: 'O título do produto é obrigatório!' }]}
            >
              <Input maxLength="190" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="description" label="Descrição">
              <Input.TextArea />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Categoria" name="menu_category_id">
              <Select value={category ? category.id : null} onChange={() => setDisabled(false)}>
                {data.map((cat) => (
                  <Option key={cat.id} value={cat.id}>
                    {cat.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item label="Classificações" name="nutritional_infos">
              <Select
                className="select-types"
                mode="multiple"
                placeholder="Selecione as classificações"
                onChange={() => setDisabled(false)}
              >
                {types.map((type) => (
                  <Option style={{ paddingLeft: 0 }} key={type.id} value={type.id}>
                    <Icon width="25px" icon={`${type.icon}.svg`} />
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Preço Original"
              name="original_price"
              onChange={() => handleOnChange('original_price')}
              rules={[
                { required: true, message: 'É obrigatório informar um preço para o produto!' },
              ]}
            >
              <InputGroup
                style={{
                  width: '100%',
                }}
                onChange={() => setDisabled(false)}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Desconto"
              name="discount_price"
              onChange={() => handleOnChange('discount_price')}
            >
              <InputGroup
                prefix="R$"
                style={{
                  width: '100%',
                }}
                onChange={() => setDisabled(false)}
              />
            </Form.Item>
          </Col>

          <Col span={12} />
          <Col span={12} style={{ textAlign: 'right', marginBottom: 10 }}>
            <strong>Preço Final:</strong> &nbsp; R$ {getPrice(finalPrice)}
          </Col>

          <Col span={10}>
            <Form.Item name="active">
              <Switch
                checked={active}
                onChange={() => {
                  setActive(!active);
                  setDisabled(false);
                }}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
              <span style={{ marginLeft: 10 }}>Ativo</span>
            </Form.Item>
          </Col>

          <Col span={14} style={{ textAlign: 'right' }}>
            <Form.Item name="adults" valuePropName="checked">
              <Checkbox>Produto para maiores de 18 anos.</Checkbox>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

ProductForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  product: PropTypes.shape({
    id: PropTypes.number,
    nutritional_infos: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

ProductForm.defaultProps = {
  product: null,
};

export default ProductForm;
