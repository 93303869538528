import Immutable from 'seamless-immutable';

export const Types = {
  GET_AUTH_USER_SUCCESS: 'user/GET_AUTH_USER_SUCCESS',
  REMOVE_AUTH_USER_REQUEST: 'user/REMOVE_AUTH_USER_REQUEST',
  REMOVE_AUTH_USER_SUCCESS: 'user/REMOVE_AUTH_USER_SUCCESS',

  SHOW_CHANGE_PASSWORD_MODAL: 'user/SHOW_CHANGE_PASSWORD_MODAL',
};

const INITIAL_STATE = Immutable({
  user: null,
  showChangePasswordModal: false,
});

export default function me(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_AUTH_USER_SUCCESS: {
      return {
        ...state,
        user: action.payload,
      };
    }

    case Types.REMOVE_AUTH_USER_SUCCESS: {
      return {
        ...state,
        user: null,
      };
    }

    case Types.SHOW_CHANGE_PASSWORD_MODAL: {
      return {
        ...state,
        showChangePasswordModal: action.payload,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getAuthUserSuccess: (payload) => ({
    type: Types.GET_AUTH_USER_SUCCESS,
    payload,
  }),
  removeAuthUser: () => ({
    type: Types.REMOVE_AUTH_USER_REQUEST,
  }),
  removeAuthUserSuccess: () => ({
    type: Types.REMOVE_AUTH_USER_SUCCESS,
  }),
  showChangePasswordModal: (payload) => ({
    type: Types.SHOW_CHANGE_PASSWORD_MODAL,
    payload,
  }),
};
