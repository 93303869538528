import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Input,
  Form,
  Row,
  Col,
  Divider,
  Select,
} from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';

import { Creators as AuthCreators } from '../../store/ducks/auth';
import { Wrapper, LoginBox, RowText, RowLink, LoginButton } from './styles';

const { Option } = Select;

const Login = () => {
  const dispatch = useDispatch();
  const { loading, restaurants } = useSelector((state) => state.auth);
  const [restaurant, setRestaurant] = useState(null);

  const onFinish = (values) => {
    dispatch(AuthCreators.getAuth(values));
  };

  const onRestaurantSelection = async () => {
    await localStorage.setItem('restaurant_id', restaurant);
    dispatch(AuthCreators.handleAuth());
  };

  const handleForm = () => {
    if (!restaurants || restaurants.length === 0) {
      return (
        <Form
          name="getin"
          initialValues={{
            remember: false,
          }}
          onFinish={onFinish}
        >
          <Form.Item
            name="username"
            rules={[
              {
                required: true,
                message: 'O campo usuário/email é obrigatório!',
              },
            ]}
          >
            <Input
              size="large"
              placeholder="Usuário ou email"
              prefix={<UserOutlined style={{ marginRight: 10 }} />}
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              {
                required: true,
                message: 'O campo senha é obrigatório!',
              },
            ]}
          >
            <Input.Password
              size="large"
              placeholder="**********"
              prefix={<LockOutlined style={{ marginRight: 10 }} />}
            />
          </Form.Item>

          <p>
            <RowLink to="/reset-password">Esqueci minha senha</RowLink>
          </p>

          <Divider />
          <RowText>
            Não possui uma conta?&nbsp;
            <RowLink to="/register">Clique aqui para se registrar!</RowLink>
          </RowText>

          <Form.Item>
            <Row justify="center" style={{ marginTop: 30 }}>
              <LoginButton loading={loading} type="primary" htmlType="submit">
                Entrar
              </LoginButton>
            </Row>
          </Form.Item>
        </Form>
      );
    }

    return null;
  };

  const handleRestaurantSelection = () => {
    if (restaurants && restaurants.length > 0) {
      return (
        <div style={{ width: '283px' }}>
          <Select
            style={{
              width: '100%',
            }}
            defaultOpen
            autoFocus
            size="large"
            showSearch
            placeholder="Selecione o restaurante"
            optionFilterProp="children"
            onChange={(value) => setRestaurant(value)}
            filterOption={(input, option) => option.children.toLowerCase().indexOf(
              input.toLowerCase(),
            ) >= 0}
          >
            {
              restaurants.map((value) => (
                <Option
                  key={value.id}
                  value={value.id}
                >
                  {value.name}
                </Option>
              ))
            }
          </Select>

          <Row justify="center" style={{ marginTop: 30 }}>
            <LoginButton
              loading={loading}
              disabled={!restaurant}
              type="primary"
              onClick={onRestaurantSelection}
            >
              Acessar restaurante
            </LoginButton>
          </Row>
        </div>
      );
    }

    return null;
  };

  return (
    <Wrapper>
      <Row>
        <LoginBox>
          <Row justify="center" style={{ paddingTop: 10 }}>
            <img
              src="assets/icons/getin_logo.svg"
              alt="Get In Delivery"
              width="150"
            />
          </Row>
          <Divider />
          <Col>
            {handleForm()}
            {handleRestaurantSelection()}
          </Col>
        </LoginBox>
      </Row>
    </Wrapper>
  );
};

export default Login;
