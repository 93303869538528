import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';

import { Creators as RestaurantActions, Types as RestaurantTypes } from '../ducks/restaurants';

export function* getRestaurantBasicInfo() {
  try {
    const { status, data } = yield call(api.get, '/restaurants/settings/basic-info');

    if (status === 200) {
      yield put(
        RestaurantActions.getRestaurantBasicInfoSuccess({
          basicInfo: data,
        }),
      );

      yield put(RestaurantActions.getRestaurantImage());
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error(
        'Houve um erro ao buscar as informações deste restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.getRestaurantBasicInfoFailed());
  }
}

export function* getRestaurantDeliveries() {
  try {
    const { status, data } = yield call(api.get, '/restaurants/settings/deliveries');

    if (status === 200) {
      yield put(
        RestaurantActions.getRestaurantDeliveriesSuccess({
          deliveries: data,
        }),
      );
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error(
        'Houve um erro ao buscar as informações deste restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.getRestaurantDeliveriesFailed());
  }
}

export function* getRestaurantPendencies() {
  try {
    const { status, data } = yield call(api.get, '/restaurants/status/pendencies');

    if (status === 200) {
      yield put(RestaurantActions.getRestaurantPendenciesSuccess(data));
    }
  } catch (error) {
    yield put(RestaurantActions.getRestaurantPendenciesFailed());
  }
}

export function* getRestaurantSpecifications() {
  try {
    const { status, data } = yield call(api.get, '/restaurants/settings/specifications');

    if (status === 200) {
      yield put(
        RestaurantActions.getRestaurantSpecificationsSuccess({
          specifications: data,
        }),
      );
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error(
        'Houve um erro ao buscar as informações deste restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.getRestaurantSpecificationsFailed());
  }
}

export function* getRestaurantPlans() {
  try {
    const { status, data } = yield call(api.get, '/restaurants/payments/plans');

    if (status === 200) {
      yield put(
        RestaurantActions.getRestaurantPlansSuccess({
          plans: data,
        }),
      );
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error(
        'Houve um erro ao buscar as informações deste restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.getRestaurantPlansFailed());
  }
}

export function* getRestaurantBankAccounts() {
  try {
    const { status, data } = yield call(api.get, '/restaurants/payments/bank-accounts');

    if (status === 200) {
      yield put(
        RestaurantActions.getRestaurantBankAccountsSuccess({
          bankAccounts: data,
        }),
      );
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error(
        'Houve um erro ao buscar as informações deste restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.getRestaurantBankAccountsFailed());
  }
}

export function* getRestaurantImage() {
  try {
    const { data: images } = yield call(api.get, '/restaurants/images');

    const logo = images.data.find((image) => image.type_id === RestaurantTypes.IMAGE_LOGO_TYPE);
    const cover = images.data.find((image) => image.type_id === RestaurantTypes.IMAGE_COVER_TYPE);

    yield put(
      RestaurantActions.getRestaurantImageSuccess({
        logo: logo ?? null,
        cover: cover ?? null,
      }),
    );
  } catch (error) {
    message.warning('O seu restaurante ainda não possui uma imagem.');
    yield put(RestaurantActions.getRestaurantImageFailed());
  }
}

export function* editRestaurantBasicInfo({ payload }) {
  try {
    const { data, status } = yield call(api.put, '/restaurants/settings/basic-info', payload);

    if (status === 204) {
      message.success('As informações do restaurante foram atualizadas!');
      yield put(
        RestaurantActions.updateRestaurantBasicInfoSuccess({
          basicInfo: data,
        }),
      );
    }
  } catch (error) {
    if (
      error
      && error.response
      && error.response.data
      && error.response.data.message
      && error.response.status !== 500
    ) {
      message.warning(error.response.data.message, 6);
    } else {
      message.error(
        'Houve um erro ao editar as informações do restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.updateRestaurantBasicInfoFailed());
  }
}

export function* changeVisibleApp() {
  try {
    const { status } = yield call(api.post, '/restaurants/status', {});

    if (status === 204) {
      message.success('Status alterado com sucesso!');
      yield put(RestaurantActions.changeVisibleAppSuccess());
    }
  } catch (error) {
    message.error('Houve um erro ao alterar o status do restaurante!');
    yield put(RestaurantActions.changeVisibleAppFailed());
  }
}

export function* editRestaurantDeliveries({ payload }) {
  try {
    const { status } = yield call(api.put, '/restaurants/settings/deliveries', payload);

    if (status === 204) {
      message.success('As configurações de delivery do restaurante foram atualizadas!');
      yield put(RestaurantActions.updateRestaurantDeliveriesSuccess());
    }
  } catch (error) {
    if (
      error
      && error.response
      && error.response.data
      && error.response.data.message
      && error.response.status !== 500
    ) {
      message.warning(error.response.data.message, 6);
    } else {
      message.error(
        'Houve um erro ao editar as configurações de delivery do restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.updateRestaurantDeliveriesFailed());
  }
}

export function* editRestaurantSpecifications({ payload }) {
  try {
    const { status } = yield call(api.put, '/restaurants/settings/specifications', payload);

    if (status === 204) {
      message.success('As especificações do restaurante foram atualizadas!');
      yield put(RestaurantActions.updateRestaurantSpecificationsSuccess());
    }
  } catch (error) {
    if (
      error
      && error.response
      && error.response.data
      && error.response.data.message
      && error.response.status !== 500
    ) {
      message.warning(error.response.data.message, 6);
    } else {
      message.error(
        'Houve um erro ao editar as especificações do restaurante, tente novamente mais tarde!',
      );
    }
    yield put(RestaurantActions.updateRestaurantSpecificationsFailed());
  }
}

export function* editRestaurantPlans({ payload }) {
  try {
    const { status } = yield call(api.put, '/restaurants/payments/plans', payload);

    if (status === 204) {
      message.success('O plano de recebimento do restaurante foi atualizado!');
      yield put(RestaurantActions.updateRestaurantPlansSuccess());
    }
  } catch (error) {
    message.error(
      'Houve um erro ao editar o plano de recebimento do restaurante, tente novamente mais tarde!',
    );
    yield put(RestaurantActions.updateRestaurantPlansFailed());
  }
}

export function* editRestaurantBankAccounts({ payload }) {
  try {
    const { status } = yield call(api.put, '/restaurants/payments/bank-accounts', payload);

    if (status === 204) {
      message.success('As informações bancárias foram atualizadas!');
      yield put(RestaurantActions.updateRestaurantBankAccountsSuccess());
    }
  } catch (error) {
    message.error('Houve um erro ao editar as informações bancárias, tente novamente mais tarde!');
    yield put(RestaurantActions.updateRestaurantBankAccountsFailed());
  }
}

export function* storeRestaurantBankAccounts({ payload }) {
  try {
    const { status } = yield call(api.post, '/restaurants/payments/bank-accounts', payload);

    if (status === 200) {
      message.success('As informações bancárias foram salvas!');
      yield put(RestaurantActions.storeRestaurantBankAccountsSuccess());
    }
  } catch (error) {
    message.error('Houve um erro ao salvar as informações bancárias, tente novamente mais tarde!');
    yield put(RestaurantActions.storeRestaurantBankAccountsFailed());
  }
}

export function* uploadImage({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/restaurants/images', payload);

    if (status === 201) {
      message.success('A imagem do restaurante foi atualizada!');

      yield put(
        RestaurantActions.uploadImageSuccess({
          type_id: data.type_id,
          url: data.filename,
        }),
      );
    }
  } catch (error) {
    message.error(
      'Houve um erro ao fazer upload da imagem do restaurante, tente novamente mais tarde!',
    );

    yield put(RestaurantActions.uploadImageFailed());
  }
}

export function* uploadDocument({ payload }) {
  try {
    yield call(api.post, '/restaurants/documents', payload);

    message.success('Documento enviado com sucesso!');
    yield put(RestaurantActions.uploadDocumentSuccess());
    yield put(RestaurantActions.getRestaurantDocuments(payload.id));
  } catch (error) {
    const messageError = 'Houve um erro ao fazer upload do documento do restaurante, tente novamente mais tarde!';

    message.error(messageError);
    yield put(RestaurantActions.uploadDocumentFailed());
  }
}

export function* getRestaurantPlan({ payload }) {
  try {
    const { data, status } = yield call(api.get, `/payments/plans/${payload.id}`);

    if (status === 200) {
      yield put(RestaurantActions.getRestaurantPlanSuccess(data.data));
    }
  } catch (error) {
    yield put(RestaurantActions.getRestaurantPlanFailed(error.message));
  }
}

export function* associatePlan({ payload }) {
  try {
    const { data, status } = yield call(api.post, '/plans/subscriptions', payload);

    if (status === 200) {
      yield put(RestaurantActions.associatePlanSuccess(data.data));
    }
  } catch (error) {
    yield put(RestaurantActions.associatePlanFailed(error.message));
  }
}

export function* getDocuments() {
  try {
    const { data } = yield call(api.get, '/restaurants/documents');

    yield put(RestaurantActions.getRestaurantDocumentsSuccess(data.data));
  } catch (error) {
    yield put(RestaurantActions.getRestaurantDocumentsFailed());
  }
}
