import styled from 'styled-components';
import { Layout } from 'antd';
import media from 'styled-media-query';

export const Content = styled(Layout.Content)`
  overflow: auto;
  max-height: 55rem;
  background-color: white;
  padding: 20px;
  padding-top: 0;

  ${media.between('large', 'huge')`
    max-height: 21rem;
  `}

  ${media.greaterThan('1800px')`
    max-height: 50rem;
  `}

  ${media.lessThan('small')`
    max-height: 30rem;
  `}
`;
