import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';
import { Creators as CouponActions } from '../ducks/coupons';

import validation from '../../services/validation';

const Constants = {
  DELETE_COUPON: 'coupon/delete',
};

export function* getCoupons() {
  try {
    const { data, status } = yield call(
      api.get,
      '/coupons',
    );
    if (status === 200) {
      yield put(CouponActions.getCouponsSuccess(data.data));
    }
  } catch (error) {
    yield put(CouponActions.getCouponsFailed(error.message));
  }
}

export function* createCoupon({ payload }) {
  try {
    const { status } = yield call(api.post, '/coupons', payload);

    if (status === 201) {
      message.success(`O cupom '${payload.title}' foi criado com sucesso!`);

      yield put(
        CouponActions.createCouponSuccess(),
      );

      yield put(CouponActions.handleModalVisibility(false));
      yield put(CouponActions.getCoupons());
    }
  } catch (error) {
    let content = 'Houve um erro ao criar o cupom, tente novamente mais tarde!';

    if (error.response) {
      const { response } = error;

      if (response?.data?.message) {
        content = response.data.message;
      }

      if (response?.status === 422) {
        content = validation(response.data);
      }

      message.warn(content);
    } else {
      message.error(content);
    }

    yield put(CouponActions.createCouponFailed());
  }
}

export function* editCoupon({ payload }) {
  try {
    const { status } = yield call(api.put, `/coupons/${payload.id}`, payload);

    if (status === 204) {
      message.success('O cupom foi alterado com sucesso!');

      yield put(CouponActions.editCouponSuccess());
      yield put(CouponActions.handleModalVisibility(false));
      yield put(CouponActions.getCoupons());
    }
  } catch (error) {
    let content = 'Houve um erro ao editar o cupom, tente novamente mais tarde!';

    if (error.response) {
      const { response } = error;

      if (response?.data?.message) {
        content = response.data.message;
      }

      if (response?.status === 422) {
        content = validation(response.data);
      }

      message.warn(content);
    } else {
      message.error(content);
    }

    yield put(CouponActions.editCouponFailed());
  }
}

export function* deleteCoupon({ payload }) {
  message.loading({
    content: 'Por favor aguarde! Estamos processando a exclusão do cupom.',
    key: Constants.DELETE_COUPON,
  });

  try {
    const { status } = yield call(api.delete, `/coupons/${payload.id}`);

    if (status === 204) {
      message.success({
        content: `O cupom "${payload.title}" foi excluído com sucesso!`,
        key: Constants.DELETE_COUPON,
      });

      yield put(CouponActions.deleteCouponSuccess(payload.id));
    }
  } catch (error) {
    message.error('Houve um erro ao excluir o cupom, tente novamente mais tarde!');
    yield put(CouponActions.deleteCouponFailed());
  }
}

export function* getCoupon({ payload }) {
  try {
    const { data, status } = yield call(
      api.get,
      `/coupons/${payload.id}`,
    );

    if (status === 200) {
      yield put(CouponActions.getCouponSuccess(data));
    }
  } catch (error) {
    yield put(CouponActions.getCouponFailed(error.message));
  }
}
