import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Switch, Col, Card, Row } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { styles } from './styles';
import InputGroup from '../../../../../../../components/InputGroup';

function Optional({ type = 0, index, newOption, onChange }) {
  return (
    <>
      <Form.Item noStyle name={['options', index, 'id']}>
        <Input type="hidden" />
      </Form.Item>

      <Col hidden={type !== 1} span={24}>
        <Card size="small" style={{ marginTop: 15 }}>
          <Row>
            <Col span={17}>
              <Form.Item
                style={styles.formName}
                name={['options', index, 'name']}
                rules={[
                  { required: true, message: 'O nome da opção é obrigatório!' },
                  { max: 255, message: 'A quantidade máxima de caracteres é de 255' },
                  { type: 'string' },
                ]}
              >
                <Input onChange={onChange} maxLength={255} placeholder="Nome" style={styles.name} />
              </Form.Item>
            </Col>

            <Col span={5}>
              <Form.Item style={styles.marginItem} name={['options', index, 'final_price']}>
                <InputGroup onChange={onChange} style={styles.inputPrice} placeholder="0,00" />
              </Form.Item>
            </Col>

            {newOption && (
              <Col span={2}>
                <Form.Item name={['options', index, 'active']} valuePropName="checked">
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked
                    onChange={onChange}
                  />
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <Form.Item style={styles.marginItem} name={['options', index, 'description']}>
                <Input.TextArea onChange={onChange} placeholder="Descrição da opção" />
              </Form.Item>
            </Col>
          </Row>
        </Card>
      </Col>
    </>
  );
}

Optional.propTypes = {
  type: PropTypes.number,
  index: PropTypes.number.isRequired,
  newOption: PropTypes.bool,
  onChange: PropTypes.func,
};

Optional.defaultProps = {
  type: 0,
  newOption: false,
  onChange: () => {},
};

export default Optional;
