import { message } from 'antd';
import { call, put } from 'redux-saga/effects';

import api from '../../services/api';
import { Creators as ProductTypeActions } from '../ducks/productTypes';

export function* getProductTypes() {
  try {
    const { data, status } = yield call(
      api.get,
      '/nutritional-infos',
    );

    if (status === 200) {
      yield put(ProductTypeActions.setProductTypes(data.data));
    }
  } catch (error) {
    message.error('Houve um erro ao retornar os tipos de produto!');
  }
}
