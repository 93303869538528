/* eslint-disable react/self-closing-comp */
/* eslint-disable radix */
/* eslint-disable arrow-body-style */
/* eslint-disable no-param-reassign */
/* eslint-disable import/no-duplicates */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { Alert, Typography, Switch, Row, Col, Form, InputNumber, Button } from 'antd';

import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import InputGroup from '../../../../components/InputGroup';

import { Content } from './styles';
import { AlertContainer } from '../../styles';

import { Creators as RestaurantActions } from '../../../../store/ducks/restaurants';
import { Creators as RestaurantCreators } from '../../../../store/ducks/restaurants';

const { Title } = Typography;

const Delivery = ({ current, setCurrent }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const [deliveryTimeStart, setDeliveryTimeStart] = useState(0);
  const [deliveryTimeEnd, setDeliveryTimeEnd] = useState(0);
  const [values, setValues] = useState(null);
  const { deliveries, saving } = useSelector((state) => state.restaurants);

  useEffect(() => {
    if (deliveries) {
      const deliveriesValues = {
        ...deliveries,
      };

      deliveriesValues.delivery_enabled = true;
      form.setFieldsValue(deliveriesValues);
      setValues(deliveriesValues);
    }
  }, [deliveries, form]);

  useEffect(() => {
    dispatch(RestaurantCreators.getRestaurantDeliveries());
  }, [dispatch]);

  const checkFieldBoolean = (field) => {
    // eslint-disable-next-line radix
    return values && (values[field] === true || Boolean(parseInt(values[field])));
  };

  const getFieldBoolean = (field) => {
    return checkFieldBoolean(field) ? 'success' : 'error';
  };

  const handleOnChange = (field) => {
    setValues({
      ...values,
      [field]: !values[field],
    });

    setDisabled(false);
  };

  const onFinish = (formValues) => {
    formValues.delivery_fee = formValues.delivery_fee || null;
    dispatch(RestaurantActions.updateRestaurantDeliveries(formValues));
  };

  return (
    <>
      <Content>
        <Form
          form={form}
          onFinish={onFinish}
          initialValues={{
            delivery_start: deliveryTimeStart,
            delivery_end: deliveryTimeEnd,
          }}
        >
          <Row>
            <Col span={24}>
              <Alert
                message={<Title level={2}>Delivery</Title>}
                description={
                  <AlertContainer>
                    <div>
                      <p>Qual o tempo médio de entrega (em minutos).</p>
                      {checkFieldBoolean('delivery_enabled') ? (
                        <div style={{ display: 'flex' }}>
                          De &nbsp;&nbsp;
                          <Form.Item
                            name="min_delivery_time"
                            rules={[
                              {
                                required: true,
                                message:
                                  'É necessário informar o tempo de entrega mínimo estimado!',
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              max={60}
                              maxLength="2"
                              onChange={(value) => {
                                setDisabled(false);
                                setDeliveryTimeStart(parseInt(value));
                              }}
                            />
                          </Form.Item>
                          &nbsp;&nbsp; á &nbsp;&nbsp;
                          <Form.Item
                            name="max_delivery_time"
                            rules={[
                              {
                                required: true,
                                message:
                                  'É necessário informar o tempo de entrega máximo estimado!',
                              },
                            ]}
                          >
                            <InputNumber
                              min={0}
                              max={60}
                              maxLength="2"
                              onChange={(value) => {
                                setDisabled(false);
                                setDeliveryTimeEnd(parseInt(value));
                              }}
                            />
                          </Form.Item>
                          &nbsp;&nbsp; min
                        </div>
                      ) : null}
                    </div>
                    <div>
                      <Form.Item name="delivery_enabled" valuePropName="checked">
                        <Switch
                          style={{ marginTop: -35 }}
                          disabled
                          onChange={() => handleOnChange('delivery_enabled')}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </Form.Item>
                    </div>
                  </AlertContainer>
                }
                type={getFieldBoolean('delivery_enabled')}
                showIcon
              />
            </Col>

            <Col span={24}>
              <Alert
                message={<Title level={2}>Raio de entrega</Title>}
                description={
                  <AlertContainer>
                    <div>
                      <p>Digite o raio de entrega (em quilômetros).</p>
                      <Form.Item
                        name="delivery_radius"
                        rules={[
                          {
                            required: true,
                            message: 'É necessário informar o raio de entrega do restaurante',
                          },
                        ]}
                      >
                        <InputNumber
                          min={0}
                          max={100}
                          style={{ width: '30%' }}
                          onChange={() => handleOnChange('delivery_radius')}
                        />
                      </Form.Item>
                    </div>
                  </AlertContainer>
                }
                showIcon
              />
            </Col>

            <Col span={24}>
              <Alert
                message={<Title level={2}>Utilizar entregador próprio</Title>}
                description={
                  <AlertContainer>
                    <div>
                      <p>
                        Habilite caso prefira utilizar seus próprios entregadores.
                        <br></br>
                        <i>
                          Caso desabilitado utilizaremos nossos entregadores parceiros para as
                          entregas
                        </i>
                        <br></br>
                        {checkFieldBoolean('own_delivery') ? (
                          <div>
                            <p>Taxa de entrega.</p>
                            <br></br>
                            <i>Para entregador próprio é obrigatório informar a taxa de entrega</i>
                            <Form.Item
                              name="delivery_fee"
                              rules={[
                                {
                                  required: checkFieldBoolean('own_delivery'),
                                  message: 'É necessário informar a taxa de entrega',
                                },
                              ]}
                            >
                              <InputGroup onChange={() => setDisabled(false)} />
                            </Form.Item>
                          </div>
                        ) : null}
                      </p>
                    </div>
                    <div>
                      <Form.Item name="own_delivery" valuePropName="checked">
                        <Switch
                          style={{ marginTop: -35 }}
                          onChange={() => handleOnChange('own_delivery')}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </Form.Item>
                    </div>
                  </AlertContainer>
                }
                type={getFieldBoolean('own_delivery')}
                showIcon
              />
            </Col>
          </Row>
        </Form>
      </Content>

      <div
        style={{
          display: 'flex',
          flex: 1,
          paddingTop: 15,
          paddingBottom: 15,
          paddingRight: 20,
          justifyContent: 'flex-end',
        }}
      >
        <Button style={{ marginRight: 20 }} onClick={() => setCurrent(current - 1)}>
          Etapa Anterior
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          disabled={disabled}
          loading={saving}
          onClick={() => form.submit()}
        >
          Salvar Alterações
        </Button>
      </div>
    </>
  );
};

Delivery.propTypes = {
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
};

export default Delivery;
