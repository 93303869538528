/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';

import { Tooltip, Upload, Typography } from 'antd';

import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

import { getBase64, beforeUpload, base64ValidateSize } from '../../../../../../configs/utils';

import { Types as RestaurantTypes } from '../../../../../../store/ducks/restaurants';

import './index.css';
import ModalImage from './components/ModalImage';

const { Title } = Typography;

const ProfilePhoto = (props) => {
  const { restaurant, logo, uploading, onChangeImage } = props;

  const [modalImageVisible, setModalImageVisible] = useState(false);

  const uploadButton = (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div className="ant-upload-text">{uploading ? 'Carregando...' : 'Upload'}</div>
    </div>
  );

  const handleLogoChange = (info) => {
    if (info.file.originFileObj) {
      getBase64(info.file.originFileObj).then((base64) => {
        onChangeImage({
          id: restaurant.id,
          filename: info.file.name,
          image: base64,
          type_id: RestaurantTypes.IMAGE_LOGO_TYPE,
        });
      });
    }
  };

  const handleModalCropOpen = (event) => {
    event.persist();

    if (event.target.localName === 'img' || event.target.localName === 'div') {
      setModalImageVisible(true);
    }
  };

  const handleModalImageClose = () => setModalImageVisible(false);

  const handleCoverChange = (image) => {
    if (!base64ValidateSize(image.toDataURL(), 2048)) {
      return;
    }

    handleModalImageClose();

    onChangeImage({
      id: restaurant.id,
      filename: `${restaurant.id}_cover`,
      image: image.toDataURL(),
      type: RestaurantTypes.IMAGE_COVER_TYPE,
    });
  };

  return (
    <>
      <Tooltip placement="top">
        <div className="panel">
          <div className="child-logo">
            <Tooltip
              placement="right"
              title={<span>Faça o upload de uma imagem para sua loja!</span>}
            >
              <div>
                <Upload
                  name="avatar"
                  disabled={!restaurant}
                  listType="picture-card"
                  className="avatar-uploader"
                  onChange={handleLogoChange}
                  customRequest={() => null}
                  beforeUpload={beforeUpload}
                  showUploadList={false}
                  accept="image/*"
                >
                  {restaurant && logo && !uploading ? (
                    <div className="logo">
                      <img
                        src={logo}
                        alt="Logo"
                        style={{ borderRadius: '50%', backgroundColor: '#cccccc' }}
                      />
                    </div>
                  ) : (
                    uploadButton
                  )}
                </Upload>
              </div>
            </Tooltip>
          </div>

          <div className="child-name">
            <Title>{restaurant ? restaurant.name : 'Carregando informações...'}</Title>
          </div>
        </div>
      </Tooltip>

      <ModalImage
        visible={modalImageVisible}
        onSave={handleCoverChange}
        onClose={handleModalImageClose}
        onBeforeUpload={beforeUpload}
        validateDimensions
        dimensions={{
          w: 1242,
          h: 690,
        }}
      />
    </>
  );
};

export default ProfilePhoto;
