/* eslint-disable comma-dangle */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import axios from 'axios';
import PropTypes from 'prop-types';

import {
  Button,
  Input,
  Form,
  Select,
  Row,
  Col,
  message,
  Divider,
} from 'antd';
import { PhoneOutlined } from '@ant-design/icons';

import { Content } from './styles';

import { Creators as RestaurantActions } from '../../../../store/ducks/restaurants';
import InputMask from '../../../../components/InputMask';

import ProfileImage from './components/ProfileImage';
import { ALL_CATEGORIES as categories } from '../../../../constants/categories';
import { Creators as RestaurantCreators } from '../../../../store/ducks/restaurants';

const { Option } = Select;

const Details = ({ current, setCurrent }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);
  const {
    basicInfo,
    saving,
    images,
    uploading,
  } = useSelector((state) => state.restaurants);

  const { logo, cover } = images;

  useEffect(() => {
    dispatch(RestaurantCreators.getRestaurantBasicInfo());
  }, [dispatch]);

  useEffect(() => {
    if (basicInfo && basicInfo.address) {
      basicInfo.address_zipcode = basicInfo.address.zipcode;
      basicInfo.address_street = basicInfo.address.street;
      basicInfo.address_state = basicInfo.address.state;
      basicInfo.address_neighborhood = basicInfo.address.neighborhood;
      basicInfo.address_city = basicInfo.address.city;
      basicInfo.address_complement = basicInfo.address.complement;
      basicInfo.address_number = basicInfo.address.number;
    }
    form.setFieldsValue(basicInfo);
  }, [basicInfo, form]);

  async function handleZipcode(cep) {
    if (cep.replace(/[\W_]+/g, '').length >= 8) {
      message.loading({
        content: 'Buscando informações do CEP informado...',
        key: 'GET_CEP_INFO',
      });

      const { data, status } = await axios.get(
        `https://viacep.com.br/ws/${cep.replace(/[\W_]+/g, '')}/json/`,
      );

      if (status === 200) {
        form.setFieldsValue({
          ...form.getFieldsValue(),
          address_street: data.logradouro,
          address_state: data.uf,
          address_neighborhood: data.bairro,
          address_city: data.localidade,
        });
        message.success({
          content: 'O CEP foi encontrado!',
          key: 'GET_CEP_INFO',
        });
      } else {
        message.error({
          content: 'Houve um problema ao buscar o CEP informado! Tente novamente mais tarde.',
          key: 'GET_CEP_INFO',
        });
      }
    }
  }

  const handleChangeImage = (data) => {
    dispatch(RestaurantActions.uploadImage(data));
  };

  const onFinish = (values) => {
    dispatch(
      RestaurantActions.updateRestaurantBasicInfo({
        ...values,
        phone: values.phone.replace(/[\W_]+/g, ''),
        cnpj: values.cnpj.replace(/[\W_]+/g, ''),
        category_id: values.partner_category,
        address: {
          zipcode: values.address_zipcode,
          street: values.address_street,
          state: values.address_state,
          neighborhood: values.address_neighborhood,
          city: values.address_city,
          complement: values.address_complement,
          number: values.address_number,
        }
      }),
    );
  };

  return (
    <>
      <Divider />

      <Content>
        <Form
          name="detailStore"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onChange={() => setDisabled(false)}
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} lg={12} xl={12}>
              <ProfileImage
                onChangeImage={handleChangeImage}
                uploading={uploading}
                logo={logo}
                cover={cover}
                restaurant={basicInfo}
              />

              <Divider />

              <Form.Item name="about" label="Sobre a loja">
                <Input.TextArea rows={5} />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} lg={12} xl={12}>
              <Row gutter={24}>
                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    label="Nome"
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'O nome da loja é obrigatório!',
                      },
                    ]}
                  >
                    <Input placeholder="Nome da loja" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    name="partner_category"
                    label="Categoria"
                    hasFeedback
                    rules={[{ required: true, message: 'Selecione uma categoria' }]}
                  >
                    <Select
                      placeholder="Selecione uma categoria"
                      onChange={() => setDisabled(false)}
                    >
                      {
                        categories.map((category) => (
                          <Option key={category.value} value={category.value}>
                            {category.text}
                          </Option>
                        ))
                      }
                    </Select>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    name="cnpj"
                    label="CNPJ"
                    rules={[
                      { required: true, message: 'É obrigatório informar um CNPJ!' },
                      () => ({
                        validator(rule, value) {
                          if (!value || value.replace(/[\W_]+/g, '').length === 14) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('O CNPJ é inválido!'));
                        },
                      }),
                    ]}
                  >
                    <InputMask mask="99.999.999/9999-99" placeholder="CNPJ do estabelecimento" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    name="corporate_name"
                    label="Razão Social"
                    rules={[{ required: true, message: 'O campo Razão Social é obrigatório!' }]}
                  >
                    <Input placeholder="Razão Social" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    name="phone"
                    label="Telefone"
                    rules={[
                      { required: true, message: 'É necessário um telefone de contato!' },
                      () => ({
                        validator(rule, value) {
                          if (!value || value.replace(/[\W_]+/g, '').length >= 12) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('O número de telefone é inválido!'));
                        },
                      }),
                    ]}
                  >
                    <InputMask
                      mask="+55 (99) 99999-9999"
                      placeholder="Telefone para contato"
                      prefix={<PhoneOutlined style={{ marginRight: 10 }} />}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    name="address_zipcode"
                    label="CEP"
                    rules={[
                      {
                        required: true,
                        message: 'O campo CEP é obrigatório!',
                      },
                      () => ({
                        validator(rule, value) {
                          if (!value || value.replace(/[\W_]+/g, '').length >= 8) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('O CEP digitado é inválido!'));
                        },
                      }),
                    ]}
                  >
                    <InputMask
                      mask="99999-999"
                      placeholder="CEP"
                      onChange={(e) => handleZipcode(e.target.value)}
                    />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    label="Logradouro"
                    name="address_street"
                    rules={[
                      {
                        required: true,
                        message: 'O campo endereço é obrigatório!',
                      },
                    ]}
                  >
                    <Input placeholder="Endereço da loja" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    label="Número"
                    name="address_number"
                    rules={[
                      {
                        required: true,
                        message: 'O campo número é obrigatório!',
                      },
                    ]}
                  >
                    <Input placeholder="Número da loja" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    label="Bairro"
                    name="address_neighborhood"
                    rules={[
                      {
                        required: true,
                        message: 'O campo bairro é obrigatório!',
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item label="Complemento" name="address_complement">
                    <Input placeholder="Complemento da loja" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    label="Cidade"
                    name="address_city"
                    rules={[
                      {
                        required: true,
                        message: 'O campo cidade é obrigatório!',
                      },
                    ]}
                  >
                    <Input placeholder="Endereço da loja" />
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    label="UF"
                    name="address_state"
                    rules={[
                      {
                        required: true,
                        message: 'O campo UF é obrigatório!',
                      },
                    ]}
                  >
                    <Input maxLength="2" />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Content>

      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
          paddingBottom: 10,
          paddingRight: 20,
          backgroundColor: 'white',
        }}
      >
        <Button
          type="primary"
          disabled={disabled}
          loading={saving}
          htmlType="submit"
          onClick={() => form.submit()}
        >
          Salvar Alterações
        </Button>
      </div>
    </>
  );
};

Details.propTypes = {
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
};

export default Details;
