/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';

import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import { Tag, Menu, Dropdown, Modal } from 'antd';
import {
  DownOutlined,
  UpOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  DeleteOutlined,
  EllipsisOutlined,
} from '@ant-design/icons';
import { Wrapper, MobileTag, styles } from './styles';
import { Creators as OptionalActions } from '../../../../../../store/ducks/optionals';
import OptionalItem from './OptionalItem';

const { confirm } = Modal;

const getListStyle = (isDraggingOver) => ({
  width: '100%',
  background: isDraggingOver ? '#f2f2f2' : 'white',
  padding: 8,
  margin: 0,
});

const Optional = ({ data, productId, setVisible }) => {
  const [active, setActive] = useState(false);
  const [items, setItems] = useState([]);
  const dispatch = useDispatch();

  function deleteOptional() {
    confirm({
      content: `Você deseja realmente deletar o opcional "${data.name}" ?`,
      icon: <ExclamationCircleOutlined />,
      title: 'Solicitação para deletar opcional',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        dispatch(
          OptionalActions.deleteOptional({
            id: data.id,
            name: data.name,
          }),
        );
      },
    });
  }

  useEffect(() => {
    if (data.options.length > 0) {
      setItems(data.options);
    }
  }, [data.options]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    dispatch(
      OptionalActions.reorderOptionalItems({
        id: removed.id,
        old_position: startIndex,
        new_position: endIndex,
      }),
    );

    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(items, result.source.index, result.destination.index);
    setItems(newItems);
  };

  return (
    <Wrapper
      style={{ marginTop: 15 }}
      type="inner"
      visible={active ? 1 : 0}
      title={
        <>
          <Tag color="#87d068" style={styles.tag}>
            {data.type !== 'simple' ? 'Composta' : 'Simples'}
          </Tag>
          <b>{data.name}</b>
        </>
      }
      extra={
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {data.min_selectable > 0 && (
            <MobileTag color="red" style={styles.tag}>
              {`${data.min_selectable} Mínimo`}
            </MobileTag>
          )}
          {data.max_selectable > 0 && (
            <MobileTag color="green" style={styles.tag}>
              {`${data.max_selectable} Máximo`}
            </MobileTag>
          )}
          <MobileTag color={data.active ? '#87d068' : '#f50'} style={styles.tag}>
            {data.active ? 'Ativo' : 'Inativo'}
          </MobileTag>

          <Dropdown
            trigger={['click']}
            overlay={() => (
              <Menu style={styles.menu}>
                <Menu.Item
                  style={styles.menuItem}
                  onClick={() => {
                    dispatch(OptionalActions.setOptionalEdit(data));
                    setVisible(true);
                  }}
                >
                  <EditOutlined />
                  Editar
                </Menu.Item>
                <Menu.Item onClick={() => deleteOptional()} style={styles.menuItemDanger}>
                  <DeleteOutlined />
                  Excluir
                </Menu.Item>
              </Menu>
            )}
            placement="topCenter"
          >
            <EllipsisOutlined style={styles.dropdownIcon} />
          </Dropdown>
          {data.options.length > 0 && (
            <div onClick={() => setActive(!active)}>
              {!active ? <DownOutlined /> : <UpOutlined />}
            </div>
          )}
        </div>
      }
    >
      <small>{data.description}</small>

      {active && data.type !== 'simple' && (
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-optional-item">
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {items.map((item, itemIndex) => (
                  <OptionalItem key={item.id} item={item} index={itemIndex} productId={productId} />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      )}
    </Wrapper>
  );
};

Optional.propTypes = {
  data: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.array,
    max_selectable: PropTypes.number,
    min_selectable: PropTypes.number,
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  }).isRequired,
  setVisible: PropTypes.func.isRequired,
  productId: PropTypes.number,
};

Optional.defaultProps = {
  productId: null,
};

export default memo(Optional);
