import { createGlobalStyle } from 'styled-components';
import 'antd/dist/antd.min.css';

const GlobalStyle = createGlobalStyle`

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
  }

  html , body, #root{
    display: flex;
    height: 100%;
    width: 100%;
    flex: 1;
    flex-direction: column;
    -webkit-appearance: none;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
  }


`;

export default GlobalStyle;
