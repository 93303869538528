import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import { Card, Row, Col } from 'antd';

import './index.css';

const ImageCrop = ({ src, onCropped, aspectRatio, initialAspectRatio, children }) => {
  const cropperRef = useRef(null);

  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;

    onCropped(
      cropper.getCroppedCanvas({
        maxWidth: 1242,
        maxHeight: 690,
        imageSmoothingEnabled: true,
        imageSmoothingQuality: 'medium',
      }),
    );
  };

  if (src) {
    return (
      <>
        {children}
        <Card
          type="inner"
          title="Recorte e pré-visualização"
          style={{
            width: '100%',
          }}
        >
          <Row align="center" gutter={24}>
            <Col xs={24} sm={24} lg={12} xl={12}>
              <Cropper
                src={src}
                ref={cropperRef}
                cropend={onCrop}
                zoomable={false}
                checkCrossOrigin={false}
                aspectRatio={aspectRatio}
                initialAspectRatio={initialAspectRatio}
                preview=".img-preview"
              />
            </Col>

            <Col xs={24} sm={24} lg={12} xl={12}>
              <div className="box">
                <div className="img-preview" />
              </div>
            </Col>
          </Row>
        </Card>
      </>
    );
  }

  return null;
};

ImageCrop.propTypes = {
  src: PropTypes.string,
  onCropped: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number.isRequired,
  initialAspectRatio: PropTypes.number.isRequired,
  children: PropTypes.node,
};

ImageCrop.defaultProps = {
  src: null,
  children: null,
};

export default ImageCrop;
