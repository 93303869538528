import React, { useState } from 'react';
import { Row } from 'antd';

import CategoryList from './components/CategoryList';
import ProductList from './components/ProductList';
import OptionalList from './components/OptionalList';
import { Wrapper, TabPainel } from './styles';

const { TabPane } = TabPainel;

const Menu = () => {
  const [tab, setTab] = useState('1');

  return (
    <TabPainel animated={false} defaultActiveKey={tab} onChange={(key) => setTab(key)}>
      <TabPane tab="Cardápio Principal" key="1">
        <Wrapper>
          <Row gutter={24}>
            <CategoryList />
            <ProductList />
          </Row>
        </Wrapper>
      </TabPane>
      <TabPane tab="Opcionais" key="2">
        <Wrapper>
          <Row justify="space-between">
            <OptionalList />
          </Row>
        </Wrapper>
      </TabPane>
    </TabPainel>
  );
};

export default Menu;
