import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isAuthenticated } from '../configs/utils';

const PublicRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector((state) => state.me);

  function verifyAuth(props) {
    return !isAuthenticated(user) ? <Component {...props} /> : <Redirect to="/settings" />;
  }

  return <Route {...rest} render={(props) => verifyAuth(props)} />;
};

PublicRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default PublicRoute;
