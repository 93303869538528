/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Table } from 'antd';
import { Creators as RestaurantActions } from '../../../../store/ducks/restaurants';
import { getPrice, planMutator } from '../../../../configs/utils';

const PlanModel = ({ visible }) => {
  const [cardBrand, setCardBrand] = useState([]);
  const [salesPlan, setSalesPlan] = useState({});
  const dispatch = useDispatch();
  const { plan } = useSelector((state) => state.restaurants);

  // eslint-disable-next-line arrow-body-style
  const getOnlyInstallmentCreditOnce = (cards) => {
    return cards.filter((card) => (
      card.payment_type === 'credit'
        && card.capture_mode === 'manually_keyed'
        && card.number_installments === 1
        && card.source === 'zoop'));
  };

  const fillDataSource = (cards) => {
    const dataSource = cards.map((card) => {
      let feePercent = 0;
      if (card.percent_amount && card.percent_amount > 0) {
        feePercent = card.percent_amount / 100;
      }

      let fee = 0;
      if (card.dollar_amount && card.dollar_amount > 0) {
        fee = card.dollar_amount / 100;
      }

      return {
        key: card.type,
        brand: card.card_brand,
        fee_percent: `${getPrice(feePercent)} %`,
        fee: `R$ ${getPrice(fee)}`,
      };
    });

    setCardBrand(dataSource);
  };

  useEffect(() => {
    if (!plan || !plan.fee_details || plan.fee_details.length === 0) {
      return;
    }

    setSalesPlan({
      title: plan.description || 'Plano de venda',
      sellerId: plan.sellerId,
      id: plan.id,
    });

    const cards = getOnlyInstallmentCreditOnce(plan.fee_details);

    if (cards && cards.length > 0) {
      fillDataSource(cards);
    }
  }, [plan]);

  const handleCloseModal = () => {
    dispatch(RestaurantActions.handleModalVisibility(false));
  };

  const handleOkModal = () => {
    const payload = {
      customer: salesPlan.sellerId,
      plan: salesPlan.id,
      quantity: 1,
    };

    dispatch(RestaurantActions.associatePlan(payload));
  };

  const columns = [
    {
      title: 'Bandeira',
      dataIndex: 'brand',
      key: 'brand',
    },
    {
      title: '(%)',
      dataIndex: 'fee_percent',
      key: 'fee_percent',
    },
    {
      title: 'Taxa',
      dataIndex: 'fee',
      key: 'fee',
    },
  ];

  if (!plan || !plan.id) {
    return null;
  }

  return (
    <Modal
      title={planMutator(plan, 'description')}
      visible={visible}
      onOk={() => handleOkModal()}
      okText="Associar Plano"
      onCancel={() => handleCloseModal()}
      cancelText="Cancelar"
    >
      <Table dataSource={cardBrand} columns={columns} size="small" pagination={false} />
    </Modal>
  );
};

export default PlanModel;
