import React from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { Input, Form, Typography, Checkbox, Row, Col, Divider, message, Select } from 'antd';
import { MailOutlined, LockOutlined, UserOutlined, PhoneOutlined } from '@ant-design/icons';

import { Creators as AuthCreators } from '../../store/ducks/auth';

import InputMask from '../../components/InputMask';
import { validateCnpj, isValidCpf } from '../../configs/utils';

import { Wrapper, LoginButton, LoginBox } from './styles';
import { ALL_CATEGORIES } from '../../constants/categories';

const { Title } = Typography;
const { Option } = Select;

const Register = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { loading } = useSelector((state) => state.auth);

  const onFinishFailed = () => {
    message.error(
      'Existem campos obrigatórios não preenchidos ou com preenchimento incorreto! Verifique os campos e tente novamente.',
    );
  };

  const formataStringData = (date) => {
    const day = date.split('/')[0];
    const month = date.split('/')[1];
    const year = date.split('/')[2];

    return `${year}-${`0${month}`.slice(-2)}-${`0${day}`.slice(-2)}`;
  };

  const onFinish = (values) => {
    dispatch(
      AuthCreators.registerUser({
        restaurant: {
          name: values.restaurant_name,
          corporate_name: values.restaurant_corporate_name,
          cnpj: values.restaurant_cnpj.replace(/[\W_]+/g, ''),
          phone: values.restaurant_phone.replace(/[\W_]+/g, ''),
          terms_use: values.restaurant_terms_use,
          opening_date: formataStringData(values.restaurant_opening_date),
          category_id: values.restaurant_category_id,
        },
        owner: {
          name: values.owner_name,
          cpf: values.owner_cpf.replace(/[\W_]+/g, ''),
          email: values.owner_email,
          phone: values.owner_phone,
          birthdate: formataStringData(values.owner_birthdate),
        },
        user: {
          username: values.user_username,
          password: values.user_password,
          password_confirmation: values.user_password_confirmation,
          email: values.user_email,
        },
        address: {
          street: values.address_street,
          number: values.address_number,
          complement: values.address_complement,
          neighborhood: values.address_neighborhood,
          zipcode: values.address_zipcode.replace(/[\W_]+/g, ''),
          city: values.address_city,
          state: values.address_state,
          country: values.address_country,
        },
      }),
    );
  };

  async function handleZipcode(cep) {
    if (cep.replace(/[\W_]+/g, '').length >= 8) {
      message.loading({
        content: 'Buscando informações do CEP informado...',
        key: 'GET_CEP_INFO',
      });
      const { data, status } = await axios.get(
        `https://viacep.com.br/ws/${cep.replace(/[\W_]+/g, '')}/json/`,
      );
      if (status === 200) {
        form.setFieldsValue({
          ...form.getFieldsValue(),
          address_street: data.logradouro,
          address_state: data.uf,
          address_country: 'Brasil',
          address_neighborhood: data.bairro,
          address_city: data.localidade,
        });

        message.success({
          content: 'O CEP foi encontrado!',
          key: 'GET_CEP_INFO',
        });
      } else {
        message.error({
          content: 'Houve um problema ao buscar o CEP informado! Tente novamente mais tarde.',
          key: 'GET_CEP_INFO',
        });
      }
    }
  }

  const PasswordRuleAlertUl = {
    border: '1px solid',
    borderRadius: '6px',
    padding: '20px 5px 20px 25px',
    display: 'flex',
    width: 'auto',
    flex: 1,
    borderColor: '#ffeeba',
    backgroundColor: '#fff3cd',
    color: '#856404',
    fontSize: '11px',
    fontWeight: 'bold',
    listStyle: 'none',
  };

  const PasswordRuleAlertSubUl = {
    paddingLeft: '16px',
    paddingTop: '10px',
  };

  return (
    <Wrapper>
      <Row justify="center">
        <LoginBox md={12} sm={24}>
          <Row justify="center">
            <Col>
              <img src="assets/icons/getin_logo.svg" alt="Get In Delivery" width="150" />
            </Col>
          </Row>
          <Row justify="center">
            <Col>
              <Title level={4} style={{ marginTop: 10 }}>
                Registrar nova conta
              </Title>
            </Col>
          </Row>

          <Divider style={{ marginTop: 5, marginBottom: 5 }} />

          <Form
            id="form"
            layout="vertical"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              telefone_prefix: '+55',
            }}
          >
            <Row justify="left" style={{ paddingTop: 10, paddingBottom: 10 }}>
              <Col>
                <Title level={4}>Dados do restaurante</Title>
              </Col>
            </Row>

            <Row justify="space-between">
              <Col xs={24} xl={11}>
                <Form.Item
                  name="restaurant_name"
                  label="Nome do restaurante"
                  rules={[{ required: true, message: 'O nome do restaurante é obrigatório!' }]}
                >
                  <Input size="large" placeholder="Nome do restaurante" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={11}>
                <Form.Item
                  name="restaurant_corporate_name"
                  label="Razão social"
                  rules={[{ required: true, message: 'O campo razão social é obrigatório!' }]}
                >
                  <Input size="large" placeholder="Razão social" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} xl={11}>
                <Form.Item
                  name="restaurant_cnpj"
                  label="CNPJ"
                  rules={[
                    { required: true, message: 'É obrigatório informar um CNPJ!' },
                    () => ({
                      validator(rule, value) {
                        if (value && validateCnpj(value)) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('O CNPJ é inválido!'));
                      },
                    }),
                  ]}
                >
                  <InputMask
                    mask="99.999.999/9999-99"
                    size="large"
                    placeholder="CNPJ do estabelecimento"
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={11}>
                <Form.Item name="restaurant_opening_date" label="Data de abertura">
                  <InputMask mask="99/99/9999" placeholder="Data de abertura" size="large" />
                </Form.Item>
              </Col>

              <Col xs={24} xl={11}>
                <Form.Item
                  name="restaurant_phone"
                  label="Telefone"
                  rules={[
                    { required: true, message: 'É necessário um telefone de contato!' },
                    () => ({
                      validator(rule, value) {
                        if (!value || value.replace(/[\W_]+/g, '').length >= 12) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('O número de telefone é inválido!'));
                      },
                    }),
                  ]}
                >
                  <InputMask
                    mask="+55 (99) 99999-9999"
                    placeholder="Telefone para contato"
                    size="large"
                    autoComplete="none"
                    prefix={<PhoneOutlined style={{ marginRight: 10 }} />}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} xl={11}>
                <Form.Item
                  name="restaurant_category_id"
                  label="Categoria"
                  hasFeedback
                  rules={[{ required: true, message: 'Selecione a categoria do restaurante' }]}
                >
                  <Select placeholder="Selecione uma categoria" size="large">
                    {ALL_CATEGORIES.map((category) => (
                      <Option key={category.value} value={category.value}>
                        {category.text}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row justify="left" style={{ paddingTop: 0, paddingBottom: 10 }}>
              <Col>
                <Title level={4}>Dados do proprietário</Title>
              </Col>
            </Row>

            <Row>
              <Col xs={24}>
                <Form.Item
                  name="owner_name"
                  label="Nome do responsável"
                  rules={[
                    { required: true, message: 'O campo nome do responsável é obrigatório!' },
                  ]}
                >
                  <Input size="large" placeholder="Nome do responsável" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} xl={11}>
                <Form.Item
                  name="owner_cpf"
                  label="CPF"
                  rules={[
                    { required: true, message: 'É obrigatório informar o CPF do responsável!' },
                    () => ({
                      validator(_, value) {
                        if (value && isValidCpf(value.replace(/[\W_]+/g, ''))) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('O CPF é inválido!'));
                      },
                    }),
                  ]}
                >
                  <InputMask mask="999.999.999-99" size="large" placeholder="CPF do responsável" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={11}>
                <Form.Item
                  name="owner_email"
                  label="E-mail"
                  rules={[{ required: true, message: 'O campo e-mail é obrigatório!' }]}
                >
                  <Input
                    size="large"
                    placeholder="E-mail"
                    prefix={<MailOutlined style={{ marginRight: 10 }} />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} xl={11}>
                <Form.Item
                  name="owner_phone"
                  label="Telefone"
                  rules={[
                    {
                      required: true,
                      message: 'É necessário um telefone de contato!',
                    },
                    () => ({
                      validator(rule, value) {
                        if (!value || value.replace(/[\W_]+/g, '').length >= 12) {
                          return Promise.resolve();
                        }

                        return Promise.reject(new Error('O número de telefone é inválido!'));
                      },
                    }),
                  ]}
                >
                  <InputMask
                    mask="+55 (99) 99999-9999"
                    placeholder="Telefone para contato"
                    size="large"
                    autoComplete="none"
                    prefix={<PhoneOutlined style={{ marginRight: 10 }} />}
                  />
                </Form.Item>
              </Col>

              <Col xs={24} xl={11}>
                <Form.Item name="owner_birthdate" label="Data de nascimento">
                  <InputMask mask="99/99/9999" placeholder="Data de nascimento" size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row justify="left" style={{ paddingTop: 0, paddingBottom: 10 }}>
              <Col>
                <Title level={4}>Dados de acesso</Title>
              </Col>
            </Row>

            <Row justify="space-between">
              <Col xs={24}>
                <Form.Item
                  name="user_username"
                  label="Usuário"
                  rules={[
                    {
                      required: true,
                      message: 'É obrigatório um usuário para login!',
                    },
                  ]}
                >
                  <Input
                    size="large"
                    placeholder="Usuário"
                    prefix={<UserOutlined style={{ marginRight: 10 }} />}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col xs={24} xl={24}>
                <ul style={PasswordRuleAlertUl}>
                  <li>
                    Regras de senha:
                    <ul style={PasswordRuleAlertSubUl}>
                      <li>Mínimo de 6 caracteres.</li>
                      <li>Possuir pelo menos uma letra maiúscula.</li>
                      <li>Possuir pelo menos uma letra minúscula.</li>
                      <li>Possuir pelo menos um número.</li>
                      <li>
                        Possuir pelo menos um caracter especial. (Aceitos: @ $ ! % * ? _ - . : & #)
                      </li>
                    </ul>
                  </li>
                </ul>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} xl={11}>
                <Form.Item
                  name="user_password"
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'O campo senha é obrigatório!',
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Senha"
                    prefix={<LockOutlined style={{ marginRight: 10 }} />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={11}>
                <Form.Item
                  name="user_password_confirmation"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Por favor confirme sua senha!',
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value || getFieldValue('user_password') === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('As senhas inseridas não correspondem!'));
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Confirmar Senha"
                    prefix={<LockOutlined style={{ marginRight: 10 }} />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} xl={24}>
                <Form.Item
                  name="user_email"
                  label="E-mail"
                  rules={[{ required: true, message: 'O campo e-mail é obrigatório!' }]}
                >
                  <Input
                    size="large"
                    placeholder="E-mail"
                    prefix={<MailOutlined style={{ marginRight: 10 }} />}
                  />
                </Form.Item>
              </Col>
            </Row>

            <Divider />

            <Row justify="left" style={{ paddingTop: 0, paddingBottom: 10 }}>
              <Col>
                <Title level={4}>Endereço</Title>
              </Col>
            </Row>

            <Row justify="space-between">
              <Col xs={24} xl={11}>
                <Form.Item
                  name="address_zipcode"
                  label="CEP"
                  rules={[
                    {
                      required: true,
                      message: 'O campo CEP é obrigatório!',
                    },
                    () => ({
                      validator(rule, value) {
                        if (!value || value.replace(/[\W_]+/g, '').length >= 8) {
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('O CEP digitado é inválido!'));
                      },
                    }),
                  ]}
                >
                  <InputMask
                    size="large"
                    mask="99999-999"
                    placeholder="CEP"
                    onChange={(e) => handleZipcode(e.target.value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} xl={16}>
                <Form.Item
                  label="Logradouro"
                  name="address_street"
                  rules={[
                    {
                      required: true,
                      message: 'O campo endereço é obrigatório!',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Endereço da loja" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={6}>
                <Form.Item
                  label="Número"
                  name="address_number"
                  rules={[
                    {
                      required: true,
                      message: 'O campo número é obrigatório!',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Número da loja" />
                </Form.Item>
              </Col>
            </Row>
            <Row justify="space-between">
              <Col xs={24} xl={11}>
                <Form.Item label="Complemento" name="address_complement">
                  <Input size="large" placeholder="Complemento do endereço" />
                </Form.Item>
              </Col>

              <Col xs={24} xl={11}>
                <Form.Item
                  label="Bairro"
                  name="address_neighborhood"
                  rules={[
                    {
                      required: true,
                      message: 'O campo bairro é obrigatório!',
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row justify="space-between">
              <Col xs={24} xl={10}>
                <Form.Item
                  label="Cidade"
                  name="address_city"
                  rules={[
                    {
                      required: true,
                      message: 'O campo cidade é obrigatório!',
                    },
                  ]}
                >
                  <Input size="large" placeholder="Cidade da loja" />
                </Form.Item>
              </Col>

              <Col xs={24} xl={4}>
                <Form.Item
                  label="UF"
                  name="address_state"
                  rules={[
                    {
                      required: true,
                      message: 'O campo UF é obrigatório!',
                    },
                  ]}
                >
                  <Input size="large" />
                </Form.Item>
              </Col>
              <Col xs={24} xl={8}>
                <Form.Item
                  label="País"
                  name="address_country"
                  rules={[
                    {
                      required: true,
                      message: 'O campo país é obrigatório!',
                    },
                  ]}
                >
                  <Input size="large" placeholder="País" />
                </Form.Item>
              </Col>
            </Row>

            <Divider style={{ marginBottom: 10, marginTop: 10 }} />

            <Col xl={24}>
              <Form.Item
                name="restaurant_terms_use"
                valuePropName="checked"
                rules={[
                  {
                    validator: (_, value) => {
                      const error = 'É obrigatório ler e aceitar os Termos de Uso';
                      return value ? Promise.resolve() : Promise.reject(new Error(error));
                    },
                  },
                ]}
              >
                <Checkbox style={{ cursor: 'default' }}>
                  Eu li e concordo com o&nbsp;
                  <a target="__blank" href="https://help.lechefstella.com.br/restaurant-terms">
                    Termo de Adesão
                  </a>
                </Checkbox>
              </Form.Item>
            </Col>
          </Form>

          <Divider style={{ marginTop: 5, marginBottom: 15 }} />

          <Row justify="center">
            <Col span={14}>
              <LoginButton
                block
                type="primary"
                loading={loading}
                onClick={() => form.submit()}
                style={{ height: 50, fontWeight: 'bold' }}
              >
                Confirmar Registro
              </LoginButton>
            </Col>

            <Col span={16} style={{ textAlign: 'center' }}>
              <Divider style={{ marginBottom: 5, marginTop: 10 }} />
              <Link to="/login">Clique aqui para retornar ao login.</Link>
            </Col>
          </Row>
        </LoginBox>
      </Row>
    </Wrapper>
  );
};

export default Register;
