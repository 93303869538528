import styled from 'styled-components';
import { Layout, Col, Button } from 'antd';
import media from 'styled-media-query';
import { Link } from 'react-router-dom';

export const Wrapper = styled(Layout)`
  position: absolute;
  width: 100%;
  background-color: #f1f1f1 !important;
  padding: 20px;
  font-family: 'Jost', sans-serif;
  justify-content: center;
  align-items: center;

  #form::-webkit-scrollbar {
    width: 10px;
  }

  #form::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  #form::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }

  color: #D50032;

  a {
    color: #a39665;

    :hover {
      color: #a39665;
    }
  }
`;

export const LoginBox = styled(Col)`
  background-color: white;
  border-radius: 0.286rem;
  font-weight: 400;
  line-height: 1.8;
  color: #212120;
  min-width: 500px;
  padding: 30px;

  ${media.lessThan('small')`
    overflow: auto;
  `}

  ${media.lessThan('medium')`
    min-width: auto;
  `}
`;

export const RowLink = styled(Link)`
  color: #a39665;

  :hover {
    color: #a39665;
  }
`;

export const LoginButton = styled(Button)`
  background-color: #D50032;
  border-color: #D50032;

  :hover {
    background-color: #8c0525;
    border-color: #8c0525;
  }
`;
