import React from 'react';
import PropTypes from 'prop-types';

const Icon = ({
  fallbackSrc,
  src,
  icon,
  width,
}) => (
  <img
    style={{
      width,
    }}
    onError={(e) => {
      e.target.src = fallbackSrc;
    }}
    alt=""
    src={`${src}${icon}`}
  />
);

Icon.propTypes = {
  fallbackSrc: PropTypes.string,
  src: PropTypes.string,
  icon: PropTypes.string.isRequired,
  width: PropTypes.string,
};

Icon.defaultProps = {
  fallbackSrc: '/assets/images/empty.png',
  src: '/assets/icons/',
  width: 25,
};

export default Icon;
