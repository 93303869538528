import styled from 'styled-components';
import { Col } from 'antd';
import media from 'styled-media-query';

export const Wrapper = styled(Col)`
  .ant-card-body {
    padding: 3px;
  }
`;

export const Content = styled.div`
  overflow: auto;
  max-height: 52rem;

  ${media.between('large', 'huge')`
    max-height: 36rem;
  `}

  ${media.greaterThan('1800px')`
    max-height: 47rem;
  `}


  ${media.lessThan('small')`
    max-height: 30rem;
  `}
`;
