import styled from 'styled-components';
import media from 'styled-media-query';

const grid = 1;

export const Container = styled.div`
  user-select: none;
  display: flex;
  padding: ${grid * 2}px;
  margin: 0 0 ${grid}px 0;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border-bottom: 1px dashed #ccc;
  background: white;

  .visible-tag {
    ${media.lessThan('small')`
      display: none;
    `}
  }
  .avatar-uploader {
    margin-top: 15px;
    padding: 10px;
  }
`;

export const CouponInformation = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const Image = styled.img`
  display: flex;
  align-items: center;
  width: 110px;
  background-color: #cccccc;
`;

export const Title = styled.b`
  color: black;
`;

export const Informations = styled.div`
  margin-left: 30px;
  max-width: 500px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  ${media.lessThan('small')`
    margin-left: 20px;
  `}
`;

export const Code = styled.span`
  font-size: 12px;
  margin-left: 10px;
`;

export const styles = {
  tag: {
    borderRadius: 50,
    cursor: 'pointer',
  },
};
