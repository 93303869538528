import Immutable from 'seamless-immutable';

export const Types = {
  GET_PRODUCT_TYPES: 'productTypes/GET_PRODUCT_TYPES',
  SET_PRODUCT_TYPES: 'productTypes/SET_PRODUCT_TYPES',
};

const INITIAL_STATE = Immutable({
  types: [],
});

export default function productTypes(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SET_PRODUCT_TYPES: {
      return {
        types: action.payload,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getProductTypes: () => ({
    type: Types.GET_PRODUCT_TYPES,
  }),

  setProductTypes: (payload) => ({
    type: Types.SET_PRODUCT_TYPES,
    payload,
  }),
};
