/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-bind */
import React, { useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckOutlined,
  CloseOutlined,
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { Switch, Tag, Dropdown, Menu, Modal } from 'antd';
import { Draggable } from 'react-beautiful-dnd';
import { Container, Title, Informations, styles } from './styles';
import { Creators as ProductActions } from '../../store/ducks/products';
import { Creators as CategoryActions } from '../../store/ducks/categories';

const { confirm } = Modal;

const Category = ({ item, index, setEditableItem }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(item.active);
  const { category } = useSelector((state) => state.products);

  function handleCategory(newCategory) {
    if (category && category.id !== newCategory.id) {
      dispatch(ProductActions.setCategorySuccess(newCategory));
      dispatch(
        ProductActions.getProducts({
          category_id: newCategory.id,
        }),
      );
    }
  }

  function toggleVisibility() {
    dispatch(
      CategoryActions.toggleCategoryVisibility({
        id: item.id,
        ...item,
        active: visible ? 0 : 1,
      }),
    );
    setVisible(!visible);
  }

  function deleteCategory() {
    confirm({
      content: `Você deseja realmente deletar a categoria "${item.name}"?`,
      icon: <ExclamationCircleOutlined />,
      title: 'Solicitação para deletar categoria',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        dispatch(
          CategoryActions.deleteCategory({
            id: item.id,
            name: item.name,
          }),
        );
      },
    });
  }

  function handleEditCategory() {
    dispatch(CategoryActions.handleModalVisibility(true));

    setEditableItem(item);
  }

  return (
    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
      {(draggagleProvided, draggableSnapshot) => (
        <Container
          ref={draggagleProvided.innerRef}
          {...draggagleProvided.draggableProps}
          {...draggagleProvided.dragHandleProps}
          isdragging={draggableSnapshot.isDragging}
          active={category && category.id === item.id}
          style={{ ...draggagleProvided.draggableProps.style }}
        >
          <Informations onClick={() => handleCategory(item)}>
            <Title>{item.name}</Title>
            <div>
              <Tag color="#cccccc" style={styles.tag}>
                {item.available_items_quantity}
                &nbsp;Itens
              </Tag>
              <Tag
                color={visible ? '#87d068' : '#f50'}
                style={styles.tag}
                onClick={() => toggleVisibility()}
              >
                {visible ? 'Visível' : 'Invisível'}
              </Tag>
            </div>
          </Informations>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <Dropdown
              trigger={['click']}
              overlay={() => (
                <Menu style={{ borderRadius: 10 }}>
                  <Menu.Item
                    style={{
                      padding: 15,
                    }}
                    onClick={handleEditCategory}
                  >
                    <EditOutlined />
                    Editar
                  </Menu.Item>
                  <Menu.Item
                    style={{
                      color: 'red',
                      padding: 15,
                    }}
                    onClick={deleteCategory}
                  >
                    <DeleteOutlined />
                    Excluir
                  </Menu.Item>
                </Menu>
              )}
              placement="topCenter"
            >
              <EllipsisOutlined
                size={90}
                style={{ fontSize: 40, paddingBottom: 5, cursor: 'pointer' }}
              />
            </Dropdown>
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              defaultChecked={visible}
              checked={visible}
              onChange={() => toggleVisibility()}
            />
          </div>
        </Container>
      )}
    </Draggable>
  );
};

Category.propTypes = {
  index: PropTypes.number.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    items: PropTypes.array,
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  }).isRequired,
  setEditableItem: PropTypes.func.isRequired,
};

export default memo(Category);
