/* eslint-disable react/jsx-indent */
/* eslint-disable dot-notation */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';

import { Alert, Typography, Switch, Row, Col, Form, Divider, Button } from 'antd';

import {
  CloseOutlined,
  CheckOutlined,
  PlusCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';

import { Content } from './styles';
import { AlertContainer } from '../../styles';

import { Creators as RestaurantCreators } from '../../../../store/ducks/restaurants';
import DayTimer from '../../components/dayTimer';
import InputGroup from '../../../../components/InputGroup';

const { Title } = Typography;

function Specifications({ current, setCurrent }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [arrDayTimers, setArrayDayTimers] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [values, setValues] = useState(null);

  const { specifications, pendencies, saving } = useSelector((state) => state.restaurants);

  useEffect(() => {
    dispatch(RestaurantCreators.getRestaurantSpecifications());
    dispatch(RestaurantCreators.getRestaurantPendencies());
  }, [dispatch]);

  useEffect(() => {
    if (specifications) {
      const restaurantValues = {
        ...specifications,
      };

      form.setFieldsValue({ ...restaurantValues, opening_hours: specifications.opening_hours });
      setValues(restaurantValues);
      setArrayDayTimers(specifications.opening_hours ? specifications.opening_hours : []);
    }
  }, [specifications, form]);

  const getFieldBoolean = (field) => (values && values[field] ? 'success' : 'error');

  const handleOnChange = (field) => {
    setValues({
      ...values,
      [field]: !values[field],
    });

    setDisabled(false);
  };

  const handleVisibleAppChange = () => {
    setValues({
      ...values,
      visible_app: !values['visible_app'],
    });

    dispatch(RestaurantCreators.changeVisibleApp());

    setDisabled(false);
  };

  const onFinish = (formValues) => {
    dispatch(
      RestaurantCreators.updateRestaurantSpecifications({
        id: specifications.id,
        ...formValues,
        opening_hours: arrDayTimers,
      }),
    );
  };

  const incrementDayTimer = () => {
    setDisabled(false);
    if (arrDayTimers.length < 7) {
      setArrayDayTimers((oldArray) => [
        ...oldArray,
        {
          weekday: 0,
          opening_time: '00:00',
          closing_time: '00:00',
        },
      ]);
    }
  };

  const decrementDayTimer = () => {
    setDisabled(false);

    if (arrDayTimers.length > 1) {
      const temp = [...arrDayTimers];
      temp.pop();
      setArrayDayTimers(temp);
    }
  };

  const setValuesInDelivery = (field, value, index) => {
    setDisabled(false);
    setArrayDayTimers(
      arrDayTimers.map((day, indexDay) => {
        if (indexDay === index) {
          return {
            ...day,
            [field]: value,
          };
        }

        return day;
      }),
    );
  };

  const renderDaytimer = (item, index) => (
    <div key={index} index={index} style={{ display: 'flex', flex: 1, alignItems: 'center' }}>
      <DayTimer key={index} data={item} index={index} setValuesInDelivery={setValuesInDelivery} />
      <Button
        type="primary"
        shape="circle"
        style={{ marginLeft: 10 }}
        danger
        icon={<MinusCircleOutlined />}
        size={30}
        onClick={() => {
          setDisabled(false);
          const filtered = arrDayTimers.filter((_, position) => index !== position);
          setArrayDayTimers(filtered);
        }}
      />
    </div>
  );

  const renderArrDayTimers = () => arrDayTimers.map(renderDaytimer);

  return (
    <>
      <Content>
        <Form form={form} onFinish={onFinish}>
          <Row justify="space-between">
            <Col span={24}>
              <Alert
                message={<Title level={2}>Habilitar o restaurante</Title>}
                description={
                  <AlertContainer>
                    <p>
                      Informa se o restaurante irá aparecer na lista de restaurantes do App.
                      {' '}
                      <br />
                      <br />
                      {pendencies && pendencies.pendencies && pendencies.pendencies.length > 0 && (
                        <>
                          <strong style={{ color: 'red' }}>
                            As seguintes pendências precisam estar resolvidas para poder ativar seu
                            restaurante:
                          </strong>
                          <br />
                          <br />

                          {pendencies.pendencies.map((pendency) => <p style={{ color: 'red' }}>
                            -
                            {pendency.message}
                                                                   </p>)}
                        </>
                      )}
                    </p>

                    <Form.Item name="visible_app" valuePropName="checked">
                      <Switch
                        style={{ marginTop: -35 }}
                        disabled={
                          pendencies
                          && pendencies.pendencies
                          && !pendencies.is_enabled
                          && pendencies.pendencies.length > 0
                        }
                        onChange={() => handleVisibleAppChange()}
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                      />
                    </Form.Item>
                  </AlertContainer>
                }
                type={getFieldBoolean('visible_app')}
                showIcon
              />
            </Col>

            <Col span={24}>
              <Alert
                message={<Title level={2}>Exigir pedido mínimo no Delivery</Title>}
                description={
                  <AlertContainer>
                    <div>
                      <p>
                        O delivery será disponibilizado apenas se atingir o valor mínimo a seguir.
                      </p>
                      <Form.Item name="min_delivery_price">
                        <InputGroup onChange={() => setDisabled(false)} />
                      </Form.Item>
                    </div>
                    <div>
                      <Form.Item name="min_price_is_required_to_delivery" valuePropName="checked">
                        <Switch
                          style={{ marginTop: -35 }}
                          disabled
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </Form.Item>
                    </div>
                  </AlertContainer>
                }
                type="info"
                showIcon
              />
            </Col>

            <Col span={24}>
              <Alert
                message={<Title level={2}>Horário de funcionamento</Title>}
                description={
                  <AlertContainer>
                    <div>
                      <p>Horário de funcionamento do restaurante</p>
                      {renderArrDayTimers()}
                    </div>
                    <div>
                      <Button
                        type="primary"
                        shape="circle"
                        icon={<PlusCircleOutlined />}
                        size={30}
                        onClick={() => incrementDayTimer()}
                        style={{ marginRight: 10 }}
                      />
                      <Button
                        type="primary"
                        shape="circle"
                        danger
                        icon={<MinusCircleOutlined />}
                        size={30}
                        onClick={() => decrementDayTimer()}
                      />
                    </div>
                  </AlertContainer>
                }
                type="info"
                showIcon
              />
              <Divider />
            </Col>
          </Row>
        </Form>
      </Content>

      <div
        style={{
          display: 'flex',
          flex: 1,
          paddingTop: 15,
          paddingBottom: 15,
          paddingRight: 20,
          justifyContent: 'flex-end',
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          disabled={disabled}
          loading={saving}
          onClick={() => form.submit()}
        >
          Salvar Alterações
        </Button>
      </div>
    </>
  );
}

Specifications.propTypes = {
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
};

export default Specifications;
