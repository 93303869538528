/* eslint-disable comma-dangle */
/* eslint-disable react/jsx-indent */
/* eslint-disable arrow-body-style */
/* eslint-disable consistent-return */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import { message } from 'antd';
import PropTypes from 'prop-types';

import {
  Row,
  Col,
  Upload,
  Modal,
  Button,
  Divider,
} from 'antd';

import {
  UploadOutlined,
} from '@ant-design/icons';

import { validateImageSize } from '../../../../../../../../configs/utils';
import ImageCrop from '../../../../../../../../components/ImageCrop';

const ModalImage = ({
  visible,
  onClose,
  onSave,
  onBeforeUpload,
  validateDimensions,
  dimensions,
}) => {
  const [imageCropped, setImageCropped] = useState(null);
  const [src, setSrc] = useState(null);

  const handleSelect = (file) => {
    if (file) {
      if (file.file.originFileObj) {
        const reader = new FileReader();

        reader.addEventListener('load', () => {
          if (validateDimensions) {
            const { w, h } = dimensions;

            validateImageSize(reader.result, w, h).then(() => {
              return setSrc(reader.result);
            }).catch(() => {
              message.error(`A imagem precisa ter no mínimo ${w} x ${h} de tamanho.`);
            });
          } else {
            return setSrc(reader.result);
          }
        });

        reader.readAsDataURL(file.file.originFileObj);
      }
    }
  };

  useEffect(() => {
    if (!visible) {
      setSrc(null);
      setImageCropped(null);
    }
  }, [visible]);

  return (
    <Modal
      title="Alterar imagem de capa"
      maskClosable={false}
      centered
      width={1200}
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="back" onClick={onClose}>
          Fechar
        </Button>,
        <Button
          key="submit"
          type="primary"
          disabled={!imageCropped}
          onClick={() => onSave(imageCropped)}
        >
          Salvar
        </Button>,
      ]}
    >
      <Row type="flex" align="center">
        <Col span={32}>
          <Upload
            name="cover"
            onChange={handleSelect}
            customRequest={() => null}
            beforeUpload={onBeforeUpload}
            showUploadList={false}
            accept="image/*"
          >
            <Button
              icon={<UploadOutlined />}
            >
              Clique aqui selecionar a imagem
            </Button>
          </Upload>
        </Col>
      </Row>

      <ImageCrop
        initialAspectRatio={9 / 5}
        aspectRatio={9 / 5}
        onCropped={setImageCropped}
        src={src}
      >
        <Divider />
      </ImageCrop>
    </Modal>
  );
};

ModalImage.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onBeforeUpload: PropTypes.func,
  validateDimensions: PropTypes.bool,
  dimensions: PropTypes.shape({
    w: PropTypes.number,
    h: PropTypes.number,
  }),
};

ModalImage.defaultProps = {
  onBeforeUpload: () => true,
  validateDimensions: false,
  dimensions: {
    w: 1920,
    h: 1080,
  }
};

export default ModalImage;
