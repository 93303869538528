import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card, Result, Skeleton } from 'antd';

import CouponExtra from './components/CouponExtra';
import CouponForm from './components/CouponForm';
import { Wrapper, Content } from './styles';
import Coupon from '../../../../components/Coupon';
import { Container } from '../../../../components/Coupon/styles';

import { Creators as CouponCreators } from '../../../../store/ducks/coupons';

const CouponList = () => {
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);

  const {
    loading,
    data,
    visible,
    coupon,
    saving,
  } = useSelector((state) => state.coupons);

  const { account } = useSelector((state) => state.accounts);

  useEffect(() => {
    dispatch(CouponCreators.getCoupons());
  }, [dispatch]);

  useEffect(() => {
    if (data.length > 0) {
      setItems(data);
    }
  }, [data]);

  const handleEdit = (id) => {
    dispatch(CouponCreators.getCoupon({
      id,
    }));
  };

  const getListStyle = (isDraggingOver) => ({
    width: '100%',
    background: isDraggingOver ? '#f2f2f2' : '#f2f2f2',
    padding: 8,
    margin: 0,
  });

  function renderCouponList() {
    if (data.length === 0) {
      return (
        <Result
          status="404"
          title="Nenhum cupom cadastrado!"
          subTitle="Desculpe! A lista está vazia, tente cadastrar um cupom."
        />
      );
    }

    return (
      <div>
        {items.map((item, index) => (
          <Coupon
            key={item.id}
            item={item}
            index={index}
            setEditableCoupon={handleEdit}
          />
        ))}
      </div>
    );
  }

  return (
    <Wrapper xs={24} sm={4} md={14} lg={12} xl={24}>
      <Card
        style={{ borderRadius: 5 }}
        title="Cupons"
        extra={<CouponExtra />}
      >
        <CouponForm
          visible={visible}
          coupon={coupon}
          saving={saving}
          account={account}
        />
        <Content>
          {loading ? (
            <div style={getListStyle(false)}>
              {Array.from([0, 1, 2, 3, 4, 5]).map((index) => (
                <Container key={index}>
                  <Skeleton avatar active />
                </Container>
              ))}
            </div>
          ) : (
            renderCouponList()
          )}
        </Content>
      </Card>
    </Wrapper>
  );
};

export default CouponList;
