import styled from 'styled-components';
import { Col } from 'antd';
import media from 'styled-media-query';

export const Wrapper = styled(Col)`
  margin-bottom: 30px;
  .ant-card-body {
    padding: 20px;
  }
`;

export const Title = styled.b`
  color: black;
  font-size: 15px;
  margin-bottom: 20px;
`;

export const Content = styled.div`
  overflow: auto;
  max-height: 52rem;
  display: ${({ activeLoading }) => (activeLoading ? 'flex' : 'block')};
  justify-content: center;
  align-items: center;


  ${media.between('large', 'huge')`
    max-height: 34rem;
  `}

  ${media.greaterThan('1800px')`
    max-height: 47rem;
  `}


  ${media.lessThan('small')`
    max-height: 30rem;
  `}
`;
