export const ALL_CATEGORIES = [
  { value: '1', text: 'Atacado' },
  { value: '2', text: 'Casas de Carne / Peixaria' },
  { value: '3', text: 'Docerias / Confeitarias / Rotisserie' },
  { value: '4', text: 'Feira livre' },
  { value: '5', text: 'Hortifruit / Granjeiros' },
  { value: '6', text: 'Lojas de Conveniência' },
  { value: '7', text: 'Mercearia e Bebidas' },
  { value: '8', text: 'Alimentação em geral' },
  { value: '22', text: 'Supermercado' },
  { value: '23', text: 'Padaria' },
  { value: '27', text: 'Restaurante' },
  { value: '28', text: 'Bar e Casa Noturna' },
  { value: '29', text: 'Restaurante Fast Food' },
];
