import Immutable from 'seamless-immutable';

export const Types = {
  GET_AUTH_REQUEST: 'auth/GET_AUTH_REQUEST',
  GET_AUTH_SUCCESS: 'auth/GET_AUTH_SUCCESS',
  GET_AUTH_FAILED: 'auth/GET_AUTH_FAILED',

  REGISTER_USER_AND_AUTH_REQUEST: 'auth/REGISTER_USER_AND_AUTH_REQUEST',
  REGISTER_USER_AND_AUTH_SUCCESS: 'auth/REGISTER_USER_AND_AUTH_SUCCESS',
  REGISTER_USER_AND_AUTH_FAILED: 'auth/REGISTER_USER_AND_AUTH_FAILED',

  RESET_PASSWORD_REQUEST: 'auth/RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'auth/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILED: 'auth/RESET_PASSWORD_FAILED',

  CHANGE_PASSWORD_REQUEST: 'auth/CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'auth/CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILED: 'auth/CHANGE_PASSWORD_FAILED',

  GET_AUTH_RESTAURANTS: 'auth/GET_AUTH_RESTAURANTS',
  GET_AUTH_RESTAURANTS_SUCCESS: 'auth/GET_AUTH_RESTAURANTS_SUCCESS',
  GET_AUTH_RESTAURANTS_FAILED: 'auth/GET_AUTH_RESTAURANTS_FAILED',

  NEED_SESSION_UPDATE: 'auth/NEED_SESSION_UPDATE',

  HANDLE_AUTH: 'auth/HANDLE_AUTH',
  HANDLE_AUTH_FINISH: 'auth/HANDLE_AUTH_FINISH',
};

const INITIAL_STATE = Immutable({
  loading: false,
  error: null,
  restaurants: [],
  needSessionUpdate: false,
});

export default function auth(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_AUTH_REQUEST: {
      return {
        ...state,
        restaurants: [],
        loading: true,
      };
    }

    case Types.GET_AUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.GET_AUTH_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case Types.REGISTER_USER_AND_AUTH_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.REGISTER_USER_AND_AUTH_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.REGISTER_USER_AND_AUTH_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case Types.RESET_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case Types.CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.CHANGE_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    }

    case Types.GET_AUTH_RESTAURANTS: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_AUTH_RESTAURANTS_SUCCESS: {
      return {
        ...state,
        restaurants: action.payload,
        loading: false,
      };
    }

    case Types.GET_AUTH_RESTAURANTS_FAILED: {
      return {
        ...state,
        restaurants: [],
        loading: false,
      };
    }

    case Types.HANDLE_AUTH: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.HANDLE_AUTH_FINISH: {
      return {
        ...state,
        loading: false,
        restaurants: [],
      };
    }

    case Types.NEED_SESSION_UPDATE: {
      return {
        ...state,
        needSessionUpdate: action.payload,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getAuth: (payload) => ({
    type: Types.GET_AUTH_REQUEST,
    payload,
  }),

  getAuthSuccess: (payload) => ({
    type: Types.GET_AUTH_SUCCESS,
    payload,
  }),

  getAuthFailed: (payload) => ({
    type: Types.GET_AUTH_FAILED,
    payload,
  }),

  registerUser: (payload) => ({
    type: Types.REGISTER_USER_AND_AUTH_REQUEST,
    payload,
  }),

  registerUserSuccess: (payload) => ({
    type: Types.REGISTER_USER_AND_AUTH_SUCCESS,
    payload,
  }),

  registerUserFailed: (payload) => ({
    type: Types.REGISTER_USER_AND_AUTH_FAILED,
    payload,
  }),

  resetPassword: (payload) => ({
    type: Types.RESET_PASSWORD_REQUEST,
    payload,
  }),

  resetPasswordSuccess: (payload) => ({
    type: Types.RESET_PASSWORD_SUCCESS,
    payload,
  }),

  resetPasswordFailed: (payload) => ({
    type: Types.RESET_PASSWORD_FAILED,
    payload,
  }),

  changePassword: (payload) => ({
    type: Types.CHANGE_PASSWORD_REQUEST,
    payload,
  }),

  changePasswordSuccess: (payload) => ({
    type: Types.CHANGE_PASSWORD_SUCCESS,
    payload,
  }),

  changePasswordFailed: (payload) => ({
    type: Types.CHANGE_PASSWORD_FAILED,
    payload,
  }),

  getAuthRestaurants: () => ({
    type: Types.GET_AUTH_RESTAURANTS,
  }),

  getAuthRestaurantsSuccess: (payload) => ({
    type: Types.GET_AUTH_RESTAURANTS_SUCCESS,
    payload,
  }),

  getAuthRestaurantsFailed: () => ({
    type: Types.GET_AUTH_RESTAURANTS_FAILED,
  }),

  handleAuth: () => ({
    type: Types.HANDLE_AUTH,
  }),

  handleAuthFinish: () => ({
    type: Types.HANDLE_AUTH_FINISH,
  }),

  needSessionUpdate: (payload) => ({
    type: Types.NEED_SESSION_UPDATE,
    payload,
  }),
};
