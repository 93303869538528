import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import { push } from 'connected-react-router';

import api from '../../services/api';
import { Creators as AuthActions } from '../ducks/auth';
import { Creators as UserActions } from '../ducks/me';

import { ROLE_MASTER, ROLE_SUPPORT } from '../../constants/roles';

import validation from '../../services/validation';

export function* login({ payload }) {
  try {
    const response = yield call(api.post, '/login', payload);
    yield put(AuthActions.getAuthSuccess());

    if (response.status === 200) {
      const { accessToken, roleId } = response.data;
      yield localStorage.setItem('access_token', accessToken);

      if (roleId === ROLE_MASTER || roleId === ROLE_SUPPORT) {
        yield put(AuthActions.getAuthRestaurants());
      } else {
        yield put(AuthActions.handleAuth());
      }
    }
  } catch (error) {
    if (error.response) {
      message.error(
        error.response.status === 404 ? 'Usuário ou senha inválidos!' : 'Erro ao realizar login!',
      );
    } else {
      message.error('Erro ao realizar login!');
    }

    yield put(AuthActions.getAuthFailed('O usuário informado não existe'));
  }
}

export function* handleAuth() {
  const { data, status } = yield call(api.get, '/auth/me');

  if (status === 200) {
    yield put(UserActions.getAuthUserSuccess(data));
    yield put(push('/settings'));
  }

  yield put(AuthActions.handleAuthFinish());
}

export function* getAuthRestaurants() {
  try {
    const { data, status } = yield call(api.get, '/restaurants');

    if (status === 200) {
      yield put(AuthActions.getAuthRestaurantsSuccess(data.data));
    }
  } catch (error) {
    message.error('Houve um erro ao buscar os restaurantes para efetuar o login. Tente novamente!');
    yield put(AuthActions.getAuthRestaurantsFailed());
  }
}

export function* register({ payload }) {
  try {
    const { status } = yield call(api.post, '/restaurants', payload);
    if (status === 204) {
      window.location.href = '/register-success';
    }
  } catch (error) {
    let content = 'Erro ao registrar o restaurante, tente novamente mais tarde!';

    if (error.response) {
      const { response } = error;

      if (response?.data?.message) {
        content = response.data.message;
      }

      if (response?.status === 422) {
        content = validation(response.data.errors);
      }

      message.warn(content);
    } else {
      message.error(content);
    }
    yield put(AuthActions.registerUserFailed());
  }
}

export function* changePassword({ payload }) {
  try {
    const { status } = yield call(api.post, 'users/change-password', payload);

    if (status === 204) {
      message.success('Alteraçao de senha realizado com sucesso!');
      yield put(AuthActions.changePasswordSuccess());
      yield put(UserActions.showChangePasswordModal(false));
    }
  } catch (error) {
    message.error('Erro ao alterar senha, tente novamente!');
    yield put(AuthActions.changePasswordFailed());
  }
}

export function* resetPassword({ payload }) {
  try {
    const { status } = yield call(api.post, '/reset-password', payload);
    if (status === 204) {
      message.success('Por favor, verifique sua caixa de e-mail para conferir sua nova senha!');
      yield put(AuthActions.resetPasswordSuccess());
    }
  } catch (error) {
    message.error('Erro ao enviar o e-mail, tente novamente mais tarde!');
    yield put(AuthActions.resetPasswordFailed());
  }
}

export function* logout() {
  try {
    const { status } = yield call(api.post, '/logout');

    if (status === 204) {
      localStorage.clear();
      yield put(UserActions.removeAuthUserSuccess());
      yield localStorage.setItem('access_token', null);
      yield localStorage.setItem('restaurant_id', null);
    }
  } catch (error) {
    message.error('Erro ao efetuar logout!');
  }
}
