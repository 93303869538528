import axios from 'axios';

import { store } from '../store';
import { Creators as AuthCreators } from '../store/ducks/auth';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL_BASE,
});

api.defaults.headers = {
  'content-type': 'application/json',
  Accept: 'application/json',
};

api.interceptors.response.use(
  response => {
    if (response.config.url.indexOf('/login') === -1
      && response.config.url.indexOf('/logout') === -1) {
      store.dispatch(AuthCreators.needSessionUpdate(true));
    }

    let resfreshToken = response.headers['x-refresh-token'];

    if (resfreshToken) {
      localStorage.setItem('access_token', resfreshToken);
    }

    return response;
  },
  (error) => {
    if (error.response.status !== 401) {
      return new Promise((_, reject) => {
        reject(error);
      });
    }

    if (error.response.status === 401) {
      localStorage.clear();
      return (window.location.href = '/login');
    }
  },
);

api.interceptors.request.use(async (config) => {
  const token = await localStorage.getItem('access_token');
  const restaurant = await localStorage.getItem('restaurant_id');

  config.headers.Authorization = `Bearer ${token || ''}`;

  if (restaurant && restaurant.length > 0) {
    config.headers['restaurant-id'] = restaurant;
  }

  return config;
});

export default api;
