import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { isMobile } from 'react-device-detect';
import { saveAs } from 'file-saver';

import { Layout, Menu, Dropdown, Button } from 'antd';
import { Creators as UserActions } from '../../store/ducks/me';

import { Wrapper, Navbar, MenuIconOpen, MenuMobile, MenuIconClose, Container } from './styles';
import ChangePassword from '../../pages/ChangePassword';

import { ROLE_ADMIN, ROLE_EXPERT, ROLE_SUPPORT, ROLE_MASTER } from '../../constants/roles';

const navs = [
  {
    id: 2,
    name: 'Cardápios',
    path: '/menu',
    role: [ROLE_ADMIN, ROLE_EXPERT, ROLE_MASTER, ROLE_SUPPORT],
  },
  {
    id: 2,
    name: 'Cupons',
    path: '/coupons',
    role: [ROLE_ADMIN, ROLE_MASTER, ROLE_SUPPORT],
  },
  {
    id: 3,
    name: 'Configurações',
    path: '/settings',
    role: [ROLE_ADMIN, ROLE_MASTER, ROLE_SUPPORT],
  },
  {
    id: 4,
    name: 'Usuários',
    path: '/users',
    role: [ROLE_ADMIN, ROLE_MASTER, ROLE_SUPPORT],
  },
];

const Header = ({ children }) => {
  const [isOpen, setMenuIsOpen] = useState(false);
  const { pathname } = useLocation();
  const { user, showChangePasswordModal } = useSelector((state) => state.me);
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(UserActions.removeAuthUser());
  };

  const changePassword = () => {
    dispatch(UserActions.showChangePasswordModal(true));
  };

  const downloadApp = () => {
    let downloadExtension = 'exe';

    if (navigator.appVersion.indexOf('Mac') !== -1) downloadExtension = 'dmg';
    if (navigator.appVersion.indexOf('X11') !== -1) downloadExtension = 'deb';
    if (navigator.appVersion.indexOf('Linux') !== -1) downloadExtension = 'deb';

    saveAs(`/download/GetInDelivery-latest.${downloadExtension}`, 'get-in-delivery.exe');
  };

  useEffect(() => {
    setMenuIsOpen(false);
  }, [pathname]);

  return user ? (
    <Layout>
      <Wrapper className="header" justify="space-between" breakpoint="sm">
        <Navbar>
          <Menu
            theme="dark"
            breakpoint="lg"
            mode="horizontal"
            defaultSelectedKeys={[
              pathname.substring(1) === '' ? 'settings' : pathname.substring(1),
            ]}
          >
            {navs.map((nav) => {
              if (nav.role.find((role) => user.role_id === role)) {
                return (
                  <Menu.Item key={nav.path.substring(1)}>
                    <Link to={nav.path}>{nav.name}</Link>
                  </Menu.Item>
                );
              }

              return null;
            })}
            <Button
              theme="dark"
              style={{
                height: 59,
                border: 0,
                marginBottom: 3,
                marginLeft: 70,
              }}
              onClick={() => downloadApp()}
            >
              <img
                src="assets/icons/icon-500px.png"
                alt="Baixar Gestor de Pedidos do Get In"
                height="34"
                style={{ marginRight: 12 }}
              />
              Baixar Gestor de Pedidos
            </Button>
          </Menu>
        </Navbar>

        {isMobile && isOpen ? (
          <div onClick={() => setMenuIsOpen(false)}>
            <MenuIconClose />
          </div>
        ) : (
          <div onClick={() => setMenuIsOpen(true)}>
            <MenuIconOpen />
          </div>
        )}
        <Dropdown
          trigger={['click', 'hover']}
          overlay={() => (
            <Menu>
              <Menu.Item onClick={() => changePassword()}>Alterar Senha</Menu.Item>
              <Menu.Item style={{ color: 'red' }} onClick={() => logout()}>
                Sair
              </Menu.Item>
            </Menu>
          )}
          placement="bottomLeft"
        >
          <Button style={{ background: 'none', color: 'white', border: 'none' }}>
            {`Olá, ${user.name}`}
          </Button>
        </Dropdown>
      </Wrapper>
      {isMobile && isOpen && (
        <MenuMobile>
          {navs.map((nav) => {
            if (nav.role.find((role) => user.role_id === role)) {
              return (
                <div key={nav.id.toString()}>
                  <Link style={{ color: 'white' }} to={nav.path}>
                    {nav.name}
                  </Link>
                </div>
              );
            }

            return null;
          })}
        </MenuMobile>
      )}

      <Container className="site-layout" mobile={isOpen.toString()}>
        <ChangePassword user={user} visible={showChangePasswordModal} />
        {children}
      </Container>
    </Layout>
  ) : (
    children
  );
};

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
};

export default Header;
