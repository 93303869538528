/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line import/order
import { Creators as RestaurantCreators } from '../../../../store/ducks/restaurants';

import { Button, Input, Divider, Typography, Upload, Form, Select, Row, Col } from 'antd';

import {
  UploadOutlined,
  CreditCardFilled,
  CheckCircleOutlined,
  LoadingOutlined,
  DownloadOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { Content } from './styles';

import { getBase64, planMutator } from '../../../../configs/utils';
import * as DocumentConstants from '../../../../constants/documents';
import InputMask from '../../../../components/InputMask';
import PlanModal from '../../components/PlanModal';

const { Title } = Typography;
const { Option } = Select;

const uploaded = {};

const Documents = ({ current, setCurrent }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { plans, bankAccounts, saving, visibleModal, documents, loadingDocuments } = useSelector(
    (state) => state.restaurants,
  );

  useEffect(() => {
    dispatch(RestaurantCreators.getRestaurantPlans());
    dispatch(RestaurantCreators.getRestaurantBankAccounts());
    dispatch(RestaurantCreators.getRestaurantDocuments());
  }, [dispatch]);

  const [disabled, setDisabled] = useState(true);
  const [loadingPlan, setLoadingPlan] = useState([]);
  const [documentsStatus, setDocumentsStatus] = useState([]);

  const onFinish = (values) => {
    values.bank_code = values.bank_code.replace(/[\W_]+/g, '');
    values.routing_number = values.routing_number.replace(/[\W_]+/g, '');
    values.account_number = values.account_number.replace(/[\W_]+/g, '');

    if (bankAccounts.account_number && bankAccounts.bank_code) {
      dispatch(RestaurantCreators.updateRestaurantBankAccounts(values));
    } else {
      dispatch(RestaurantCreators.storeRestaurantBankAccounts(values));
    }
  };

  const openPlanModal = (id) => {
    const loading = [...loadingPlan];
    loading[id] = true;
    setLoadingPlan(loading);

    const payload = {
      id,
      sellerId: plans.payment_partner_shop_id,
    };

    dispatch(RestaurantCreators.getRestaurantPlan(payload));
  };

  const handleChange = (typeId, info) => {
    if (info.file.originFileObj) {
      getBase64(info.file.originFileObj).then((base64) => {
        uploaded[typeId] = true;
        dispatch(
          RestaurantCreators.uploadDocument({
            filename: info.file.name,
            content: base64,
            typeId,
          }),
        );
      });
    }
  };

  const handleDocumentApproved = (type) => {
    if (documentsStatus[type]) {
      if (documentsStatus[type].approved) {
        return (
          <CheckCircleOutlined
            style={{ fontSize: 16, color: 'green', marginRight: 5 }}
            title="Esse documento foi aprovado"
          />
        );
      }

      if (documentsStatus[type].status === DocumentConstants.STATUS_DENIED) {
        return (
          <CloseCircleOutlined
            style={{ fontSize: 16, color: 'red', marginRight: 5 }}
            title="Esse documento foi reprovado. Por favor, entrar em contato para mais informações!"
          />
        );
      }
    }

    return null;
  };

  const handleDocumentDownload = (typeId, label) => {
    if (documentsStatus[typeId] && documentsStatus[typeId].link) {
      return (
        <Button
          target="_blank"
          href={documentsStatus[typeId].link}
          type="primary"
          style={{ verticalAlign: 'top' }}
        >
          <DownloadOutlined style={{ marginRight: 5, fontSize: 15 }} />
          {label}
        </Button>
      );
    }

    return null;
  };

  const handlerPlan = (plan, selected = false) => (
    <Row gutter={10} key={plan.id}>
      <Col style={{ marginBottom: 10 }}>
        {selected && <CheckCircleOutlined style={{ color: 'green', marginRight: 14 }} />}

        <Button
          title="Clique para ver os detalhes e associar"
          type="primary"
          icon={<CreditCardFilled />}
          style={{
            marginRight: 4,
          }}
          size="small"
          onClick={() => openPlanModal(plan.id)}
          loading={loadingPlan[plan.id]}
        >
          {planMutator(plan, 'name')}
        </Button>
      </Col>
      <Col sm={24} md={12} style={{ lineHeight: 'normal' }}>
        <div
          style={{
            display: 'inline',
            verticalAlign: 'super',
            fontSize: 12,
          }}
        >
          {planMutator(plan, 'description')}
        </div>
      </Col>
    </Row>
  );

  const showPlanButtons = () => {
    let planSelected = null;

    if (plans && plans.selectedPlan && plans.selectedPlan.id) {
      planSelected = plans.selectedPlan;
      plans.plans = plans.plans.filter((plan) => plan.id !== planSelected.id);
    }

    const buttons = plans && plans.plans ? plans.plans.map((plan) => handlerPlan(plan)) : [];

    return (
      <>
        {planSelected ? (
          <>
            <Col sm={24} md={24} lg={24}>
              <h3>
                <strong>Seu plano</strong>
              </h3>
            </Col>
            <Col sm={24} md={24} lg={24}>
              {handlerPlan(planSelected, true)}
            </Col>
          </>
        ) : (
          <Col sm={24} md={24} lg={24}>
            <h4>Você ainda não possui nenhum plano contratado.</h4>
          </Col>
        )}

        <Divider
          style={{
            marginBottom: 10,
            marginTop: 10,
          }}
          dashed
        />

        <Col sm={24} md={24} lg={24}>
          <h3>
            <strong>Planos disponíveis</strong>
          </h3>
        </Col>
        <Col sm={24} md={24} lg={24}>
          {buttons}
        </Col>
      </>
    );
  };

  useEffect(() => {
    if (documents) {
      const data = [];

      documents.forEach((document) => {
        data[document.type_id] = {
          approved: document.status === DocumentConstants.STATUS_APPROVED,
          status: document.status_id,
          link: document.filename,
        };
      });

      setDocumentsStatus(data);
    }
  }, [documents]);

  useEffect(() => {
    if (bankAccounts && bankAccounts.bank_account) {
      bankAccounts.holder_name = bankAccounts.bank_account.holder_name;
      bankAccounts.bank_code = bankAccounts.bank_account.bank_code;
      bankAccounts.routing_number = bankAccounts.bank_account.routing_number;
      bankAccounts.account_number = bankAccounts.bank_account.account_number;
      bankAccounts.type = bankAccounts.bank_account.type;
    }

    form.setFieldsValue(bankAccounts);
  }, [bankAccounts, form]);

  useEffect(() => {
    setLoadingPlan([]);
  }, [visibleModal]);

  return (
    <>
      <Content>
        <Form
          name="documentStore"
          layout="vertical"
          form={form}
          onFinish={onFinish}
          onChange={() => setDisabled(false)}
        >
          <Row gutter={24}>
            <Col xs={24} sm={24} lg={12} xl={12}>
              <Row gutter={24}>
                <Col xs={24} sm={24} lg={24} xl={24}>
                  <Title level={3}>Conta Bancária</Title>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} lg={24} xl={24}>
                  <Form.Item
                    label="Nome do portador"
                    name="holder_name"
                    rules={[
                      {
                        required: true,
                        message: 'O Nome do portador é obrigatório!',
                      },
                    ]}
                  >
                    <Input placeholder="Nome do portador" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={8} xl={8}>
                  <Form.Item
                    label="Código do Banco"
                    name="bank_code"
                    rules={[
                      {
                        required: true,
                        message: 'O Código do Banco é obrigatório!',
                      },
                    ]}
                  >
                    <InputMask mask="999" placeholder="Código do Banco" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={8} xl={8}>
                  <Form.Item
                    label="Agência (Sem DV)"
                    name="routing_number"
                    rules={[
                      {
                        required: true,
                        message: 'É obrigatório informar uma agência!',
                      },
                    ]}
                  >
                    <InputMask mask="9999" placeholder="Agência (Sem DV)" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={8} xl={8}>
                  <Form.Item
                    label="Conta Bancária (com DV)"
                    name="account_number"
                    rules={[
                      {
                        required: true,
                        message: 'É obrigatório informar uma Conta Bancária!',
                      },
                      () => ({
                        validator(_, value) {
                          if (!value || !Number.isNaN(value.replace(/[\W_]+/g, ''))) {
                            return Promise.resolve();
                          }

                          return Promise.reject(new Error('O número da conta está inválido!'));
                        },
                      }),
                    ]}
                  >
                    <Input placeholder="Conta Bancária (com DV)" />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} lg={12} xl={12}>
                  <Form.Item
                    name="type"
                    label="Tipo de Conta"
                    hasFeedback
                    rules={[{ required: true, message: 'Selecione um tipo de conta' }]}
                  >
                    <Select
                      placeholder="Selecione um tipo de conta"
                      onChange={() => setDisabled(false)}
                    >
                      <Option key="Checking" value={2}>
                        Corrente
                      </Option>
                      <Option key="Savings" value={1}>
                        Poupança
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={24}>
                <Col xs={24} sm={24} lg={8} xl={8}>
                  <Title level={3}>Planos de venda</Title>
                </Col>
                {showPlanButtons()}
              </Row>
            </Col>

            <Col xs={24} sm={24} lg={12} xl={12}>
              <Row gutter={24}>
                <Col xs={24} sm={24} lg={24} xl={24}>
                  <Title level={3}>
                    Documentos
                    {loadingDocuments && <LoadingOutlined style={{ marginLeft: 10 }} spin />}
                  </Title>
                </Col>

                <Col xs={24} sm={24} lg={24} xl={24}>
                  <Form.Item
                    name="identificacao"
                    label={
                      <span>
                        {handleDocumentApproved(DocumentConstants.IDENTIFICATION)}
                        Documento de Identificação (RG, CPF, CNH do sócio ou Cartão CNPJ)
                      </span>
                    }
                  >
                    {(!documentsStatus[DocumentConstants.IDENTIFICATION] || (
                      documentsStatus[DocumentConstants.IDENTIFICATION]
                      && !documentsStatus[DocumentConstants.IDENTIFICATION].approved)) && (
                      <Upload
                        name="documento_identificacao"
                        onChange={(e) => handleChange(DocumentConstants.IDENTIFICATION, e)}
                        customRequest={() => null}
                        showUploadList={false}
                      >
                        <Button style={{ marginRight: 5 }} disabled={loadingDocuments}>
                          <UploadOutlined /> Upload {' '}
                        </Button>
                      </Upload>
                    )}

                    {handleDocumentDownload(DocumentConstants.IDENTIFICATION, 'Download')}
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={24} xl={24}>
                  <Form.Item
                    name="residencia"
                    label={
                      <span>
                        {handleDocumentApproved(DocumentConstants.RESIDENCE)}
                        Comprovante de residência (Conta de luz, telefone ou gás no nome do sócio ou
                        de familiares)
                      </span>
                    }
                  >
                    {(!documentsStatus.residencia || (
                      documentsStatus.residencia && !documentsStatus.residencia.approved)) && (
                      <Upload
                        name="documento_residencia"
                        onChange={(e) => handleChange(DocumentConstants.RESIDENCE, e)}
                        customRequest={() => null}
                        showUploadList={false}
                      >
                        <Button style={{ marginRight: 5 }} disabled={loadingDocuments}>
                          <UploadOutlined /> Upload
                        </Button>
                      </Upload>
                    )}

                    {handleDocumentDownload(DocumentConstants.RESIDENCE, 'Download')}
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={24} xl={24}>
                  <Form.Item
                    name="atividade"
                    label={
                      <span>
                        {handleDocumentApproved(DocumentConstants.ACTIVITY)}
                        Comprovante de atividade
                      </span>
                    }
                  >
                    <Row gutter={20}>
                      <Col>
                        {(!documentsStatus.atividade || (
                          documentsStatus.atividade && !documentsStatus.atividade.approved)) && (
                          <Upload
                            name="documento_atividade"
                            onChange={(e) => handleChange(DocumentConstants.ACTIVITY, e)}
                            customRequest={() => null}
                            showUploadList={false}
                          >
                            <Button style={{ marginRight: 5 }} disabled={loadingDocuments}>
                              <UploadOutlined /> Upload
                            </Button>
                          </Upload>
                        )}

                        {handleDocumentDownload(DocumentConstants.ACTIVITY, 'Download')}
                      </Col>
                    </Row>
                  </Form.Item>
                </Col>

                <Col xs={24} sm={24} lg={24} xl={24}>
                  <Form.Item
                    name="cnpj"
                    label={
                      <span>
                        {handleDocumentApproved(DocumentConstants.CNPJ)}
                        Identificação CNPJ (Cartão CNPJ)
                      </span>
                    }
                  >
                    {(!documentsStatus.cnpj
                    || (documentsStatus.cnpj && !documentsStatus.cnpj.approved)) && (
                      <Upload
                        name="documento_cnpj"
                        onChange={(e) => handleChange(DocumentConstants.CNPJ, e)}
                        customRequest={() => null}
                        showUploadList={false}
                      >
                        {(!documentsStatus.cnpj
                        || (documentsStatus.cnpj && !documentsStatus.cnpj.approved)) && (
                          <Button style={{ marginRight: 5 }} disabled={loadingDocuments}>
                            <UploadOutlined /> Upload
                          </Button>
                        )}
                      </Upload>
                    )}

                    {handleDocumentDownload(DocumentConstants.CNPJ, 'Download')}
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Content>

      <div
        style={{
          display: 'flex',
          flex: 1,
          justifyContent: 'flex-end',
          paddingBottom: 10,
          paddingRight: 20,
          backgroundColor: 'white',
        }}
      >
        <Button
          type="primary"
          disabled={disabled}
          loading={saving}
          htmlType="submit"
          onClick={() => form.submit()}
        >
          Salvar
        </Button>
        <Button style={{ marginLeft: 20 }} onClick={() => setCurrent(current + 1)}>
          Próxima Etapa
        </Button>
      </div>

      <PlanModal visible={visibleModal} />
    </>
  );
};

Documents.propTypes = {
  current: PropTypes.number.isRequired,
  setCurrent: PropTypes.func.isRequired,
};

export default Documents;
