import styled from 'styled-components';
import { Table, Layout } from 'antd';
import media from 'styled-media-query';

export const StyledTable = styled(Table)`
  overflow: auto;
  .ant-table-row {
    background-color: white;
  }
`;

export const Wrapper = styled(Layout)`
  padding: 30px;
  flex: 1;
  height: 100%;
  overflow: auto;

  .ant-card-body {
    ${media.lessThan('small')`
      padding: 5px;
    `}
  }
`;
