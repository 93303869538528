/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Form, Button, Input } from 'antd';
import { Creators as CategoryActions } from '../../../../../../store/ducks/categories';

const CategoryForm = ({ visible, category }) => {
  const dispatch = useDispatch();
  const { createLoading: loading } = useSelector((state) => state.categories);
  const [form] = Form.useForm();
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (visible) form.setFieldsValue(category);
  }, [visible, category, form]);

  function onFinish(values) {
    if (category) {
      dispatch(
        CategoryActions.editCategory({
          id: category.id,
          ...values,
        }),
      );
    } else {
      dispatch(CategoryActions.createCategory(values));
      form.resetFields();
    }

    setDisabled(true);
  }

  function handleCloseModal() {
    form.resetFields();
    dispatch(CategoryActions.handleModalVisibility(false));

    setDisabled(true);
  }

  return (
    <Modal
      title={category ? 'Editar Categoria' : 'Cadastrar Categoria'}
      centered
      maskClosable={false}
      visible={visible}
      onCancel={() => handleCloseModal()}
      footer={[
        <Button key="back" onClick={() => handleCloseModal()}>
          Cancelar
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          disabled={disabled}
          onClick={() => form.submit()}
        >
          Salvar
        </Button>,
      ]}
    >
      <Form form={form} name="control-hooks" onFinish={onFinish}>
        <Form.Item
          name="name"
          label="Título"
          rules={[{ required: true, message: 'O campo título é obrigatório' }]}
        >
          <Input
            onChange={(e) => setDisabled(!(e.target.value.length > 0))}
            placeholder="Entrada, Prato Principal, Sobremesas..."
            maxLength="190"
            style={{ marginLeft: 10 }}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

CategoryForm.propTypes = {
  visible: PropTypes.bool.isRequired,
  category: PropTypes.shape(),
};

CategoryForm.defaultProps = {
  category: null,
};

export default CategoryForm;
