import React from 'react';
import styled from 'styled-components';
import { Alert, Typography, Row, Col, Divider } from 'antd';
import { Wrapper, LoginBox } from './styles';

const { Title } = Typography;

const AlertContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
`;

const RegisterSuccess = () => (
  <Wrapper>
    <LoginBox>
      <Row justify="center" style={{ paddingTop: 40 }}>
        <img
          src="assets/icons/getin_logo.svg"
          alt="Get In Delivery"
          width="150"
        />
      </Row>

      <Divider />

      <Row justify="left" style={{ paddingTop: 0, paddingBottom: 10 }}>
        <Col>
          <Alert
            message={<Title level={2}>Em Análise!</Title>}
            description={
              <AlertContainer>
                <div>
                  <p>Seu cadastro passará por análise e em breve entraremos em contato.</p>
                </div>
              </AlertContainer>
            }
            type="info"
            showIcon
          />
        </Col>
      </Row>
    </LoginBox>
  </Wrapper>
);

export default RegisterSuccess;
