import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Col, Space, Row, Tag, Popconfirm, Button, Card, Popover, Input } from 'antd';

import {
  CheckOutlined,
  StopOutlined,
  EditOutlined,
  PlusCircleOutlined,
  CheckCircleOutlined,
  MinusCircleOutlined,
  SearchOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';

import { Creators as UserCreators } from '../../store/ducks/users';

import UserForm from './components/UserForm';
import { StyledTable, Wrapper } from './styles';

import { ALL_ROLES } from '../../constants/roles';

const getColumnSearchProps = (dataIndex, placeholder, searchInput) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
    <div style={{ padding: 8 }}>
      <Input
        ref={(node) => {
          // eslint-disable-next-line no-param-reassign
          searchInput = node;
        }}
        placeholder={placeholder}
        value={selectedKeys[0]}
        onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
        onPressEnter={() => confirm()}
        style={{ width: 188, marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => confirm()}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Procurar
        </Button>
        <Button
          onClick={() => clearFilters()}
          size="small"
          style={{ width: 90 }}
          icon={<CloseCircleOutlined />}
        >
          Limpar
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
  onFilter: (value, record) => (
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : ''),
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      setTimeout(() => searchInput.select(), 100);
    }
  },
});

const User = () => {
  const [editable, setEditableUser] = useState(null);
  const [dataUser, setDataUser] = useState([]);
  const dispatch = useDispatch();
  const { loading, data, visible } = useSelector((state) => state.users);

  useEffect(() => {
    dispatch(UserCreators.getAllUsers());
  }, [dispatch]);

  const getRole = (reference) => {
    // eslint-disable-next-line
    const role = ALL_ROLES.find((role) => {
      return role.reference === reference;
    });
    return role ? role.text : 'Outros';
  };

  const handleUserStatus = (user) => {
    dispatch(UserCreators.toggleVisibility(user));
  };

  useEffect(() => {
    if (data.length > 0) {
      const users = data.map((user) => ({
        key: user.id,
        name: user.name,
        profile: getRole(user.role),
        active: user.active,
        username: user.username,
      }));

      setDataUser(users);
    }
  }, [data]);

  // eslint-disable-next-line arrow-body-style
  const handleColumnSort = (column) => {
    return {
      compare: (a, b) => {
        const columnA = a[column].toUpperCase();
        const columnB = b[column].toUpperCase();

        if (columnA < columnB) {
          return -1;
        }

        if (columnA > columnB) {
          return 1;
        }

        return 0;
      },
    };
  };

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
      sorter: handleColumnSort('name'),
      multiple: 1,
      ...getColumnSearchProps('name', 'Pesquisar por nome', {}),
    },
    {
      title: 'Usuário',
      dataIndex: 'username',
      key: 'username',
      sorter: handleColumnSort('username'),
      ...getColumnSearchProps('username', 'Pesquisar por usuário', {}),
    },
    {
      title: 'Perfil',
      dataIndex: 'profile',
      key: 'profile',
      sorter: handleColumnSort('profile'),
      multiple: 1,
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (_, { active }) => (
        <>
          {active ? (
            <Tag icon={<CheckCircleOutlined />} color="success">
              Ativo
            </Tag>
          ) : (
            <Tag icon={<MinusCircleOutlined />} color="default">
              Inativo
            </Tag>
          )}
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (_, record) => (
        <Space style={{ overflow: 'auto' }}>
          <Popover content="Editar Informações">
            <Button
              type="primary"
              shape="circle"
              onClick={() => {
                dispatch(UserCreators.handleModalVisibility(true));
                setEditableUser(record);
              }}
              icon={<EditOutlined />}
              size="medium"
            />
          </Popover>

          <Popover content={record.active ? 'Desativar usuário' : 'Ativar usuário'}>
            <Popconfirm
              title={
                record.active
                  ? 'Deseja realmente desativar este usuário?'
                  : 'Deseja realmente ativar este usuário?'
              }
              onConfirm={() => handleUserStatus(record)}
              okText="Sim"
              cancelText="Não"
            >
              <Button
                type="primary"
                style={{
                  backgroundColor: record.active ? '' : '#87d068',
                  border: 'none',
                }}
                shape="circle"
                icon={record.active ? <StopOutlined /> : <CheckOutlined />}
                size="medium"
                danger={record.active}
              />
            </Popconfirm>
          </Popover>
        </Space>
      ),
    },
  ];

  const renderExtraButton = () => (
    <Button
      onClick={() => {
        dispatch(UserCreators.handleModalVisibility(true));
        setEditableUser(null);
      }}
      style={{ borderRadius: 20 }}
      type="primary"
      icon={<PlusCircleOutlined />}
    >
      Adicionar usuário
    </Button>
  );

  return (
    <Wrapper>
      <Row>
        <Col xs={24} sm={24} md={9} lg={24} xl={24}>
          <Card title="Lista de Usuários" extra={renderExtraButton()}>
            <StyledTable
              loading={loading}
              columns={columns}
              dataSource={dataUser}
              locale={{
                emptyText: 'Sem dados para exibir',
              }}
            />
          </Card>
        </Col>
      </Row>

      <UserForm visible={visible} user={editable} />
    </Wrapper>
  );
};

export default User;
