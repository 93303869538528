import React from 'react';
import PropTypes from 'prop-types';
import { Row, TimePicker, Select, Typography } from 'antd';
import moment from 'moment';

const { Option } = Select;

const { Text } = Typography;
const days = [
  {
    id: 0,
    name: 'DOM',
  },
  {
    id: 1,
    name: 'SEG',
  },
  {
    id: 2,
    name: 'TER',
  },
  {
    id: 3,
    name: 'QUA',
  },
  {
    id: 4,
    name: 'QUI',
  },
  {
    id: 5,
    name: 'SEX',
  },
  {
    id: 6,
    name: 'SAB',
  },
];
const format = 'HH:mm';
function DayTimer({ data, setValuesInDelivery, index }) {
  return (
    <Row style={{ marginTop: 10 }} align="middle">
      <Text style={{ marginRight: 10, marginLeft: 10 }}>Dia:</Text>
      <Select
        defaultValue={data ? data.weekday : 1}
        style={{ width: 100 }}
        onChange={(value) => setValuesInDelivery('weekday', value, index)}
      >
        {days.map((day) => (
          <Option key={day.id} value={day.id}>
            {day.name}
          </Option>
        ))}
      </Select>
      <Text style={{ marginRight: 10, marginLeft: 20 }}>De:</Text>
      <TimePicker
        defaultValue={moment(data ? data.opening_time : '00:00', format)}
        onChange={(value) => {
          setValuesInDelivery('opening_time', moment(value).format(format), index);
        }}
        format={format}
      />
      <Text style={{ marginRight: 10, marginLeft: 10 }}>Até:</Text>
      <TimePicker
        defaultValue={moment(data ? data.closing_time : '00:00', format)}
        onChange={(value) => {
          setValuesInDelivery('closing_time', moment(value).format(format), index);
        }}
        format={format}
      />
    </Row>
  );
}

DayTimer.propTypes = {
  index: PropTypes.number.isRequired,
  setValuesInDelivery: PropTypes.func.isRequired,
  data: PropTypes.shape({
    weekday: PropTypes.number,
    opening_time: PropTypes.number,
    closing_time: PropTypes.number,
  }),
};

DayTimer.defaultProps = {
  data: null,
};

export default DayTimer;
