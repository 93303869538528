import Immutable from 'seamless-immutable';

export const Types = {
  GET_CATEGORIES_REQUEST: 'category/GET_CATEGORIES_REQUEST',
  GET_CATEGORIES_SUCCESS: 'category/GET_CATEGORIES_SUCCESS',
  GET_CATEGORIES_FAILED: 'category/GET_CATEGORIES_FAILED',
  CREATE_CATEGORY_REQUEST: 'category/CREATE_CATEGORY_REQUEST',
  CREATE_CATEGORY_SUCCESS: 'category/CREATE_CATEGORY_SUCCESS',
  CREATE_CATEGORY_FAILED: 'category/CREATE_CATEGORY_FAILED',
  EDIT_CATEGORY_REQUEST: 'category/EDIT_CATEGORY_REQUEST',
  EDIT_CATEGORY_SUCCESS: 'category/EDIT_CATEGORY_SUCCESS',
  EDIT_CATEGORY_FAILED: 'category/EDIT_CATEGORY_FAILED',
  CATEGORIES_REORDER_REQUEST: 'category/CATEGORIES_REORDER_REQUEST',
  CATEGORIES_REORDER_SUCCESS: 'category/CATEGORIES_REORDER_SUCCESS',
  CATEGORIES_REORDER_FAILED: 'category/CATEGORIES_REORDER_FAILED',
  TOGGLE_CATEGORY_VISIBILITY_REQUEST: 'category/TOGGLE_CATEGORY_VISIBILITY_REQUEST',
  TOGGLE_CATEGORY_VISIBILITY_SUCCESS: 'category/TOGGLE_CATEGORY_VISIBILITY_SUCCESS',
  TOGGLE_CATEGORY_VISIBILITY_FAILED: 'category/TOGGLE_CATEGORY_VISIBILITY_FAILED',
  DELETE_CATEGORY_REQUEST: 'category/DELETE_CATEGORY_REQUEST',
  DELETE_CATEGORY_SUCCESS: 'category/DELETE_CATEGORY_SUCCESS',
  DELETE_CATEGORY_FAILED: 'category/DELETE_CATEGORY_FAILED',
  ADD_PRODUCT_IN_CATEGORY: 'category/ADD_PRODUCT_IN_CATEGORY',
  REMOVE_PRODUCT_FROM_CATEGORY: 'category/REMOVE_PRODUCT_FROM_CATEGORY',

  HANDLE_MODAL_VISIBILITY: 'category/HANDLE_MODAL_VISIBILITY',
};

const INITIAL_STATE = Immutable({
  loading: false,
  data: [],
  createLoading: false,
  visible: false,
});

export default function categories(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CATEGORIES_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.GET_CATEGORIES_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }

    case Types.CREATE_CATEGORY_REQUEST: {
      return {
        ...state,
        createLoading: true,
      };
    }
    case Types.CREATE_CATEGORY_SUCCESS: {
      return {
        ...state,
        createLoading: false,
        data: [...state.data, { ...action.payload, items: [] }],
      };
    }

    case Types.EDIT_CATEGORY_REQUEST: {
      return {
        ...state,
        createLoading: true,
      };
    }
    case Types.EDIT_CATEGORY_SUCCESS: {
      return {
        ...state,
        createLoading: false,
        data: state.data.map((category) => {
          if (category.id === action.payload.id) {
            return action.payload;
          }

          return category;
        }),
      };
    }

    case Types.CREATE_CATEGORY_FAILED: {
      return {
        ...state,
        createLoading: false,
      };
    }

    case Types.CATEGORIES_REORDER_FAILED: {
      return {
        ...state,
        data: [...state.data],
      };
    }

    case Types.DELETE_CATEGORY_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((category) => category.id !== action.payload),
      };
    }

    case Types.ADD_PRODUCT_IN_CATEGORY: {
      return {
        ...state,
        data: state.data.map((category) => {
          if (category.id === action.payload.section_id) {
            return {
              ...category,
              items: [...category.items, action.payload],
            };
          }

          return category;
        }),
      };
    }

    case Types.REMOVE_PRODUCT_FROM_CATEGORY: {
      return {
        ...state,
        data: state.data.map((category) => ({
          ...category,
          items: category.items.filter((cat) => cat.id !== action.payload),
        })),
      };
    }

    case Types.HANDLE_MODAL_VISIBILITY: {
      return {
        ...state,
        visible: action.payload,
      };
    }

    case Types.TOGGLE_CATEGORY_VISIBILITY_SUCCESS: {
      return {
        ...state,
        data: state.data.map((category) => {
          if (category.id === action.payload.id) {
            return {
              ...action.payload,
            };
          }
          return category;
        }),
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getCategories: (payload) => ({
    type: Types.GET_CATEGORIES_REQUEST,
    payload,
  }),
  getCategoriesSuccess: (payload) => ({
    type: Types.GET_CATEGORIES_SUCCESS,
    payload,
  }),
  getCategoriesFailed: (payload) => ({
    type: Types.GET_CATEGORIES_FAILED,
    payload,
  }),
  createCategory: (payload) => ({
    type: Types.CREATE_CATEGORY_REQUEST,
    payload,
  }),
  createCategorySuccess: (payload) => ({
    type: Types.CREATE_CATEGORY_SUCCESS,
    payload,
  }),
  createCategoryFailed: (payload) => ({
    type: Types.CREATE_CATEGORY_FAILED,
    payload,
  }),
  editCategory: (payload) => ({
    type: Types.EDIT_CATEGORY_REQUEST,
    payload,
  }),
  editCategorySuccess: (payload) => ({
    type: Types.EDIT_CATEGORY_SUCCESS,
    payload,
  }),
  editCategoryFailed: (payload) => ({
    type: Types.EDIT_CATEGORY_FAILED,
    payload,
  }),
  reorderCategories: (payload) => ({
    type: Types.CATEGORIES_REORDER_REQUEST,
    payload,
  }),
  reorderCategoriesSuccess: (payload) => ({
    type: Types.CATEGORIES_REORDER_SUCCESS,
    payload,
  }),
  reorderCategoriesFailed: (payload) => ({
    type: Types.CATEGORIES_REORDER_FAILED,
    payload,
  }),
  toggleCategoryVisibility: (payload) => ({
    type: Types.TOGGLE_CATEGORY_VISIBILITY_REQUEST,
    payload,
  }),
  toggleCategoryVisibilitySuccess: (payload) => ({
    type: Types.TOGGLE_CATEGORY_VISIBILITY_SUCCESS,
    payload,
  }),
  toggleCategoryVisibilityFailed: (payload) => ({
    type: Types.TOGGLE_CATEGORY_VISIBILITY_FAILED,
    payload,
  }),
  deleteCategory: (payload) => ({
    type: Types.DELETE_CATEGORY_REQUEST,
    payload,
  }),
  deleteCategorySuccess: (payload) => ({
    type: Types.DELETE_CATEGORY_SUCCESS,
    payload,
  }),
  deleteCategoryFailed: (payload) => ({
    type: Types.DELETE_CATEGORY_FAILED,
    payload,
  }),

  removeProductFromCategory: (payload) => ({
    type: Types.REMOVE_PRODUCT_FROM_CATEGORY,
    payload,
  }),

  addProductToCategory: (payload) => ({
    type: Types.ADD_PRODUCT_IN_CATEGORY,
    payload,
  }),

  handleModalVisibility: (payload) => ({
    type: Types.HANDLE_MODAL_VISIBILITY,
    payload,
  }),
};
