import styled from 'styled-components';
import { Layout, Tabs } from 'antd';

export const Wrapper = styled(Layout)`
  padding: 5px 30px;
  flex: 1;
`;

export const TabPainel = styled(Tabs)`
  .ant-tabs-nav-list {
    padding-top: 20px;
    padding-left: 35px;
    margin-bottom: 0px;
  }
`;
