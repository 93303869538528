export const PasswordRuleAlert = {
  border: '1px solid',
  borderRadius: '6px',
  padding: '20px 5px 20px 25px',
  display: 'flex',
  width: 'auto',
  flex: 1,
  borderColor: '#ffeeba',
  backgroundColor: '#fff3cd',
  color: '#856404',
  fontSize: '11px',
  fontWeight: 'bold',
  listStyle: 'none',
};

export const PasswordRuleAlertSub = {
  paddingLeft: '16px',
  paddingTop: '10px',
};
