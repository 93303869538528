import styled from 'styled-components';

const grid = 8;

export const Container = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  user-select: none;
  padding: ${grid * 2}px;
  margin: 0 0 ${grid}px 0;
  border: ${({ isdragging }) => (isdragging ? '1px solid grey' : 'none')};
  background: ${({ active }) => (active ? '#f2f2f2' : 'white')};

  &:hover {
    cursor: ns-resize;
  }
`;

export const Title = styled.b`
  color: black;
  font-size: 15px;
  margin-bottom: 20px;
`;

export const Informations = styled.div`
  height: 60px;
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const styles = {
  tag: {
    borderRadius: 50,
    cursor: 'pointer',
  },
};
