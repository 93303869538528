import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Card, Result, Skeleton } from 'antd';

import ProductExtra from './components/ProductExtra';
import ProductForm from './components/ProductForm';

import { Wrapper, Content } from './styles';
import Product from '../../../../components/Product';
import ProductImage from './components/ProductImage';

import { Creators as ProductCreators } from '../../../../store/ducks/products';
import { Creators as OptionalCreators } from '../../../../store/ducks/optionals';
import { Creators as ProductTypeActions } from '../../../../store/ducks/productTypes';

import { Container } from '../../../../components/Product/styles';

const ProductList = () => {
  const grid = 8;
  const dispatch = useDispatch();
  const { visible } = useSelector((state) => state.products);
  const [items, setItems] = useState([]);
  const [editableProduct, setEditableProduct] = useState(null);

  const { loading: loadingCategories } = useSelector((state) => state.categories);

  const {
    loading,
    modalImagesVisible,
    data,
    category: { id: categoryId, title },
  } = useSelector((state) => state.products);

  useEffect(() => {
    dispatch(OptionalCreators.getOptionals());
    dispatch(ProductTypeActions.getProductTypes());
  }, [dispatch]);

  useEffect(() => {
    if (data.length > 0) {
      setItems(data);
    }
  }, [data]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    dispatch(
      ProductCreators.reorderProducts({
        id: removed.menu_category_item_id,
        position: endIndex,
      }),
    );

    return result;
  };

  const getListStyle = (isDraggingOver) => ({
    width: '100%',
    background: isDraggingOver ? '#f2f2f2' : '#f2f2f2',
    padding: grid,
    margin: 0,
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(items, result.source.index, result.destination.index);

    setItems(newItems);
  };

  function renderProductList() {
    if (data.length === 0) {
      return (
        <Result
          status="404"
          title={categoryId ? 'Nenhum produto cadastrado!' : 'É necessário escolher uma categoria!'}
          subTitle={
            categoryId
              ? 'Desculpe! A lista está vazia, tente cadastrar um produto.'
              : 'Escolha uma categoria para buscarmos a lista de produtos.'
          }
        />
      );
    }

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable-product">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {items.map((item, index) => (
                <Product
                  key={item.id}
                  item={item}
                  index={index}
                  setEditableProduct={setEditableProduct}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  return (
    <Wrapper xs={24} sm={12} md={15} lg={16} xl={18}>
      <Card
        style={{ borderRadius: 5 }}
        title={categoryId ? title : 'Escolha uma categoria'}
        extra={categoryId ? <ProductExtra setEditableProduct={setEditableProduct} /> : null}
      >
        <ProductForm visible={visible} product={editableProduct} />
        <ProductImage visible={modalImagesVisible} />
        <Content>
          {loading || loadingCategories ? (
            <div style={getListStyle(false)}>
              {Array.from([0, 1, 2, 3, 4, 5]).map((index) => (
                <Container key={index}>
                  <Skeleton avatar active />
                </Container>
              ))}
            </div>
          ) : (
            renderProductList()
          )}
        </Content>
      </Card>
    </Wrapper>
  );
};

export default ProductList;
