/* eslint-disable react/jsx-no-bind */
/* eslint-disable object-shorthand */
/* eslint-disable no-param-reassign */
/* eslint-disable react/prop-types */
import React, { useState, memo, useEffect } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Tag, Switch, Dropdown, Menu, Modal } from 'antd';

import {
  CheckOutlined,
  CloseOutlined,
  EllipsisOutlined,
  EditOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';

import {
  Container,
  Image,
  Details,
  Informations,
  ProductInformation,
  Code,
  Title,
  Description,
  Price,
  Types,
  styles,
  ImagePanel,
  ImageComponent,
} from './styles';

import Icon from '../Icon';

import { Creators as ProductActions } from '../../store/ducks/products';

const { confirm } = Modal;
const imageFallback = '/assets/images/no_product_image.png?1';

const Product = ({ item, index, setEditableProduct }) => {
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(item.active);

  const toggleVisibility = () => {
    dispatch(
      ProductActions.toggleProductVisibility({
        menu_category_item_id: item.menu_category_item_id,
      }),
    );

    setVisible(!visible);
    item.active = !visible;
  };

  useEffect(() => {
    if (item) {
      setVisible(item.active);
    }
  }, [item]);

  function deleteProduct() {
    confirm({
      content: `Você deseja realmente deletar o produto "${item.name}"?`,
      icon: <ExclamationCircleOutlined />,
      title: 'Solicitação para deletar produto',
      okText: 'Sim',
      okType: 'danger',
      cancelText: 'Não',
      onOk() {
        dispatch(
          ProductActions.deleteProduct({
            id: item.id,
            name: item.name,
          }),
        );
      },
    });
  }

  function getPrice(itemPrice) {
    let price = 0.0;
    if (itemPrice) price = itemPrice;
    return price.toFixed(2).toString().replace('.', ',');
  }

  return (
    <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
      {(draggagleProvided) => (
        <div
          ref={draggagleProvided.innerRef}
          {...draggagleProvided.draggableProps}
          {...draggagleProvided.dragHandleProps}
          style={{
            ...draggagleProvided.draggableProps.style,
            flexDirection: 'column',
            marginBottom: 15,
            backgroundColor: 'white',
          }}
        >
          <Container>
            <ProductInformation>
              <ImagePanel>
                <ImageComponent>
                  <Image
                    title="Clique para alterar as imagens do produto"
                    alt="Product"
                    src={item?.image || imageFallback}
                    onClick={() => {
                      dispatch(
                        ProductActions.handleModalImageVisibility({
                          status: true,
                          item: item,
                        }),
                      );
                    }}
                  />
                </ImageComponent>
              </ImagePanel>

              <Informations>
                <Title>{item.name}</Title>
                <Description>{item.description || 'Sem descrição'}</Description>
                <Types>
                  {false
                  && item.types.map((type) => (
                      // eslint-disable-next-line react/jsx-indent
                      <Tag key={type.id} icon={<Icon width="25px" icon={`${type.icon}.svg`} />}>
                        {type.name}
                      </Tag>
                  ))}
                </Types>
                <Details>
                  <Price>{`R$ ${getPrice(item.final_price)}`}</Price>

                  {item.pos_identifier && <Code>{`(cód ${item.pos_identifier})`}</Code>}
                </Details>
                <div>
                  <Tag color="#cccccc" style={styles.tag}>
                    {item.group_options.length}
                    &nbsp;Opcionais
                  </Tag>
                  <Tag
                    className="visible-tag"
                    color={visible ? '#87d068' : '#f50'}
                    style={styles.tag}
                    onClick={() => toggleVisibility()}
                  >
                    {visible ? 'Visível' : 'Invisível'}
                  </Tag>
                </div>
              </Informations>
            </ProductInformation>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <Dropdown
                trigger={['click']}
                overlay={() => (
                  <Menu style={{ borderRadius: 10 }}>
                    <Menu.Item
                      style={{
                        padding: 15,
                      }}
                      onClick={() => {
                        setEditableProduct(item);
                        dispatch(ProductActions.handleModalVisibility(true));
                      }}
                    >
                      <EditOutlined />
                      Editar
                    </Menu.Item>
                    <Menu.Item
                      style={{
                        color: 'red',
                        padding: 15,
                      }}
                      onClick={deleteProduct}
                    >
                      <DeleteOutlined />
                      Excluir
                    </Menu.Item>
                  </Menu>
                )}
                placement="topCenter"
              >
                <EllipsisOutlined
                  size={90}
                  style={{ fontSize: 40, paddingBottom: 5, cursor: 'pointer' }}
                />
              </Dropdown>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={visible}
                checked={visible}
                onChange={() => toggleVisibility()}
              />
            </div>
          </Container>
        </div>
      )}
    </Draggable>
  );
};

Product.propTypes = {
  index: PropTypes.number.isRequired,
  setEditableProduct: PropTypes.func.isRequired,
  item: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    description: PropTypes.string,
    pos_price: PropTypes.number,
    pos_identifier: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.shape({
        filename: PropTypes.string,
      }),
    ),
    active: PropTypes.bool,
    pivot: PropTypes.shape({
      id: PropTypes.number,
      active: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    }),
  }).isRequired,
};

export default memo(Product);
