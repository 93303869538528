/* eslint-disable radix */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Steps, Col, Row, Spin } from 'antd';
import { IdcardOutlined, GiftOutlined, FormOutlined } from '@ant-design/icons';

import Details from './tabs/Details';
import Specifications from './tabs/Specifications';
import Delivery from './tabs/Delivery';
import Documents from './tabs/Documents';
import { Wrapper } from './styles';

const { Step } = Steps;

const Config = () => {
  const { loading } = useSelector((state) => state.restaurants);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const tab = parseInt(urlParams.get('tab')) || 0;

  const [current, setCurrent] = useState(tab);

  const steps = [
    {
      name: 'Store',
      component: <Details current={current} setCurrent={setCurrent} />,
    },
    {
      name: 'Documents',
      component: <Documents current={current} setCurrent={setCurrent} />,
    },
    {
      name: 'Specifications',
      component: <Specifications current={current} setCurrent={setCurrent} />,
    },
    {
      name: 'Delivery',
      component: <Delivery current={current} setCurrent={setCurrent} />,
    },
  ];

  return (
    <Wrapper>
      <Row>
        <Col
          span={24}
          style={{ backgroundColor: 'white', borderRadius: 5, height: '100%', margin: 0 }}
        >
          <Spin spinning={loading} tip="Carregando informações...">
            <Steps
              type="navigation"
              style={{ margin: 20 }}
              current={current}
              onChange={(newCurrent) => setCurrent(newCurrent)}
              className="site-navigation-steps"
            >
              <Step
                title="Informações"
                icon={<IdcardOutlined />}
                description="Configurações gerais do estabelecimento."
              />
              <Step
                title="Conta e Documentos"
                icon={<IdcardOutlined />}
                description="Conta bancária e documentos."
              />
              <Step
                title="Especificações"
                icon={<FormOutlined />}
                description="Especificações únicas da loja."
              />
              <Step
                title="Delivery"
                icon={<GiftOutlined />}
                description="Configurações de delivery e retirada."
              />
            </Steps>
          </Spin>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ backgroundColor: 'white' }}>
          <Spin spinning={loading} tip="Carregando informações...">
            {steps[current].component}
          </Spin>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Config;
