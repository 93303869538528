import Immutable from 'seamless-immutable';

export const Types = {
  GET_CUSTOMERS_REQUEST: 'customers/GET_CUSTOMERS_REQUEST',
  GET_CUSTOMERS_SUCCESS: 'customers/GET_CUSTOMERS_SUCCESS',
  GET_CUSTOMERS_FAILED: 'customers/GET_CUSTOMERS_FAILED',
};

const INITIAL_STATE = Immutable({
  loading: false,
  customers: [],
});

export default function customers(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_CUSTOMERS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }
    case Types.GET_CUSTOMERS_SUCCESS: {
      return {
        ...state,
        loading: false,
        customers: action.payload,
      };
    }

    case Types.GET_CUSTOMERS_FAILED: {
      return {
        ...state,
        loading: false,
        customers: [],
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getCustomers: (payload) => ({
    type: Types.GET_CUSTOMERS_REQUEST,
    payload,
  }),

  getCustomersSuccess: (payload) => ({
    type: Types.GET_CUSTOMERS_SUCCESS,
    payload,
  }),

  getCustomersFailed: (payload) => ({
    type: Types.GET_CUSTOMERS_FAILED,
    payload,
  }),
};
