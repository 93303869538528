import styled from 'styled-components';
import media from 'styled-media-query';

const grid = 8;

export const Container = styled.div`
  user-select: none;
  display: flex;
  min-height: 150px;
  padding: ${grid * 2}px;
  margin: 0 0 ${grid}px 0;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  border: ${({ isDragging }) => (isDragging ? '1px solid grey' : 'none')};
  background: white;

  .visible-tag {
    ${media.lessThan('small')`
      display: none;
    `}
  }
  .avatar-uploader {
    margin-top: 15px;
    padding: 10px;
  }
`;

export const ProductInformation = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
`;

export const Image = styled.img`
  height: 125px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  width: 140px;
  background-color: rgb(204, 204, 204);
  object-fit: cover;
  border-radius: 50%;
`;

export const ImagePanel = styled.div`
  display: table;
  float: left;
  width: 104px;
  height: 104px;
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  -webkit-transition: border-color .3s ease;
  transition: border-color .3s ease;
  border-radius: 50%;
  margin-left: 30px;
  padding: 0;
`;

export const ImageComponent = styled.div`
  display: table-cell;
  width: 100%;
  height: 100%;
  padding: 8px;
  text-align: center;
  vertical-align: middle;
`;

export const Title = styled.b`
  color: black;
`;

export const Description = styled.p`
  font-size: 12px;
  margin-top: 5px;
  font-style: italic;

  ${media.lessThan('small')`
    display: none;
  `}
`;

export const Types = styled.p`
  font-size: 12px;
  margin-top: 5px;

  ${media.lessThan('small')`
    display: none;
  `}
`;

export const Details = styled.div`
  ${media.lessThan('small')} {
    margin: 7px 0px;
  }
`;
export const Price = styled.b``;

export const Informations = styled.div`
  margin-left: 30px;
  flex-direction: column;
  display: flex;
  justify-content: space-between;
  ${media.lessThan('small')`
    margin-left: 20px;
  `}
`;

export const Code = styled.span`
  font-size: 12px;
  margin-left: 10px;
`;

export const styles = {
  tag: {
    borderRadius: 50,
    cursor: 'pointer',
  },
};
