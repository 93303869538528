export const styles = {
  inputPrice: {
    marginBottom: 0,
  },

  marginItem: {
    marginBottom: 0,
    marginRight: 10,
  },

  formName: {
    marginRight: 10,
    marginBottom: 0,
  },

  name: {
    marginBottom: 0,
  },
};
