import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import categories from './categories';
import auth from './auth';
import me from './me';
import products from './products';
import users from './users';
import restaurants from './restaurants';
import optionals from './optionals';
import coupons from './coupons';
import customers from './customers';
import accounts from './accounts';
import productTypes from './productTypes';

const getReducers = (history) => ({
  router: connectRouter(history),
  categories,
  products,
  auth,
  me,
  users,
  restaurants,
  optionals,
  coupons,
  customers,
  accounts,
  productTypes,
});

const reducers = (history) => combineReducers(getReducers(history));

export default reducers;
