/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable radix */
import { message } from 'antd';

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export function beforeUpload(file) {
  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
  if (!isJpgOrPng) {
    message.error('É permitido apenas arquivos do tipo JPG/PNG');
  }

  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error('É permitido apenas imagens menores que 2MB');
  }

  return isJpgOrPng && isLt2M;
}

export function validateImageSize(image, width = 1920, height = 1080) {
  return new Promise((resolve, reject) => {
    if (!image) {
      reject();
    }

    const element = new Image();
    element.onload = () => {
      if (element.width < width || element.height < height) {
        reject();
      }

      resolve();
    };

    element.src = image;
  });
}

export function base64ValidateSize(base64, maxSize = 2048) {
  if (base64.length / 1024 > maxSize) {
    const maxSizeMb = Math.round(maxSize / 1000);
    message.error(`É permitido apenas imagens menores que ${maxSizeMb}MB`);
    return false;
  }

  return true;
}

export function isAuthenticated(user) {
  const token = localStorage.getItem('access_token');
  return user && token;
}

export function getPrice(itemPrice) {
  let price = 0.0;
  if (itemPrice) price = itemPrice;
  return parseFloat(price).toFixed(2).toString().replace('.', ',');
}

export function getCurrency(value) {
  let price = 0;
  if (value) price = value;

  return price.toLocaleString('pt-br', { minimumFractionDigits: 2 });
}

export function isExpired(creationDate, minutes) {
  if (!creationDate) return false;
  const min = minutes || 10;

  const createOrder = new Date(creationDate).getTime();
  const expirationTime = createOrder + min * 60 * 1000;

  return expirationTime < new Date().getTime();
}

export function validateCnpj(s) {
  const cnpj = s.replace(/[^\d]+/g, '');

  if (cnpj.length !== 14) return false;

  if (/^(\d)\1+$/.test(cnpj)) return false;

  const t = cnpj.length - 2;
  const d = cnpj.substring(t);
  const d1 = parseInt(d.charAt(0));
  const d2 = parseInt(d.charAt(1));
  const calc = (x) => {
    const n = cnpj.substring(0, x);
    let y = x - 7;
    let s = 0;
    let r = 0;

    for (let i = x; i >= 1; i--) {
      s += n.charAt(x - i) * y--;
      if (y < 2) y = 9;
    }

    r = 11 - (s % 11);
    return r > 9 ? 0 : r;
  };

  return calc(t) === d1 && calc(t + 1) === d2;
}

export function formatCpf(cpf) {
  if (cpf && cpf.trim().length === 11) {
    const value = cpf.replace(/[^\d]/g, '');
    return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  }

  return cpf || '';
}

export function formatCnpj(cnpj) {
  if (cnpj && cnpj.trim().length === 14) {
    const value = cnpj.replace(/[^\d]/g, '');
    return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
  }

  return cnpj || '';
}

export function formatDocument(document) {
  if (!document) {
    return document;
  }

  if (document.trim().length === 11) {
    return formatCpf(document);
  }

  return formatCnpj(document);
}

export function planMutator(plan, field) {
  const planMutators = [
    {
      id: 'plano_std_getin_padrao_d30',
      name: 'Plano D+30',
      description: 'Pagamento em 30 dias',
    },
    {
      id: 'plano_pro_getin_padrao_d1',
      name: 'Plano D+10',
      description: 'Pagamento em 10 dias',
    },
  ];

  const mutator = planMutators.find((value) => value.id === plan.id);

  if (mutator) {
    return mutator[field];
  }

  return plan[field];
}

export function isValidCpf(document) {
  let sum;
  let mod;

  sum = 0;

  const invalidDocuments = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ];

  if (invalidDocuments.indexOf(document) !== -1) {
    return false;
  }

  if (document.length !== 11) {
    return false;
  }

  for (let i = 1; i <= 9; i++) {
    sum += parseInt(document.substring(i - 1, i)) * (11 - i);
  }

  mod = (sum * 10) % 11;

  if (mod === 10 || mod === 11) {
    mod = 0;
  }

  if (mod !== parseInt(document.substring(9, 10))) {
    return false;
  }

  sum = 0;

  for (let i = 1; i <= 10; i++) {
    sum += parseInt(document.substring(i - 1, i)) * (12 - i);
  }

  mod = (sum * 10) % 11;

  if (mod === 10 || mod === 11) {
    mod = 0;
  }

  if (mod !== parseInt(document.substring(10, 11))) {
    return false;
  }

  return true;
}
