import Immutable from 'seamless-immutable';

export const Types = {
  GET_OPTIONALS_REQUEST: 'optionals/GET_OPTIONALS_REQUEST',
  GET_OPTIONALS_SUCCESS: 'optionals/GET_OPTIONALS_SUCCESS',
  GET_OPTINALS_FAILED: 'optionals/GET_OPTINALS_FAILED',

  GET_OPTIONAL_REQUEST: 'optionals/GET_OPTIONAL_REQUEST',
  GET_OPTIONAL_SUCCESS: 'optionals/GET_OPTIONAL_SUCCESS',
  GET_OPTIONAL_FAILED: 'optionals/GET_OPTIONAL_FAILED',

  CREATE_OPTIONAL_REQUEST: 'optionals/CREATE_OPTIONAL_REQUEST',
  CREATE_OPTIONAL_SUCCESS: 'optionals/CREATE_OPTIONAL_SUCCESS',
  CREATE_OPTIONAL_FAILED: 'optionals/CREATE_OPTIONAL_FAILED',

  EDIT_OPTIONAL_REQUEST: 'optionals/EDIT_OPTIONAL_REQUEST',
  EDIT_OPTIONAL_SUCCESS: 'optionals/EDIT_OPTIONAL_SUCCESS',
  EDIT_OPTIONAL_FAILED: 'optionals/EDIT_OPTIONAL_FAILED',

  DELETE_OPTIONAL_REQUEST: 'optionals/DELETE_OPTIONAL_REQUEST',
  DELETE_OPTIONAL_SUCCESS: 'optionals/DELETE_OPTIONAL_SUCCESS',
  DELETE_OPTIONAL_FAILED: 'optionals/DELETE_OPTIONAL_FAILED',

  HANDLE_MODAL_VISIBILITY: 'optionals/HANDLE_MODAL_VISIBILITY',

  OPTIONAL_ITEMS_REORDER_REQUEST: 'optionals/OPTIONAL_ITEMS_REORDER_REQUEST',
  OPTIONAL_ITEMS_REORDER_SUCCESS: 'optionals/OPTIONAL_ITEMS_REORDER_SUCCESS',
  OPTIONAL_ITEMS_REORDER_FAILED: 'optionals/OPTIONAL_ITEMS_REORDER_FAILED',

  TOGGLE_OPTIONAL_ITEM_VISIBILITY_REQUEST: 'optionals/TOGGLE_OPTIONAL_ITEM_VISIBILITY',
  TOGGLE_OPTIONAL_ITEM_VISIBILITY_SUCCESS: 'optionals/TOGGLE_OPTIONAL_ITEM_VISIBILITY_SUCCESS',

  SET_OPTIONAL_ITEM_EDIT: 'optionals/SET_OPTIONAL_ITEM_EDIT',
  VINCULATE_OPTIONAL_TO_PRODUCT_REQUEST: 'optionals/VINCULATE_OPTIONAL_TO_PRODUCT_REQUEST',
  VINCULATE_OPTIONAL_TO_PRODUCT_SUCCESS: 'optionals/VINCULATE_OPTIONAL_TO_PRODUCT_SUCCESS',
  VINCULATE_OPTIONAL_TO_PRODUCT_FAILED: 'optionals/VINCULATE_OPTIONAL_TO_PRODUCT_FAILED',
};

const INITIAL_STATE = Immutable({
  loading: false,
  visible: false,
  saving: false,
  edit: null,
  data: [],
});

export default function optionals(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_OPTIONALS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_OPTIONALS_SUCCESS: {
      return {
        ...state,
        loading: false,
        data: action.payload,
      };
    }

    case Types.GET_OPTINALS_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }

    case Types.GET_OPTIONAL_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_OPTIONAL_SUCCESS: {
      return {
        ...state,
        loading: false,
        optional: action.payload,
      };
    }

    case Types.GET_OPTIONAL_FAILED: {
      return {
        ...state,
        loading: false,
        optional: {},
      };
    }

    case Types.CREATE_OPTIONAL_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }
    case Types.CREATE_OPTIONAL_SUCCESS: {
      return {
        ...state,
        saving: false,
        data: [...state.data, action.payload],
      };
    }
    case Types.CREATE_OPTIONAL_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.EDIT_OPTIONAL_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }
    case Types.EDIT_OPTIONAL_SUCCESS: {
      return {
        ...state,
        saving: false,
        data: state.data.map((optional) => {
          if (optional.id === action.payload.id) {
            return action.payload;
          }

          return optional;
        }),
      };
    }
    case Types.EDIT_OPTIONAL_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.DELETE_OPTIONAL_SUCCESS: {
      return {
        ...state,
        data: state.data.filter((optional) => optional.id !== action.payload),
      };
    }

    case Types.OPTIONAL_ITEMS_REORDER_FAILED: {
      return {
        ...state,
        data: [...state.data],
      };
    }

    case Types.HANDLE_MODAL_VISIBILITY: {
      return {
        ...state,
        visible: action.payload,
      };
    }

    case Types.SET_OPTIONAL_ITEM_EDIT: {
      return {
        ...state,
        edit: action.payload,
      };
    }

    case Types.VINCULATE_OPTIONAL_TO_PRODUCT_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.VINCULATE_OPTIONAL_TO_PRODUCT_SUCCESS: {
      return {
        ...state,
        saving: true,
        data: state.data.map((optional) => {
          if (optional.id === action.payload.optional_id) {
            return {
              ...optional,
              items: [...optional.items, { id: action.payload.product_id }],
            };
          }
          return optional;
        }),
      };
    }

    case Types.VINCULATE_OPTIONAL_TO_PRODUCT_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getOptionals: (payload) => ({
    type: Types.GET_OPTIONALS_REQUEST,
    payload,
  }),
  getOptionalsSuccess: (payload) => ({
    type: Types.GET_OPTIONALS_SUCCESS,
    payload,
  }),
  getOptionalsFailed: (payload) => ({
    type: Types.GET_OPTINALS_FAILED,
    payload,
  }),
  getOptional: (payload) => ({
    type: Types.GET_OPTIONAL_REQUEST,
    payload,
  }),
  getOptionalSuccess: (payload) => ({
    type: Types.GET_OPTIONAL_SUCCESS,
    payload,
  }),
  getOptionalFailed: (payload) => ({
    type: Types.GET_OPTIONAL_FAILED,
    payload,
  }),
  createOptional: (payload) => ({
    type: Types.CREATE_OPTIONAL_REQUEST,
    payload,
  }),
  createOptionalSuccess: (payload) => ({
    type: Types.CREATE_OPTIONAL_SUCCESS,
    payload,
  }),
  createOptionalFailed: (payload) => ({
    type: Types.CREATE_OPTIONAL_FAILED,
    payload,
  }),
  editOptional: (payload) => ({
    type: Types.EDIT_OPTIONAL_REQUEST,
    payload,
  }),
  editOptionalSuccess: (payload) => ({
    type: Types.EDIT_OPTIONAL_SUCCESS,
    payload,
  }),
  editOptionalFailed: (payload) => ({
    type: Types.EDIT_OPTIONAL_FAILED,
    payload,
  }),

  deleteOptional: (payload) => ({
    type: Types.DELETE_OPTIONAL_REQUEST,
    payload,
  }),
  deleteOptionalSuccess: (payload) => ({
    type: Types.DELETE_OPTIONAL_SUCCESS,
    payload,
  }),
  deleteOptionalFailed: (payload) => ({
    type: Types.DELETE_OPTIONAL_FAILED,
    payload,
  }),

  handleModalVisibility: (payload) => ({
    type: Types.HANDLE_MODAL_VISIBILITY,
    payload,
  }),

  reorderOptionalItems: (payload) => ({
    type: Types.OPTIONAL_ITEMS_REORDER_REQUEST,
    payload,
  }),
  reorderOptionalItemsFailedSuccess: (payload) => ({
    type: Types.OPTIONAL_ITEMS_REORDER_SUCCESS,
    payload,
  }),
  reorderOptionalItemsFailed: (payload) => ({
    type: Types.OPTIONAL_ITEMS_REORDER_FAILED,
    payload,
  }),

  toggleOptionalItemVisibility: (payload) => ({
    type: Types.TOGGLE_OPTIONAL_ITEM_VISIBILITY_REQUEST,
    payload,
  }),

  toggleOptionalItemVisibilitySuccess: (payload) => ({
    type: Types.TOGGLE_OPTIONAL_ITEM_VISIBILITY_SUCCESS,
    payload,
  }),

  setOptionalEdit: (payload) => ({
    type: Types.SET_OPTIONAL_ITEM_EDIT,
    payload,
  }),

  vinculateOptionalToProduct: (payload) => ({
    type: Types.VINCULATE_OPTIONAL_TO_PRODUCT_REQUEST,
    payload,
  }),

  vinculateOptionalToProductSuccess: (payload) => ({
    type: Types.VINCULATE_OPTIONAL_TO_PRODUCT_SUCCESS,
    payload,
  }),

  vinculateOptionalToProductFailed: (payload) => ({
    type: Types.VINCULATE_OPTIONAL_TO_PRODUCT_FAILED,
    payload,
  }),
};
