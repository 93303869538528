import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';
import { Creators as OptionalActions } from '../ducks/optionals';

const Constants = {
  DELETE_OPTIONAL: 'optional/DELETE',
  TOGGLE_VISIBILITY: 'optional/TOGGLE',
  REORDER_OPTIONAL_ITEMS: 'optional/REORDER_ITEM',
};

export function* getOptionals() {
  try {
    const { data, status } = yield call(api.get, '/menus/group-options');

    if (status === 200) {
      yield put(OptionalActions.getOptionalsSuccess(data.data));
    }
  } catch (error) {
    message.error(
      'Houve um erro ao listar os opcionais deste restaurante, tente novamente mais tarde!',
    );

    yield put(OptionalActions.getOptionalsFailed());
  }
}

export function* getOptional({ payload }) {
  try {
    const { data, status } = yield call(api.get, `/menus/group-options/${payload.id}`);

    if (status === 200) {
      yield put(OptionalActions.getOptionalSuccess(data));
    }
  } catch (error) {
    yield put(OptionalActions.getOptionalFailed(error.message));
  }
}

export function* createOptional({ payload }) {
  const body = {
    ...payload,
    options: payload.options.map((option) => {
      if (option.active === undefined) {
        return {
          ...option,
          active: true,
        };
      }

      return option;
    }),
  };
  try {
    const { data, status } = yield call(api.post, '/menus/group-options', body);

    if (status === 201) {
      message.success(`O opcional '${payload.name}' foi criado com sucesso!`);
      yield put(
        OptionalActions.createOptionalSuccess({
          ...data,
          items: payload.items,
        }),
      );
      yield put(OptionalActions.getOptionals());

      yield put(OptionalActions.handleModalVisibility(false));
    }
  } catch (error) {
    message.error('Houve um erro ao criar o opcional, tente novamente mais tarde!');
    yield put(OptionalActions.createOptionalFailed());
  }
}

export function* editOptional({ payload }) {
  try {
    const { data, status } = yield call(api.put, `/menus/group-options/${payload.id}`, payload);

    if (status === 204) {
      message.success('O opcional foi alterado com sucesso!');
      yield put(OptionalActions.editOptionalSuccess(data.resource));
      yield put(OptionalActions.handleModalVisibility(false));
    }
  } catch (error) {
    if (error && error.response && error.response.data && error.response.data.message) {
      message.error(error.response.data.message);
    } else {
      message.error('Houve um erro ao editar o opcional, tente novamente mais tarde!');
    }
    yield put(OptionalActions.editOptionalFailed());
  }
}

export function* deleteOptional({ payload }) {
  message.loading({
    content: 'Estamos processando a sua solicitação para deletar!',
    key: Constants.DELETE_OPTIONAL,
  });
  try {
    const { status } = yield call(api.delete, `/menus/group-options/${payload.id}`);

    if (status === 204) {
      message.success({
        content: `O Opcional "${payload.name}" foi deletado com sucesso!`,
        key: Constants.DELETE_OPTIONAL,
      });

      yield put(OptionalActions.deleteOptionalSuccess(payload.id));
    }
  } catch (error) {
    message.error('Houve um erro ao deletar o opcional, tente novamente mais tarde!');
    yield put(OptionalActions.deleteOptionalFailed());
  }
}

export function* reorderOptionalItems({ payload }) {
  message.loading({
    content: 'Estamos processando a sua solicitação para reordenar os seus items do opcional!',
    key: Constants.REORDER_OPTIONAL_ITEMS,
  });
  try {
    const { status } = yield call(
      api.post,
      `/menus/item-group-options/${payload.id}/reorder`,
      payload,
    );

    if (status === 200) {
      message.success({
        content: 'Os items foram reordenados com sucesso!',
        key: Constants.REORDER_OPTIONAL_ITEMS,
      });
    }
  } catch (error) {
    message.error('Houve um erro ao reordenar os items, tente novamente mais tarde!');
    yield put(OptionalActions.reorderOptionalItemsFailed());
  }
}

export function* toggleVisibilityOptionalItem({ payload }) {
  message.loading({
    content:
      'Estamos processando a sua solicitação para alterar a visibilidade  do item do opcional!',
    key: Constants.TOGGLE_VISIBILITY,
  });
  try {
    const { status } = yield call(api.patch, `/menus/item-group-options/${payload.id}/status`, {
      active: !payload.active,
    });

    if (status === 200) {
      message.success({
        content: 'A visibilidade do item do opcional foi alterada com sucesso!',
        key: Constants.TOGGLE_VISIBILITY,
      });
      yield put(OptionalActions.toggleOptionalItemVisibilitySuccess());
    }
  } catch (error) {
    message.error(
      'Houve um erro ao alterar a visibilidade do item do opcional, tente novamente mais tarde!',
    );
    yield put(OptionalActions.toggleOptionalItemVisibilityFailed());
  }
}

export function* vinculateOptionalToProduct({ payload }) {
  try {
    const { status } = yield call(
      api.post,
      `/menus/group-options/${payload.optional_id}/associate-offer`,
      {
        item_id: payload.product_id,
      },
    );

    if (status === 200) {
      message.success('O Opcional foi vinculado a este produto!');
      yield put(OptionalActions.vinculateOptionalToProductSuccess(payload));
    }
  } catch (error) {
    message.error('Houve um erro ao vincular o opcional ao produto, tente novamente mais tarde!');
    yield put(OptionalActions.vinculateOptionalToProductFailed());
  }
}
