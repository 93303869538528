import { Input } from 'antd';
import React, { forwardRef } from 'react';
import ReactInputMask from 'react-input-mask';
import PropTypes from 'prop-types';

const InputMask = ({ disabled, autoComplete, onChange, onBlur, onFocus, value, ...props }) => (
  <ReactInputMask {...props} value={value} onChange={onChange} onBlur={onBlur} onFocus={onFocus}>
    <Input {...props} autoComplete={autoComplete || 'true'} disabled={disabled || null} />
  </ReactInputMask>
);

InputMask.propTypes = {
  autoComplete: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

InputMask.defaultProps = {
  autoComplete: 'none',
  disabled: false,
  value: '',
  onChange: () => null,
  onBlur: () => null,
  onFocus: () => null,
};

export default forwardRef((props) => <InputMask {...props} />);
