import { all, takeLatest, takeEvery } from 'redux-saga/effects';
import { Types as CategoryTypes } from '../ducks/categories';
import { Types as ProductTypes } from '../ducks/products';
import { Types as AuthTypes } from '../ducks/auth';
import { Types as MeTypes } from '../ducks/me';
import { Types as UserTypes } from '../ducks/users';
import { Types as RestaurantTypes } from '../ducks/restaurants';
import { Types as OptionalTypes } from '../ducks/optionals';
import { Types as CouponTypes } from '../ducks/coupons';
import { Types as CustomerTypes } from '../ducks/customers';
import { Types as AccountTypes } from '../ducks/accounts';
import { Types as ProductTypeTypes } from '../ducks/productTypes';

import {
  login,
  logout,
  register,
  resetPassword,
  changePassword,
  handleAuth,
  getAuthRestaurants,
} from './auth';

import {
  getCategories,
  createCategory,
  editCategory,
  reorderCategories,
  toggleVisiblityCategory,
  deleteCategory,
} from './categories';

import {
  getProducts,
  getProduct,
  createProduct,
  editProduct,
  uploadImage as productUploadImage,
  reorderProducts,
  toggleVisiblityProduct,
  deleteProduct,
  getItemImages,
  removeImage,
} from './products';

import { getAllUsers, createUser, editUser, toggleUserVisibility } from './users';

import {
  getRestaurantBasicInfo,
  getRestaurantDeliveries,
  getRestaurantPendencies,
  changeVisibleApp,
  getRestaurantSpecifications,
  getRestaurantPlans,
  getRestaurantBankAccounts,
  getRestaurantImage,
  editRestaurantBasicInfo,
  editRestaurantDeliveries,
  editRestaurantSpecifications,
  editRestaurantPlans,
  editRestaurantBankAccounts,
  storeRestaurantBankAccounts,
  uploadImage,
  uploadDocument,
  getRestaurantPlan,
  associatePlan,
  getDocuments,
} from './restaurants';

import {
  getOptionals,
  getOptional,
  createOptional,
  editOptional,
  deleteOptional,
  toggleVisibilityOptionalItem,
  reorderOptionalItems,
  vinculateOptionalToProduct,
} from './optionals';

import { getCoupons, createCoupon, editCoupon, getCoupon, deleteCoupon } from './coupons';

import { getCustomers } from './customers';
import { getAccount } from './accounts';
import { getProductTypes } from './productTypes';

export default function* rootSaga() {
  yield all([
    takeLatest(CategoryTypes.GET_CATEGORIES_REQUEST, getCategories),
    takeLatest(CategoryTypes.CREATE_CATEGORY_REQUEST, createCategory),
    takeLatest(CategoryTypes.EDIT_CATEGORY_REQUEST, editCategory),
    takeLatest(CategoryTypes.CATEGORIES_REORDER_REQUEST, reorderCategories),
    takeLatest(CategoryTypes.TOGGLE_CATEGORY_VISIBILITY_REQUEST, toggleVisiblityCategory),
    takeLatest(CategoryTypes.DELETE_CATEGORY_REQUEST, deleteCategory),

    takeLatest(ProductTypes.GET_PRODUCT_REQUEST, getProduct),
    takeLatest(ProductTypes.GET_PRODUCTS_REQUEST, getProducts),
    takeLatest(ProductTypes.CREATE_PRODUCTS_REQUEST, createProduct),
    takeLatest(ProductTypes.EDIT_PRODUCT_REQUEST, editProduct),
    takeLatest(ProductTypes.PRODUCTS_REORDER_REQUEST, reorderProducts),
    takeLatest(ProductTypes.TOGGLE_PRODUCT_VISIBILITY_REQUEST, toggleVisiblityProduct),
    takeLatest(ProductTypes.DELETE_PRODUCT_REQUEST, deleteProduct),
    takeLatest(ProductTypes.GET_ITEM_IMAGES_REQUEST, getItemImages),
    takeLatest(ProductTypes.REMOVE_IMAGE_REQUEST, removeImage),
    takeEvery(ProductTypes.UPLOAD_IMAGE_REQUEST, productUploadImage),

    takeLatest(AuthTypes.GET_AUTH_REQUEST, login),
    takeLatest(AuthTypes.REGISTER_USER_AND_AUTH_REQUEST, register),
    takeLatest(AuthTypes.RESET_PASSWORD_REQUEST, resetPassword),
    takeLatest(AuthTypes.CHANGE_PASSWORD_REQUEST, changePassword),
    takeLatest(AuthTypes.HANDLE_AUTH, handleAuth),
    takeLatest(AuthTypes.GET_AUTH_RESTAURANTS, getAuthRestaurants),

    takeLatest(MeTypes.REMOVE_AUTH_USER_REQUEST, logout),

    takeLatest(UserTypes.GET_ALL_USERS_REQUEST, getAllUsers),
    takeLatest(UserTypes.TOGGLE_USER_VISIBILITY_REQUEST, toggleUserVisibility),
    takeLatest(UserTypes.CREATE_USER_REQUEST, createUser),
    takeLatest(UserTypes.EDIT_USER_REQUEST, editUser),

    takeLatest(RestaurantTypes.GET_RESTAURANT_BASIC_INFO_REQUEST, getRestaurantBasicInfo),
    takeLatest(RestaurantTypes.GET_RESTAURANT_DELIVERIES_REQUEST, getRestaurantDeliveries),
    takeLatest(RestaurantTypes.GET_RESTAURANT_PENDENCIES_REQUEST, getRestaurantPendencies),
    takeLatest(RestaurantTypes.CHANGE_VISIBLE_APP_REQUEST, changeVisibleApp),
    takeLatest(RestaurantTypes.GET_RESTAURANT_SPECIFICATIONS_REQUEST, getRestaurantSpecifications),
    takeLatest(RestaurantTypes.GET_RESTAURANT_PLANS_REQUEST, getRestaurantPlans),
    takeLatest(RestaurantTypes.GET_RESTAURANT_BANK_ACCOUNTS_REQUEST, getRestaurantBankAccounts),
    takeLatest(RestaurantTypes.GET_RESTAURANT_IMAGE_REQUEST, getRestaurantImage),
    takeLatest(RestaurantTypes.UPDATE_RESTAURANT_BASIC_INFO_REQUEST, editRestaurantBasicInfo),
    takeLatest(RestaurantTypes.UPDATE_RESTAURANT_DELIVERIES_REQUEST, editRestaurantDeliveries),
    takeLatest(
      RestaurantTypes.UPDATE_RESTAURANT_SPECIFICATIONS_REQUEST,
      editRestaurantSpecifications,
    ),
    takeLatest(RestaurantTypes.UPDATE_RESTAURANT_PLANS_REQUEST, editRestaurantPlans),
    takeLatest(RestaurantTypes.UPDATE_RESTAURANT_BANK_ACCOUNTS_REQUEST, editRestaurantBankAccounts),
    takeLatest(RestaurantTypes.STORE_RESTAURANT_BANK_ACCOUNTS_REQUEST, storeRestaurantBankAccounts),
    takeLatest(RestaurantTypes.UPLOAD_IMAGE_REQUEST, uploadImage),
    takeLatest(RestaurantTypes.UPLOAD_DOCUMENT_REQUEST, uploadDocument),
    takeLatest(RestaurantTypes.GET_RESTAURANT_PLAN_REQUEST, getRestaurantPlan),
    takeLatest(RestaurantTypes.ASSOCIATE_PLAN_REQUEST, associatePlan),
    takeLatest(RestaurantTypes.GET_RESTAURANT_DOCUMENTS, getDocuments),

    takeLatest(OptionalTypes.GET_OPTIONAL_REQUEST, getOptional),
    takeLatest(OptionalTypes.GET_OPTIONALS_REQUEST, getOptionals),
    takeLatest(OptionalTypes.CREATE_OPTIONAL_REQUEST, createOptional),
    takeLatest(OptionalTypes.EDIT_OPTIONAL_REQUEST, editOptional),
    takeLatest(OptionalTypes.DELETE_OPTIONAL_REQUEST, deleteOptional),
    takeLatest(OptionalTypes.TOGGLE_OPTIONAL_ITEM_VISIBILITY_REQUEST, toggleVisibilityOptionalItem),
    takeLatest(OptionalTypes.OPTIONAL_ITEMS_REORDER_REQUEST, reorderOptionalItems),
    takeLatest(OptionalTypes.VINCULATE_OPTIONAL_TO_PRODUCT_REQUEST, vinculateOptionalToProduct),

    takeLatest(CouponTypes.GET_COUPONS_REQUEST, getCoupons),
    takeLatest(CouponTypes.GET_COUPON_REQUEST, getCoupon),
    takeLatest(CouponTypes.CREATE_COUPONS_REQUEST, createCoupon),
    takeLatest(CouponTypes.EDIT_COUPON_REQUEST, editCoupon),
    takeLatest(CouponTypes.DELETE_COUPON_REQUEST, deleteCoupon),

    takeLatest(CustomerTypes.GET_CUSTOMERS_REQUEST, getCustomers),

    takeLatest(AccountTypes.GET_ACCOUNT_REQUEST, getAccount),

    takeLatest(ProductTypeTypes.GET_PRODUCT_TYPES, getProductTypes),
  ]);
}
