import React from 'react';
import PropTypes from 'prop-types';
import { Tag, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { Creators as ProductActions } from '../../../../../../store/ducks/products';

const ProductExtra = ({ setEditableProduct }) => {
  const dispatch = useDispatch();
  const { category } = useSelector((state) => state.products);
  return (
    <div>
      <Tag color={category.active ? '#87d068' : '#f50'} style={{ borderRadius: 50 }}>
        {category.active ? 'Visível' : 'Invisível'}
      </Tag>
      <Tag color="#cccccc" style={{ borderRadius: 50 }}>
        {`${category.available_items_quantity} Itens`}
      </Tag>
      <Button
        onClick={() => {
          setEditableProduct(null);
          dispatch(ProductActions.handleModalVisibility(true));
        }}
        style={{ borderRadius: 20 }}
        type="primary"
      >
        Adicionar Produto
      </Button>
    </div>
  );
};

ProductExtra.propTypes = {
  setEditableProduct: PropTypes.func.isRequired,
};

export default ProductExtra;
