import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Card, Result, Skeleton, Button } from 'antd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import { Wrapper, Content } from './styles';
import Category from '../../../../components/Category';
import { Container } from '../../../../components/Category/styles';
import { Creators as CategoryActions } from '../../../../store/ducks/categories';

import CategoryForm from './components/CategoryForm';

const CategoryList = () => {
  const grid = 8;
  const dispatch = useDispatch();
  const { loading, data, visible } = useSelector((state) => state.categories);
  const [items, setItems] = useState(data);
  const [editableCategory, setEditableCategory] = useState(null);

  useEffect(() => {
    dispatch(CategoryActions.getCategories());
  }, [dispatch]);

  useEffect(() => {
    if (data.length > 0) {
      setItems(data);
    }
  }, [data]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    dispatch(
      CategoryActions.reorderCategories({
        id: removed.id,
        position: endIndex + 1,
      }),
    );

    return result;
  };

  const getListStyle = () => ({
    width: '100%',
    background: '#f2f2f2',
    padding: grid,
    margin: 0,
  });

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newItems = reorder(items, result.source.index, result.destination.index);

    setItems(newItems);
  };

  function renderCategoryList() {
    if (data.total === 0) {
      return (
        <Result
          status="404"
          title="Nenhuma categoria cadastrada!"
          subTitle="Desculpe! A lista está vazia, tente cadastrar uma categoria."
        />
      );
    }

    return (
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle()}>
              {items.map((item, index) => (
                <Category
                  key={item.id}
                  item={item}
                  index={index}
                  setEditableItem={setEditableCategory}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    );
  }

  const addCategoryButton = () => (
    <Button
      onClick={() => {
        setEditableCategory(null);
        dispatch(CategoryActions.handleModalVisibility(true));
      }}
      type="primary"
      style={{ borderRadius: 20 }}
    >
      Crie seu cardápio
    </Button>
  );

  return (
    <Wrapper xs={24} sm={12} md={9} lg={8} xl={6}>
      <Card style={{ borderRadius: 5 }} title="Categorias" extra={addCategoryButton()}>
        <CategoryForm visible={visible} category={editableCategory} />
        <Content>
          {loading ? (
            <div style={getListStyle(false)}>
              {Array.from([0, 1, 2, 3, 4]).map((index) => (
                <Container key={index}>
                  <Skeleton active />
                </Container>
              ))}
            </div>
          ) : (
            renderCategoryList()
          )}
        </Content>
      </Card>
    </Wrapper>
  );
};

export default CategoryList;
