import { call, put } from 'redux-saga/effects';
import { message } from 'antd';
import api from '../../services/api';
import { Creators as CustomerActions } from '../ducks/customers';

export function* getCustomers() {
  try {
    const { data, status } = yield call(
      api.get,
      '/customers',
    );

    if (status === 200) {
      yield put(CustomerActions.getCustomersSuccess(data.data));
    }
  } catch (error) {
    message.error('Houve um erro ao retornar a lista de consumidores!');
    yield put(CustomerActions.getCustomersFailed());
  }
}
