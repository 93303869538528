/**
 * Backoffice permissions
 */
export const ROLE_SUPPORT = 5;
export const ROLE_MASTER = 100;
export const ROLE_MARKETING = 4;

/**
 * Admin permissions
 */
export const ROLE_ADMIN = 1;
export const ROLE_EXPERT = 2;

export const ROLES_ADMIN = [ROLE_ADMIN, ROLE_EXPERT];

export const ROLES_BACKOFFICE = [ROLE_SUPPORT, ROLE_MASTER, ROLE_MARKETING];

export const ROLES = [
  {
    text: 'Administrador',
    reference: ROLE_ADMIN,
  },
  {
    text: 'Operador',
    reference: ROLE_EXPERT,
  },
];

export const ALL_ROLES = [
  {
    text: 'Master',
    reference: ROLE_MASTER,
  },
  {
    text: 'Suporte',
    reference: ROLE_SUPPORT,
  },
  {
    text: 'Marketing',
    reference: ROLE_MARKETING,
  },
  ...ROLES,
];
