import Immutable from 'seamless-immutable';

export const Types = {
  GET_PRODUCTS_REQUEST: 'products/GET_PRODUCTS_REQUEST',
  GET_PRODUCTS_SUCCESS: 'products/GET_PRODUCTS_SUCCESS',
  GET_PRODUCTS_FAILED: 'products/GET_PRODUCTS_FAILED',

  GET_PRODUCT_REQUEST: 'products/GET_PRODUCT_REQUEST',
  GET_PRODUCT_SUCCESS: 'products/GET_PRODUCT_SUCCESS',
  GET_PRODUCT_FAILED: 'products/GET_PRODUCT_FAILED',

  CREATE_PRODUCTS_REQUEST: 'products/CREATE_PRODUCTS_REQUEST',
  CREATE_PRODUCTS_SUCCESS: 'products/CREATE_PRODUCTS_SUCCESS',
  CREATE_PRODUCTS_FAILED: 'products/CREATE_PRODUCTS_FAILED',

  EDIT_PRODUCT_REQUEST: 'products/EDIT_PRODUCT_REQUEST',
  EDIT_PRODUCT_SUCCESS: 'products/EDIT_PRODUCT_SUCCESS',
  EDIT_PRODUCT_FAILED: 'products/EDIT_PRODUCT_FAILED',

  SET_CATEGORY_SUCCESS: 'products/SET_CATEGORY_SUCCESS',
  REMOVE_CATEGORY_SUCCESS: 'products/REMOVE_CATEGORY_SUCCESS',

  EDIT_ACTIVE_CATEGORY: 'products/EDIT_ACTIVE_CATEGORY',
  PRODUCTS_REORDER_REQUEST: 'products/PRODUCTS_REORDER_REQUEST',
  PRODUCTS_REORDER_SUCCESS: 'products/PRODUCTS_REORDER_SUCCESS',
  PRODUCTS_REORDER_FAILED: 'products/PRODUCTS_REORDER_FAILED',
  TOGGLE_PRODUCT_VISIBILITY_REQUEST: 'products/TOGGLE_PRODUCT_VISIBILITY_REQUEST',
  TOGGLE_PRODUCT_VISIBILITY_SUCCESS: 'products/TOGGLE_PRODUCT_VISIBILITY_SUCCESS',
  TOGGLE_PRODUCT_VISIBILITY_FAILED: 'products/TOGGLE_PRODUCT_VISIBILITY_FAILED',
  DELETE_PRODUCT_REQUEST: 'products/DELETE_PRODUCT_REQUEST',
  DELETE_PRODUCT_SUCCESS: 'products/DELETE_PRODUCT_SUCCESS',
  DELETE_PRODUCT_FAILED: 'products/DELETE_PRODUCT_FAILED',

  HANDLE_MODAL_VISIBILITY: 'products/HANDLE_MODAL_VISIBILITY',

  GET_ITEM_IMAGES_REQUEST: 'products/GET_ITEM_IMAGES_REQUEST',
  GET_ITEM_IMAGES_SUCCESS: 'products/GET_ITEM_IMAGES_SUCCESS',
  GET_ITEM_IMAGES_FAILED: 'products/GET_ITEM_IMAGES_FAILED',
  ADD_IMAGE: 'products/ADD_IMAGE',
  UPLOAD_IMAGE_REQUEST: 'products/UPLOAD_IMAGE_REQUEST',
  UPLOAD_IMAGE_SUCCESS: 'products/UPLOAD_IMAGE_SUCCESS',
  UPLOAD_IMAGE_FAILED: 'products/UPLOAD_IMAGE_FAILED',
  REMOVE_IMAGE_REQUEST: 'products/REMOVE_IMAGE_REQUEST',
  REMOVE_IMAGE_SUCCESS: 'products/REMOVE_IMAGE_SUCCESS',
  HANDLE_MODAL_IMAGE_VISIBILITY: 'products/HANDLE_MODAL_IMAGE_VISIBILITY',

  IMAGE_THUMB_TYPE: 1,
  IMAGE_COVER_TYPE: 2,
};

const INITIAL_STATE = Immutable({
  loading: false,
  saving: false,
  visible: false,
  data: [],
  category: {},
  categoryItems: [],
  modalImagesVisible: false,
  itemImages: [],
  itemImagesLoading: false,
  item: {},
  itemOffer: {},
});

export default function products(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.GET_PRODUCTS_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_PRODUCTS_SUCCESS: {
      const data = action.payload.data.map((value) => {
        // eslint-disable-next-line no-param-reassign
        value.menu_category_id = action.payload.menu_category_id;

        return value;
      });

      return {
        ...state,
        loading: false,
        data,
      };
    }

    case Types.GET_PRODUCTS_FAILED: {
      return {
        ...state,
        loading: false,
        data: [],
      };
    }

    case Types.GET_PRODUCT_REQUEST: {
      return {
        ...state,
        loading: true,
      };
    }

    case Types.GET_PRODUCT_SUCCESS: {
      return {
        ...state,
        loading: false,
        itemOffer: action.payload,
      };
    }

    case Types.GET_PRODUCT_FAILED: {
      return {
        ...state,
        loading: false,
        itemOffer: {},
      };
    }

    case Types.CREATE_PRODUCTS_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.CREATE_PRODUCTS_SUCCESS: {
      return {
        ...state,
        saving: false,
        categoryItems: [...state.category.items, action.payload],
        data:
          state.category.id === action.payload.section_id
            ? [...state.data, action.payload]
            : state.data,
      };
    }

    case Types.CREATE_PRODUCTS_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.EDIT_PRODUCT_REQUEST: {
      return {
        ...state,
        saving: true,
      };
    }

    case Types.EDIT_PRODUCT_SUCCESS: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.EDIT_PRODUCT_FAILED: {
      return {
        ...state,
        saving: false,
      };
    }

    case Types.SET_CATEGORY_SUCCESS: {
      return {
        ...state,
        loading: true,
        category: action.payload,
        categoryItems: action.payload.items,
      };
    }

    case Types.REMOVE_CATEGORY_SUCCESS: {
      if (state.category.id === action.payload) {
        return {
          ...state,
          loading: false,
          category: {},
          data: [],
          categoryItems: [],
        };
      }
      return state;
    }

    case Types.EDIT_ACTIVE_CATEGORY: {
      return {
        ...state,
        category: state.category.id === action.payload.id ? action.payload : state.category,
      };
    }

    case Types.PRODUCTS_REORDER_FAILED: {
      return {
        ...state,
        data: [...state.data],
      };
    }

    case Types.DELETE_PRODUCT_SUCCESS: {
      return {
        ...state,
        categoryItems: state.categoryItems.filter((product) => product.id !== action.payload),
        data: state.data.filter((product) => product.id !== action.payload),
      };
    }

    case Types.ADD_IMAGE: {
      return {
        ...state,
        itemImages: [...state.itemImages, action.payload],
      };
    }

    case Types.UPLOAD_IMAGE_SUCCESS: {
      return {
        ...state,
        data: state.data.map((product) => {
          if (
            product.id === action.payload.item_id
            && action.payload.type_id === Types.IMAGE_THUMB_TYPE
          ) {
            return {
              ...product,
              image: action.payload.url,
              image_id: action.payload.image_id,
            };
          }

          return product;
        }),
        item: {
          ...state.item,
          image: action.payload.url,
          image_id: action.payload.image_id,
        },
      };
    }

    case Types.UPLOAD_IMAGE_FAILED: {
      return {
        ...state,
        itemImages: state.itemImages.filter(
          (value) => value.reference !== action.payload.reference,
        ),
      };
    }

    case Types.REMOVE_IMAGE_SUCCESS: {
      return {
        ...state,
        item: {
          ...state.item,
          image: null,
        },
        data: state.data.map((product) => {
          if (product.id === action.payload.itemId) {
            return {
              ...product,
              image: null,
            };
          }

          return product;
        }),
      };
    }

    case Types.HANDLE_MODAL_VISIBILITY: {
      return {
        ...state,
        visible: action.payload,
      };
    }

    case Types.HANDLE_MODAL_IMAGE_VISIBILITY: {
      return {
        ...state,
        modalImagesVisible: action.payload.status,
        item: action.payload.status ? action.payload.item : {},
      };
    }

    case Types.GET_ITEM_IMAGES_REQUEST: {
      return {
        ...state,
        itemImagesLoading: true,
      };
    }

    case Types.GET_ITEM_IMAGES_SUCCESS: {
      return {
        ...state,
        itemImagesLoading: false,
        itemImages: action.payload,
      };
    }

    case Types.GET_ITEM_IMAGES_FAILED: {
      return {
        ...state,
        modalImagesVisible: false,
        itemImages: [],
        itemImagesLoading: false,
      };
    }

    default:
      return state;
  }
}

export const Creators = {
  getProducts: (payload) => ({
    type: Types.GET_PRODUCTS_REQUEST,
    payload,
  }),

  getProductsSuccess: (payload) => ({
    type: Types.GET_PRODUCTS_SUCCESS,
    payload,
  }),

  getProductsFailed: (payload) => ({
    type: Types.GET_PRODUCTS_FAILED,
    payload,
  }),

  getProduct: (payload) => ({
    type: Types.GET_PRODUCT_REQUEST,
    payload,
  }),

  getProductSuccess: (payload) => ({
    type: Types.GET_PRODUCT_SUCCESS,
    payload,
  }),

  getProductFailed: (payload) => ({
    type: Types.GET_PRODUCT_FAILED,
    payload,
  }),

  createProduct: (payload) => ({
    type: Types.CREATE_PRODUCTS_REQUEST,
    payload,
  }),

  createProductSuccess: (payload) => ({
    type: Types.CREATE_PRODUCTS_SUCCESS,
    payload,
  }),

  createProductFailed: (payload) => ({
    type: Types.CREATE_PRODUCTS_FAILED,
    payload,
  }),

  editProduct: (payload) => ({
    type: Types.EDIT_PRODUCT_REQUEST,
    payload,
  }),

  editProductSuccess: (payload) => ({
    type: Types.EDIT_PRODUCT_SUCCESS,
    payload,
  }),

  editProductFailed: (payload) => ({
    type: Types.EDIT_PRODUCT_FAILED,
    payload,
  }),

  setCategorySuccess: (payload) => ({
    type: Types.SET_CATEGORY_SUCCESS,
    payload,
  }),

  removeCategorySuccess: (payload) => ({
    type: Types.REMOVE_CATEGORY_SUCCESS,
    payload,
  }),

  editActiveCategory: (payload) => ({
    type: Types.EDIT_ACTIVE_CATEGORY,
    payload,
  }),

  reorderProducts: (payload) => ({
    type: Types.PRODUCTS_REORDER_REQUEST,
    payload,
  }),

  reorderProductsSuccess: (payload) => ({
    type: Types.PRODUCTS_REORDER_SUCCESS,
    payload,
  }),

  reorderProductsFailed: (payload) => ({
    type: Types.PRODUCTS_REORDER_FAILED,
    payload,
  }),

  toggleProductVisibility: (payload) => ({
    type: Types.TOGGLE_PRODUCT_VISIBILITY_REQUEST,
    payload,
  }),

  toggleProductVisibilitySuccess: (payload) => ({
    type: Types.TOGGLE_PRODUCT_VISIBILITY_SUCCESS,
    payload,
  }),

  toggleProductVisibilityFailed: (payload) => ({
    type: Types.TOGGLE_PRODUCT_VISIBILITY_FAILED,
    payload,
  }),

  deleteProduct: (payload) => ({
    type: Types.DELETE_PRODUCT_REQUEST,
    payload,
  }),

  deleteProductSuccess: (payload) => ({
    type: Types.DELETE_PRODUCT_SUCCESS,
    payload,
  }),

  deleteProductFailed: (payload) => ({
    type: Types.DELETE_PRODUCT_FAILED,
    payload,
  }),

  handleModalVisibility: (payload) => ({
    type: Types.HANDLE_MODAL_VISIBILITY,
    payload,
  }),

  handleModalImageVisibility: (payload) => ({
    type: Types.HANDLE_MODAL_IMAGE_VISIBILITY,
    payload,
  }),

  getItemImages: (payload) => ({
    type: Types.GET_ITEM_IMAGES_REQUEST,
    payload,
  }),

  getItemImagesSuccess: (payload) => ({
    type: Types.GET_ITEM_IMAGES_SUCCESS,
    payload,
  }),

  getItemImagesFailed: () => ({
    type: Types.GET_ITEM_IMAGES_FAILED,
  }),

  uploadImage: (payload) => ({
    type: Types.UPLOAD_IMAGE_REQUEST,
    payload,
  }),

  addImage: (payload) => ({
    type: Types.ADD_IMAGE,
    payload,
  }),

  uploadImageSuccess: (payload) => ({
    type: Types.UPLOAD_IMAGE_SUCCESS,
    payload,
  }),

  uploadImageFailed: (payload) => ({
    type: Types.UPLOAD_IMAGE_FAILED,
    payload,
  }),

  removeImage: (payload) => ({
    type: Types.REMOVE_IMAGE_REQUEST,
    payload,
  }),

  removeImageSuccess: (payload) => ({
    type: Types.REMOVE_IMAGE_SUCCESS,
    payload,
  }),
};
